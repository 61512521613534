import {Component, Input} from '@angular/core';

/**
 * A component that renders an SVG icon for "Filter Variant Plus"
 *
 * @member {string} tooltip ? The tooltip that needs to be displayed on this icon.
 */
@Component({
  selector: 'app-icon-filter-variant-plus',
  templateUrl: './icon-filter-variant-plus.component.html',
  styleUrls: ['./icon-filter-variant-plus.component.scss'],
})
export class IconFilterVariantPlusComponent {
  /**
   * Assign a tooltip that needs to be displayed on this icon as soon as the user hovers on it.
   *
   * @param tooltip ? The tooltip that needs to be displayed on this icon.
   */
  @Input() tooltip?: string;
}
