<ion-fab slot="fixed" vertical="top" horizontal="end">
  <ion-fab-button [color]="colour" (click)="onFabClick.emit()">
    <ion-icon name="checkmark"></ion-icon>
    <ion-label class="number">{{count}}</ion-label>
    <ng-container *ngIf="type === 'AO'; else stockTemplate">
      <ion-label class="text-0">Auto</ion-label>
      <ion-label class="text-1">Orders</ion-label>
    </ng-container>
    <ng-template #stockTemplate>
      <ion-label class="text-0">Stock</ion-label>
      <ion-label class="text-1">Updates</ion-label>
    </ng-template>
    <ion-label class="text-2">Successful</ion-label>
  </ion-fab-button>
</ion-fab>

