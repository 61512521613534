import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {path_users_userId_settings_stores_storeId_mailer_settings} from '../database-paths';
import {from, Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {selectColleagues} from '../../../features/core/store-shared/shared.selectors';
import {Store} from '@ngrx/store';
import {IColleagues} from '../../shared-models/user-access/user-access';
import {IEmailSettings} from '../../../features/settings/models/settings-models';
import {killColleaguesService} from '../../../features/core/store-shared/shared.actions';
import {selectUserAccess} from '../../../features-as-modules/feature-core/store/core.selectors';

@Injectable({
  providedIn: 'root',
})
export class CollectionUsersSettingsService {
  constructor(
    private angularFirestore: AngularFirestore,
    private store: Store,
  ) {
  }

  // ====================================================================================================
  // Generic Get/Set/Delete
  // ====================================================================================================

  async setDocument<DataType>(
    path: string,
    data: DataType,
  ): Promise<void> {
    try {
      await this.angularFirestore
        .doc<DataType>(path)
        .set(data, {merge: true});
    } catch (error) {
      console.error(error);
    }
  }

  async getDocument<DataType>(
    path: string,
  ): Promise<DataType> {
    try {
      const docRef = this.angularFirestore.doc<DataType>(path);
      const docSnapshot = await docRef.get().toPromise();
      if (docSnapshot?.data()) {
        return docSnapshot.data();
      } else {
        return {} as DataType;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async deleteDocument<DataType>(
    path: string,
  ): Promise<void> {
    try {
      await this.angularFirestore
        .doc<DataType>(path)
        .delete();
    } catch (error) {
      console.error(`Error deleting document at path: ${path}\n`, error);
    }
  }

  getAllMailerSettings(storeId: string): Observable<{ [userId: string]: IEmailSettings }> {
    return from(this.getAllMailerSettingsAsync(storeId));
  }

  private async getAllMailerSettingsAsync(storeId: string): Promise<{ [userId: string]: IEmailSettings }> {
    const userAccess = await this.store.select(selectUserAccess).pipe(take(1)).toPromise();
    if (!userAccess?.adminAccessStores.includes(storeId)) {
      return {};
    }
    try {
      let allColleagues: IColleagues = await this.store.select(selectColleagues).pipe(take(1)).toPromise();
      if (!allColleagues || Object.keys(allColleagues).length === 0) {
        return {};
      }

      const userIds = Object.keys(allColleagues).filter((userId: string) => {
        const colleague = allColleagues[userId];
        return colleague.storeList?.includes(storeId);
      });

      if (userIds.length === 0) {
        return {};
      }

      const settingsPromises = userIds.map(async (userId: string) => {
        try {
          const docPath: string = path_users_userId_settings_stores_storeId_mailer_settings(userId, storeId);
          const settings = await this.angularFirestore
            .doc<IEmailSettings>(docPath)
            .valueChanges()
            .pipe(take(1))
            .toPromise();
          return {userId, settings: settings || {} as IEmailSettings};
        } catch (error) {
          return {userId, settings: {} as IEmailSettings};
        }
      });

      const results = await Promise.all(settingsPromises);

      const settingsMap: { [userId: string]: IEmailSettings } = {};
      results.forEach(({userId, settings}) => {
        settingsMap[userId] = settings;
      });
      this.store.dispatch(killColleaguesService());
      return settingsMap;
    } catch (error) {
      this.store.dispatch(killColleaguesService());
      return {};
    }
  }
  
}
