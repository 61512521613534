<ion-header>
  <ion-toolbar>
    <ion-title>{{ title }}</ion-title>
    <ion-buttons slot="end">
      <ion-menu-button menu="main-menu">
        <ion-icon name="menu-outline"></ion-icon>
      </ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
