<div
  (focus)="onMouseOverMenu()"
  (mouseenter)="resetTimeOut()"
  (mouseover)="onMouseOverMenu()"
  class="menu-container">

  <div
    (mouseleave)="onMouseLeftMenu()"
    [class.collapsed]="!isMenuExpanded"
    [class.expanded]="isMenuExpanded"
    id="navigation">
    <div *ngIf="!subNav" class="logo">
      <div *ngIf="!showFullLogo; else fullLogo">
        <app-logo-g></app-logo-g>
      </div>
      <ng-template #fullLogo>
        <div (click)="increaseClickCount()" *ngIf="showFullLogo">
          <app-logo-full></app-logo-full>
        </div>
      </ng-template>
    </div>
    <div class="scrollable-sidenav-div">
      <div
        (click)="pageObj.multiple ? expandMenuOnAccClick(pageObj.page) : onNavigate(pageObj.page, pageObj.tab)"
        *ngFor="let pageObj of userPages; trackBy: trackByFunction"
        [id]="pageObj.page"
        [ngClass]="{'page-button': true, 'beta': pageObj.beta, 'fresh': pageObj.fresh, 'selected': selectedPage.includes(pageObj.page)}">
        <ng-container>
          <ion-accordion-group
            *ngIf="pageObj.multiple; else single;"
            [multiple]="false"
            class="accordion-group">
            <ion-accordion
              [id]="'Ac' + pageObj.page"
              [value]="pageObj.page"
              class="accordion">
              <ion-item class="accordion-header additional-style" slot="header">
                <ion-label>
                  <div class="accordion-label">
                  <span class="icon-wrapper">
                    <app-display-icon [icon]="pageObj.icon" class="icon"></app-display-icon>
                  </span>
                    <span class="menu-text">
                    {{ pageObj.text }}
                  </span>
                  </div>
                </ion-label>
                <ion-icon
                  class="ion-accordion-toggle-icon"
                  color="white"
                  name="chevron-down"
                  slot="end">
                </ion-icon>
              </ion-item>
              <div class="ion-padding" slot="content">
                <div
                  (click)="onNavigate(subPageObj.page, subPageObj.tab)"
                  *ngFor="let subPageObj of subPages[pageObj.page]; trackBy: trackByFunction"
                  [id]="subPageObj.tab ? subPageObj.tab : subPageObj.page"
                  [ngClass]="{'page-button': true, 'beta': pageObj.beta, 'fresh': pageObj.fresh, 'selected': selectedPage.includes(subPageObj.tab)}">
                  <div [ngClass]="{'selected': selectedPage.includes(subPageObj.tab)}" class="sub-page">
                  <span class="icon-container">
                    <app-display-icon [icon]="subPageObj.icon" class="icon"></app-display-icon>
                  </span>
                    <span class="sub-page-text">
                    {{ subPageObj.text }}
                  </span>
                  </div>
                </div>
              </div>
            </ion-accordion>
          </ion-accordion-group>
        </ng-container>

        <ng-template #single>
        <span class="icon-wrapper">
          <app-display-icon [icon]="pageObj.icon" class="display-icon"></app-display-icon>
        </span>
          <span class="menu-text-wrapper">
          {{ pageObj.text }}
        </span>
        </ng-template>
      </div>
      <app-mushroom-easter-egg *ngIf="showFullLogo && clickCount > 9"></app-mushroom-easter-egg>
      <div class="menu-toggle">
        <div
          (click)="onToggleMenu()"
          class="arrow">
          <ion-icon
            [ngClass]="{'rotated': hasMenuFinishedExpanding}"
            class="arrow-icon"
            name="chevron-forward-outline">
          </ion-icon>
        </div>
      </div>
      <div class="styling-svg">
        <app-styling-svg [ngClass]="{'expanded': isMenuExpanded, 'collapsed': !isMenuExpanded}">
        </app-styling-svg>
      </div>
    </div>

  </div>
</div>
