import {Injectable} from '@angular/core';
import {AngularFirestore, CollectionReference, DocumentChangeAction} from '@angular/fire/compat/firestore';
import {combineLatest, from, Observable, of} from 'rxjs';
import {IStore} from '../../shared-models/store/store';
import {
  path_operational_stores_data_auto_ordering_auto_orders_GET,
  path_operational_stores_data_auto_ordering_auto_orders_order_items_GET,
} from '../database-paths';
import {
  IAutoOrderOrderFirestore,
  IAutoOrderOrderItemFirestore,
} from '../../shared-models/auto-ordering/auto-order-order';
import {catchError, map} from 'rxjs/operators';
import {ISupplierEmailsDoc} from '../../shared-models/auto-ordering/auto-order-supplier-email-doc';
import {IFirebaseQuery} from '../../shared-models/firebase/firebase-queries';
import {generateFirebaseCollectionReference} from '../../shared-utils/firestore/firestore.utils';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root',
})
export class CollectionOperationalAutoOrderingService {

  constructor(
    private angularFirestore: AngularFirestore,
  ) {
  }

  async setDocument<DataType>(
    path: string,
    data: DataType,
  ): Promise<void> {
    try {
      await this.angularFirestore
        .doc<DataType>(path)
        .set(data, {merge: true});
    } catch (error) {
      console.error(error);
    }
  }

  async getDocument<DataType>(
    path: string,
  ): Promise<DataType> {
    try {
      const docRef = this.angularFirestore.doc<DataType>(path);
      const docSnapshot = await docRef.get().toPromise();
      if (docSnapshot?.data()) {
        return docSnapshot.data();
      } else {
        return {} as DataType;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async deleteDocument<DataType>(
    path: string,
  ): Promise<void> {
    try {
      await this.angularFirestore
        .doc<DataType>(path)
        .delete();
    } catch (error) {
      console.error(`Error deleting document at path: ${path}\n`, error);
    }
  }

  async deleteField<DataType>(path: string, fieldName: keyof DataType | string): Promise<void> {
    try {
      await this.angularFirestore
        .doc<DataType>(path)
        .update({[fieldName]: firebase.firestore.FieldValue.delete()} as Partial<DataType>);
    } catch (error) {
      console.error(`Error deleting field '${String(fieldName)}' at path: ${path}\n`, error);
    }
  }

  getAutoOrders(store: IStore): Observable<IAutoOrderOrderFirestore[]> {
    return this.angularFirestore
      .collection(
        path_operational_stores_data_auto_ordering_auto_orders_GET(store.storeId)
      )
      .snapshotChanges()
      .pipe(map((changes) => {
        const autoOrderOrders: IAutoOrderOrderFirestore[] = [];
        changes.forEach((change): void => {
          const doc = change.payload.doc;
          const data = doc.data() as IAutoOrderOrderFirestore;
          // Firestore Timestamp Reference
          const dataGenerate: { seconds: number; nanoseconds: number } = data.generated as unknown as {
            seconds: number;
            nanoseconds: number
          };
          autoOrderOrders.push({
            ...data,
            generated: new Date(dataGenerate.seconds * 1000 + dataGenerate.nanoseconds / 1000000)
          });
        });
        return autoOrderOrders;
      }));
  }

  // Get only retrieves data once
  getAutoOrdersFirebaseQueriesGet(store: IStore, firebaseQueries: IFirebaseQuery[]): Observable<IAutoOrderOrderFirestore[]> {
    const collection = this.angularFirestore.collection(path_operational_stores_data_auto_ordering_auto_orders_GET(store.storeId));
    let queryRef = collection.ref;
    queryRef = generateFirebaseCollectionReference(firebaseQueries, queryRef);
    return from(
      queryRef
        .get()
        .then((snapshot) => {
          const autoOrderOrders: IAutoOrderOrderFirestore[] = [];
          snapshot.forEach((doc) => {
            const data = doc.data() as IAutoOrderOrderFirestore;
            const dataGenerate: { seconds: number; nanoseconds: number } = data.generated as unknown as {
              seconds: number;
              nanoseconds: number
            };
            autoOrderOrders.push({
              ...data,
              generated: new Date(dataGenerate.seconds * 1000 + dataGenerate.nanoseconds / 1000000),
            });
          });
          return autoOrderOrders;
        })
    );
  }

  getAutoOrdersFirebaseQueries(store: IStore, firebaseQueries: IFirebaseQuery[]): Observable<IAutoOrderOrderFirestore[]> {
    const collection = this.angularFirestore.collection(path_operational_stores_data_auto_ordering_auto_orders_GET(store.storeId));
    let queryRef = collection.ref;
    queryRef = generateFirebaseCollectionReference(firebaseQueries, queryRef);

    return new Observable((observer) => {
      // Listen for real-time changes with onSnapshot
      queryRef.onSnapshot((snapshot) => {
        const autoOrderOrders: IAutoOrderOrderFirestore[] = [];
        snapshot.forEach((doc) => {
          const data = doc.data() as IAutoOrderOrderFirestore;
          const dataGenerate: { seconds: number; nanoseconds: number } = data.generated as unknown as {
            seconds: number;
            nanoseconds: number
          };
          autoOrderOrders.push({
            ...data,
            generated: new Date(dataGenerate.seconds * 1000 + dataGenerate.nanoseconds / 1000000),
          });
        });
        observer.next(autoOrderOrders);
      }, (error) => {
        observer.error(error);
      });
    });
  }

  getAutoOrdersQueriesNoInStatus(store: IStore, firebaseQuery1: IFirebaseQuery[], firebaseQuery2: IFirebaseQuery[]): Observable<IAutoOrderOrderFirestore[]> {
    const collection = this.angularFirestore.collection(path_operational_stores_data_auto_ordering_auto_orders_GET(store.storeId));
    const query1 = generateFirebaseCollectionReference(firebaseQuery1, collection.ref);
    const query2 = generateFirebaseCollectionReference(firebaseQuery2, collection.ref);
    return from(
      Promise.all([query1.get(), query2.get()]).then(([snapshot1, snapshot2]) => {
        const autoOrderOrders: IAutoOrderOrderFirestore[] = [];
        snapshot1.forEach((doc) => {
          const data = doc.data() as IAutoOrderOrderFirestore;
          const dataGenerate: { seconds: number; nanoseconds: number } = data.generated as unknown as {
            seconds: number;
            nanoseconds: number;
          };
          autoOrderOrders.push({
            ...data,
            generated: new Date(dataGenerate.seconds * 1000 + dataGenerate.nanoseconds / 1000000),
          });
        });
        snapshot2.forEach((doc) => {
          const data = doc.data() as IAutoOrderOrderFirestore;
          const dataGenerate: { seconds: number; nanoseconds: number } = data.generated as unknown as {
            seconds: number;
            nanoseconds: number;
          };
          autoOrderOrders.push({
            ...data,
            generated: new Date(dataGenerate.seconds * 1000 + dataGenerate.nanoseconds / 1000000),
          });
        });
        const uniqueOrders = Array.from(
          new Map(autoOrderOrders.map((order: IAutoOrderOrderFirestore) => [order.orderId, order])).values()
        );
        return uniqueOrders;
      })
    );
  }


  getAutoOrderOrderItems(store: IStore, orderId: string): Observable<IAutoOrderOrderItemFirestore[]> {
    return this.angularFirestore
      .collection(path_operational_stores_data_auto_ordering_auto_orders_order_items_GET(store.storeId, orderId))
      .snapshotChanges()
      .pipe(
        map((changes: DocumentChangeAction<unknown>[]) => {
          const autoOrderOrders: IAutoOrderOrderItemFirestore[] = [];
          changes.forEach((change): void => {
            const doc = change.payload.doc;
            const data = doc.data() as IAutoOrderOrderItemFirestore;
            autoOrderOrders.push({
              ...data,
              itemId: doc.id,
            });
          });
          return autoOrderOrders;
        }),
      );
  }

  getSupplierEmails(
    path: string,
    supplierIds: string[],
  ): Observable<{
    [suppId: string]: {
      selected: string[],
      additional: string[],
    }
  }> {
    if (supplierIds?.length === 0) {
      return of({});
    }
    const validSupplierIds = supplierIds.filter((id: string) => id);
    const queries: Observable<ISupplierEmailsDoc[]>[] = [];
    while (validSupplierIds.length > 0) {
      const deleteCount = validSupplierIds.length >= 10 ? 10 : validSupplierIds.length;
      const chunk = validSupplierIds.splice(0, deleteCount);
      const query = this.angularFirestore
        .collection<ISupplierEmailsDoc>(path, (ref: CollectionReference) =>
          ref.where('__name__', 'in', chunk),
        )
        .valueChanges({idField: 'id'});
      queries.push(query);
    }
    return combineLatest(queries).pipe(
      map((results: ISupplierEmailsDoc[][]) => {
        const allDocs = ([] as ISupplierEmailsDoc[]).concat(...results);
        const supplierEmails: {
          [suppId: string]: {
            selected: string[],
            additional: string[],
          }
        } = {};
        supplierIds.forEach((id: string) => {
          supplierEmails[id] = {} as {
            selected: string[],
            additional: string[],
          };
          const found = allDocs.find((doc: ISupplierEmailsDoc) => doc.id === id);
          if (found?.selected?.length > 0) {
            supplierEmails[id].selected = found.selected;
          } else {
            supplierEmails[id].selected = [];
          }
          if (found?.additional?.length > 0) {
            supplierEmails[id].additional = found.additional;
          } else {
            supplierEmails[id].additional = [];
          }
        });
        return supplierEmails;
      }),
      catchError((error) => {
        console.error('Error fetching supplier emails:', error);
        return of({});
      }),
    );
  }

}
