import {Component, Input} from '@angular/core';
import {FirebaseService} from '../../../../shared-utilities/services-old/firebase.service';
import {AlertController} from '@ionic/angular';
import {StockValChangeCheckConfig} from '../../../../shared-utilities/models-old/datastructures';
import {StoreObject} from '../../../../shared-utilities/models-old/store/store-object';
import {
  CollectionStoresSettingsService,
} from '../../../../shared/shared-services/firebase/collection-stores-settings.service';

interface ThresholdSettings {
  pos: number;
  neg: number;
}

@Component({
  selector: 'app-price-threshold',
  templateUrl: './price-threshold.component.html',
  styleUrls: ['./price-threshold.component.scss'],
})
export class PriceThresholdComponent {
  @Input() stores?: StoreObject;

  selectedStore: string;
  // TODO: make this StockValChangeCheckConfig;
  thresholds: { [storeId: string]: ThresholdSettings } = {};

  constructor(
    private firebaseService: FirebaseService,
    private alertControl: AlertController,
    private collectionStoresSettingsService: CollectionStoresSettingsService,
  ) {
  }

  async onChangeStoreSelect() {
    if (this.thresholds[this.selectedStore]) {
      return;
    }

    this.thresholds[this.selectedStore] = {neg: 0.2, pos: 0.2};
    const ac = await this.alertControl.create({header: 'Loading', subHeader: 'Fetching settings'});
    await ac.present();
    const thresh = await this.firebaseService.getStoreEditFlagConfig(this.selectedStore);

    if (thresh.sellPriIncl1) {
      this.thresholds[this.selectedStore] = {pos: thresh.sellPriIncl1.pct, neg: thresh.sellPriIncl1.negPct};
    }
    await ac.dismiss();
  }

  onValueChange(storeId: string, event: any, valueKey: keyof ThresholdSettings) {
    if (event.target.value >= 100) {
      this.thresholds[storeId][valueKey] = 1;
    } else if (event.target.value <= 0) {
      this.thresholds[storeId][valueKey] = 0;
    } else {
      this.thresholds[storeId][valueKey] = event.target.value / 100;
    }
  }

  async onClickSave(event: any) {
    (event.target as HTMLInputElement).disabled = true;
    const config: StockValChangeCheckConfig = {
      sellPriIncl1: {
        pct: this.thresholds[this.selectedStore].pos,
        negPct: this.thresholds[this.selectedStore].neg,
      },
    };

    await this.collectionStoresSettingsService.setDocument<StockValChangeCheckConfig>('stock_edit_thresholds', config);
    const ac = await this.alertControl.create({
      header: 'Thresholds Set',
      subHeader: `The price change thresholds for ${this.stores.stores[this.selectedStore].name} have been updated.`,
      cssClass: 'custom-alert', buttons: ['Ok'],
      backdropDismiss: true,
    });
    await ac.present();
    await ac.onDidDismiss();
    (event.target as HTMLInputElement).disabled = false;
  }
}
