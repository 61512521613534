<ion-header *ngIf="options">
    <ion-title>{{ options.header }}</ion-title>
</ion-header>

<ion-content *ngIf="options" class="special-no-scroll">
  <div class="special-scroller">
    <div class="sub-header" *ngIf="options.subHeader">
      {{ options.subHeader }}
    </div>
    <div class="message" *ngIf="options.message" [innerHTML]="options.message"></div>
    <div class="input">
      <label *ngIf="options.inputLabel">{{ options.inputLabel }}:</label>
      <ion-input [formControl]="formControl"></ion-input>
    </div>
  </div>
  <div class="buttons">
    <button mat-button *ngIf="!options.required" (click)="cancel()" style="color: var(--ion-color-primary)">
      {{ options.cancelText }}
    </button>
    <button mat-button (click)="submit()" [ngStyle]="{'color': formControl.invalid ? '#ccc' : 'var(--ion-color-primary)'}" [disabled]="formControl.invalid" >
      {{ options.submitText }}
    </button>
  </div>
</ion-content>
