import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController, PopoverController} from '@ionic/angular';
import {
  Feature,
  LinkedDepartments,
  LinkedDepartmentsLinkType,
  PriceBand,
  StoreInfo,
  SubDep
} from '../../../../shared-utilities/models-old/datastructures';
import {SelectPopoverComponent} from '../select-popover/select-popover.component';
import {copyRange, inRange, IRange, rangeOverlap} from '../../../../shared-utilities/functions-old/ranges';
import {
  DepInfo,
  SuggestedLink,
  UnlinkedDepartmentsModalComponent
} from '../unlinked-departments-modal/unlinked-departments-modal.component';
import {initObj} from '../../../../shared-utilities/functions-old/object-functions';
import {FirebaseService} from '../../../../shared-utilities/services-old/firebase.service';

type SnapType = 'rand' | 'cents';

@Component({
  selector: 'app-price-banding',
  templateUrl: './price-banding.component.html',
  styleUrls: ['./price-banding.component.scss'],
})
export class PriceBandingComponent implements OnInit {

  @Input() stores?: { order: string[]; stores: { [storeId: string]: StoreInfo } };
  @Input() storeId?: string;
  @Input() setChanges?: (document: string, change: any, storeId?: string, feature?: Feature | 'user') => void;

  @Input() departments?: {
    [storeId: string]: {
      dep: { [code: string]: { name: string } }; sub: { [code: string]: SubDep };
    };
  };

  priceBands: { [storeId: string]: PriceBand[] } = {}; // {'': []};
  ogPriceBands: { [storeId: string]: PriceBand[] } = {}; // {'': []};
  // storesWithSettings = [];
  selectedStore = null; // '';
  hiddenPBs: { [storeId: string]: { [what: string]: boolean }[] } = {}; // {'': []};
  toggles: { [storeId: string]: { range?: boolean; randSnap?: boolean }[] } = {}; // {'': []};
  randSnapConstraints: { [storeId: string]: { digs: number; selectable: number }[] } = {}; // {'': []};
  copiedStoreId = '';
  copiedIdx: number[] = [];
  copiedPriceBand: PriceBand[] = [];

  readonly randSnapsIDs = ['a', 'b', 'c', 'd', 'e'];

  readonly help = {
    copySingleRule: 'Copy Rule', copyAll: 'Copy All Rules',
    paste: 'Paste Copied Rules', clearClipboard: 'Clear Copied Rules'
  };
  readonly centSnapsIDs = ['i', 'ii', 'iii', 'iv', 'v', 'vi', 'vii', 'viii', 'ix', 'x'];

  private depLinks: LinkedDepartments = {idxLookUp: {}, sIdLookUp: {}};
  // TODO: This should almost definitely be local only. Want to decide each time.
  private makeNewLinks: { [storeId: string]: boolean } = {};
  private alreadyAlerted: boolean;

  constructor(
    private firebase: FirebaseService,
    private alertControl: AlertController,
    private modalController: ModalController,
    private popControl: PopoverController,
  ) {
  }

  private static copyPB(pb: PriceBand): PriceBand {
    const pb2: PriceBand = {};

    if (pb.range) {
      pb2.range = copyRange(pb.range);
    }
    if (pb.departs) {
      pb2.departs = pb.departs.map((d) => d);
    }
    if (pb.subDeparts) {
      pb2.subDeparts = pb.subDeparts.map((d) => d);
    }
    if (pb.randSnap) {
      pb2.randSnap = {
        digits: pb.randSnap.digits,
        snaps: pb.randSnap.snaps.map((cs) => ({range: copyRange(cs.range), v: cs.v}))
      };
    }
    if (pb.centSnaps) {
      pb2.centSnaps = pb.centSnaps.map((cs) => ({range: copyRange(cs.range), v: cs.v}));
    }
    return pb2;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private static invalidSnap(snap: { range: IRange; v: number }, snapType: SnapType, pbRange?: IRange): void | string {
    if (snap.range) {
      for (const part of Object.keys(snap.range)) {
        if (snap.range[part]) {
          if (snap.range[part].v !== null && isNaN(+snap.range[part].v)) {
            return `Range ${part} is not valid`;
          }

          if (snapType === 'cents') {
            if (snap.range[part].v < 0 || snap.range[part].v > 99) {
              return `Range ${part} is not between 0 and 99`;
            }
          } else if (pbRange) {
            if (!inRange(snap.range[part].v, pbRange)) {
              return `Range ${part} is not within Price Band range`;
            }
          }
        }
      }

      if (snap.range.start && snap.range.start.v !== null && snap.range.end && snap.range.end.v !== null &&
        snap.range.start.v >= snap.range.end.v) {
        return `${snap.range.start.v} - ${snap.range.end.v} is an invalid range`;
      }
    }

    if (snapType === 'cents') {
      if (snap.v === null || snap.v === undefined || snap.v < 0 || snap.v > 99) {
        return 'The set to value must be between 0 and 99 inclusive';
      }
    } else {
      if (snap.v === null || snap.v === undefined || (pbRange && !inRange(snap.v, pbRange))) {
        return 'The set to value must be within Price Band range';
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private static nines(num: number): number {
    let s = '';
    for (let i = 0; i < num; i++) {
      s += '9';
    }
    return +s;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private static randSnapRange(digits: number): IRange {
    if (digits <= 0) {
      return {start: {v: 0}, end: {v: 0}};
    }
    const range: IRange = {start: {v: 0, inc: true}, end: {v: 10 ** digits}};
    range.start.v = range.start.v === 1 ? 0 : range.start.v;
    return range;
  }

  ngOnInit() {
    if (!this.stores && !this.storeId) {
      throw Error('PriceBandingComponent could not initialise, stores or storeId required');
    }
    const stores = this.stores ? this.stores.order : [this.storeId];

    if (!this.departments) {
      this.departments = {};
      stores.forEach((storeId) => {
        this.departments[storeId] = {dep: null, sub: null};
        this.firebase.getStoreDataDoc('departments', storeId).then((d) => {
          this.departments[storeId].dep = d;
        }).catch(error => console.error('Error getting departments.\n' + error));
        this.firebase.getStoreDataDoc('sub_departments', storeId).then((d) => {
          this.departments[storeId].sub = d;
        }).catch(error => console.error('Error getting sub_departments.\n' + error));
      });
    }

    this.firebase.getPriceBands().then((result) => {

      const process = (storeId: string, pbs: PriceBand[]) => {
        this.ogPriceBands[storeId] = [];
        this.hiddenPBs[storeId] = [];
        this.toggles[storeId] = [];
        this.randSnapConstraints[storeId] = [];

        if (pbs) {
          this.priceBands[storeId] = pbs;
          let idx = 0;
          pbs.forEach((pb) => {
            this.ogPriceBands[storeId].push(PriceBandingComponent.copyPB(pb));
            this.hiddenPBs[storeId].push({});
            this.toggles[storeId].push({range: !!pb.range, randSnap: !!pb.randSnap});
            this.randSnapConstraints[storeId].push(this.rsMaxSelectableDigits(pb, idx, storeId));
            idx++;
          });
        } else {
          this.priceBands[storeId] = [];
        }

      };
      // process('', result.personal);
      Object.keys(result.stores).forEach((sID) => process(sID, result.stores[sID]));
      this.stores.order = this.stores.order.filter((sID) => {
        if (result.stores.hasOwnProperty(sID)) {
          return true;
        }
        delete this.stores.stores[sID];
        return false;
      });
      // this.storesWithSettings.sort((a, b) => stores.indexOf(a) - stores.indexOf(b));
    });
    // this.fromStoreID = stores[0];
  }

  async alertAboutStores() {
    if (!this.alreadyAlerted) {
      const ac = await this.alertControl.create({
        header: 'Important Notice!', subHeader: 'Please take a moment to read this quick notice.', message: 'Welcome ' +
          'to the Price Banding functionality. This feature has been requested for a while but please note the final ' +
          'layout and implementation of this configurator is still in development.<br>' +
          'NOTE: Any settings you change for a store will be applied to all users when editing that store.<br><br>' +
          'Thanks,<br>Techodactyl Team', cssClass: ['custom-alert', 'warn'],
        buttons: ['OK'], backdropDismiss: false
      });
      await ac.present();
      this.alreadyAlerted = true;
    }
  }

  getOgValue(part: 'start' | 'end' | 'snap', idx: number, snapIdx?: number, snapType: SnapType = 'cents',
             storeId: string = this.selectedStore) {
    if (this.ogPriceBands[storeId] && this.ogPriceBands[storeId] && this.ogPriceBands[storeId][idx]) {
      const pb = this.ogPriceBands[storeId][idx];

      if (snapIdx !== undefined) {
        if (snapType === 'cents') {
          if (pb.centSnaps.length > snapIdx && pb.centSnaps[snapIdx]) {
            if (part === 'snap') {
              return pb.centSnaps[snapIdx].v;
            } else {
              return pb.centSnaps[snapIdx].range[part].v;
            }
          }
        } else if (pb.randSnap && pb.randSnap.snaps.length > snapIdx && pb.randSnap.snaps[snapIdx]) {
          if (part === 'snap') {
            return pb.randSnap.snaps[snapIdx].v;
          } else {
            return pb.randSnap.snaps[snapIdx].range[part].v;
          }
        }
      } else if (pb.range && pb.range[part]) {
        return pb.range[part].v;
      }
    }
    return null;
  }

  addRule(storeId: string = this.selectedStore) {
    if (this.selectedStore !== null) {
      if (!this.priceBands[storeId]) {
        this.priceBands[storeId] = [];
        this.ogPriceBands[storeId] = [];
      }
      this.priceBands[storeId].push({} as PriceBand);
      this.ogPriceBands[storeId].push(null);
      this.hiddenPBs[storeId].push({departmentFilters: true});
      this.toggles[storeId].push({});
    }
  }

  setRange(event, range: IRange, part: 'start' | 'end', snapType: SnapType = null, rsDigits?: number) {
    const v = +event.detail.value;
    let valid = false;
    const alt = part === 'start' ? 'end' : 'start';



    if (event.detail.value !== '') {
      if (!isNaN(v)) {
        if (range[alt]) {
          if (range[alt].v !== v) {
            if (part === 'start') {
              if (range[alt].v === null || range[alt].v === undefined || v < range[alt].v) {
                valid = true;
              }
            } else if (v > range[alt].v) {
              valid = true;
            }
          }
        } else {
          valid = true;
        }
      }
    } else {
      valid = true;
    }

    if (valid) {
      if (snapType === 'cents') {
        valid = v >= 0 && v <= 99;
      } else if (snapType === 'rand') {
        valid = /^\d+$/.test(event.detail.value) && v >= 0 && v <= PriceBandingComponent.nines(rsDigits);
      }
    }
    const classNames = (event.target as HTMLIonInputElement).className.split(' ')
      .filter((cn) => cn !== 'invalid').join(' ');
    if (!range[part]) {
      range[part] = {v: null};
    }

    if (!valid) {
      (event.target as HTMLIonInputElement).className = classNames + ' invalid';

      if (isNaN(v) && event.detail.value !== '') {
        range[part].v = event.detail.value;
      } else {
        range[part].v = v;
      }
    } else {
      (event.target as HTMLIonInputElement).className = classNames;
      range[part].v = v;
    }
  }

  toggleInclusive(pb: PriceBand, part: 'start' | 'end') {
    if (pb.range[part]) {
      pb.range[part].inc = !pb.range[part].inc;
    } else {
      pb.range[part] = {v: null, inc: true};
    }
  }

  async filterDeps(event, pb: PriceBand, which: 'dep' | 'sub') {
    const value = pb[which === 'dep' ? 'departs' : 'subDeparts'];
    const selection: { [id: string]: string } = {};
    const departs = this.departments[this.selectedStore][which];
    Object.keys(departs).forEach((id) => selection[id] = departs[id].name);

    const order = Object.keys(selection);
    order.sort((a, b) => selection[a] < selection[b] ? -1 : 1);

    const pc = await this.popControl.create({
      component: SelectPopoverComponent,
      componentProps: {
        title: `Filter ${which === 'dep' ? '' : 'Sub-'}Departments`, selection, order, multiple: true,
        value, selectAll: true
      },
      event
    });
    await pc.present();
    const {data} = await pc.onDidDismiss();

    // TODO: Bailey delete this
    if (data) {
      if (data.length) {
        pb[which === 'dep' ? 'departs' : 'subDeparts'] = data;
      } else {
        delete pb[which === 'dep' ? 'departs' : 'subDeparts'];
      }
    }
  }

  async toggleRange(pb: PriceBand, idx: number) {
    if (!pb.range) {
      pb.range = {};
    } else {
      const keys = Object.keys(pb.range);
      let confirm = false;

      for (const key of keys) {
        if (pb.range[key] && pb.range[key].v !== undefined && pb.range[key].v !== null) {
          confirm = true;
          break;
        }
      }

      if (confirm) {
        const ac = await this.alertControl.create({
          header: 'Discard Range?', subHeader: 'Discard the range values?',
          cssClass: 'custom-alert', buttons: ['Cancel', {text: 'Discard', role: 'y'}]
        });
        await ac.present();
        const {role} = await ac.onDidDismiss();

        if (role !== 'y') {
          return;
        }
      }
      delete pb.range;
    }
    this.toggles[this.selectedStore][idx].range = !!pb.range;
  }

  async toggleRandSnaps(pb: PriceBand, idx: number) {
    if (!pb.randSnap) {
      pb.randSnap = {digits: 1, snaps: []};
    } else {
      const ac = await this.alertControl.create({
        header: 'Remove Rand Snap?', message: 'Are you sure?',
        cssClass: 'custom-alert', buttons: ['No', {text: 'Yes', role: 'y'}]
      });
      await ac.present();
      const {role} = await ac.onDidDismiss();

      if (role === 'y') {
        delete pb.randSnap;
      }
    }
    this.toggles[this.selectedStore][idx].randSnap = !!pb.randSnap;
    // if (!pb.randSnaps) {
    //   pb.randSnaps = [];
    // }
    // pb.randSnap.push({range: {start: {v: null, inc: true}, end: {v: null, inc: true}}, digits: 1, v: 0});
  }

  resetRandSnapConstraints(storeId: string, idx: number) {
    this.randSnapConstraints[storeId][idx] = this.rsMaxSelectableDigits(this.priceBands[storeId][idx], idx, storeId);
  }

  randSnapDigit(inc: -1 | 1, pb: PriceBand, idx: number, storeId: string) {
    if (inc > 0) {
      if (pb.randSnap.digits < this.randSnapConstraints[storeId][idx].selectable) {
        pb.randSnap.digits += inc;
      }
    } else {
      if (pb.randSnap.digits > 1) {
        pb.randSnap.digits += inc;
      }
    }
  }

  async addCentSnap(idx: number, storeId: string = this.selectedStore) {
    const pb = this.priceBands[storeId][idx];
    const error = this.invalidSnaps(pb);

    if (error) {
      const ac = await this.alertControl.create({
        header: 'Invalid Snaps Detected', subHeader: 'Please make sure all existing cent snaps are valid ' +
          'before creating more.', message: `Cent Snap ${this.centSnapsIDs[error[0]]}: ${error[1]}`,
        cssClass: 'custom-alert', buttons: ['OK']
      });
      await ac.present();
      return;
    }
    if (!pb.centSnaps) {
      pb.centSnaps = [];
    }

    if (pb.centSnaps.length === this.centSnapsIDs.length) {
      const ac = await this.alertControl.create({
        header: 'Max Snaps Reached', subHeader: 'Currently cent snaps are ' +
          `limited to ${this.centSnapsIDs.length} per price band rule.`, cssClass: 'custom-alert', buttons: ['ok']
      });
      await ac.present();
      return;
    }
    pb.centSnaps.push({range: {start: {v: null, inc: true}, end: {v: null, inc: true}}, v: 0});
    const og = this.ogPriceBands[storeId][idx] ? this.ogPriceBands[storeId][idx] : {};
    if (!og.centSnaps) {
      og.centSnaps = [];
    }
    og.centSnaps.push(null);
    this.ogPriceBands[storeId][idx] = og;
    // pb.centSnaps.push()
  }

  async addRandSnap(idx: number, storeId: string = this.selectedStore) {
    const pb = this.priceBands[storeId][idx];
    const error = this.invalidSnaps(pb, 'rand');

    if (error) {
      const ac = await this.alertControl.create({
        header: 'Invalid Snaps Detected', subHeader: 'Please make sure all existing rand snaps are valid ' +
          'before creating more.', message: `Rand Snap <i>${this.randSnapsIDs[error[0]]}</i>: ${error[1]}`,
        cssClass: 'custom-alert', buttons: ['OK']
      });
      await ac.present();
      return;
    }
    if (!pb.randSnap.snaps) {
      pb.randSnap.snaps = [];
    }

    if (pb.randSnap.snaps.length === this.randSnapsIDs.length) {
      const ac = await this.alertControl.create({
        header: 'Max Snaps Reached', subHeader: 'Currently rand snaps are ' +
          `limited to ${this.randSnapsIDs.length} per price band rule.`, cssClass: 'custom-alert', buttons: ['ok']
      });
      await ac.present();
      return;
    }
    pb.randSnap.snaps.push({range: {start: {v: null, inc: true}, end: {v: null, inc: true}}, v: 0});
    const og = this.ogPriceBands[storeId][idx] ? this.ogPriceBands[storeId][idx] : {};
    if (!og.randSnap) {
      og.randSnap = {snaps: [], digits: null};
    }
    og.randSnap.snaps.push(null);
    this.ogPriceBands[storeId][idx] = og;
  }

  async remove(pbIdx: number, snapIdx?: number, snapType: SnapType = 'cents', storeId: string = this.selectedStore) {
    let ac: HTMLIonAlertElement;

    if (snapIdx !== null && snapIdx !== undefined) {
      ac = await this.alertControl.create({
        header: `Remove ${snapType[0].toUpperCase() + snapType.substring(1)} Snap`, subHeader: 'Are you sure you ' +
          `want to remove ${snapType[0].toUpperCase() + snapType.substring(1)} Snap ${this.centSnapsIDs[snapIdx]} of` +
          ` rule ${pbIdx + 1}`, cssClass: 'custom-alert', buttons: ['No', {text: 'Yes', role: 'y'}]
      });
    } else {
      ac = await this.alertControl.create({
        header: 'Delete Rule', subHeader: `Are you sure you want rule ${pbIdx + 1}`,
        cssClass: 'custom-alert', buttons: ['No', {text: 'Yes', role: 'y'}]
      });
    }
    await ac.present();
    const {role} = await ac.onDidDismiss();

    if (role === 'y') {
      this.randSnapConstraints[storeId].splice(pbIdx, 1);
      if (snapIdx !== null && snapIdx !== undefined) {
        if (snapType === 'cents') {
          this.priceBands[storeId][pbIdx].centSnaps.splice(snapIdx, 1);
          this.ogPriceBands[storeId][pbIdx].centSnaps.splice(snapIdx, 1);
        } else {
          this.priceBands[storeId][pbIdx].randSnap.snaps.splice(snapIdx, 1);
          this.ogPriceBands[storeId][pbIdx].randSnap.snaps.splice(snapIdx, 1);
        }
      } else {
        this.priceBands[storeId].splice(pbIdx, 1);
        this.ogPriceBands[storeId].splice(pbIdx, 1);
      }
    }
  }

  async shiftSnap(idx: number, snapIdx: number, snapType: SnapType, shift: -1 | 1,
                  storeId: string = this.selectedStore) {
    const pb = this.priceBands[storeId][idx];
    const og = this.ogPriceBands[storeId] ?
      (idx < this.ogPriceBands[storeId].length ? this.ogPriceBands[storeId][idx] : null) : null;


    const snaps = snapType === 'cents' ? pb.centSnaps : pb.randSnap.snaps;
    let temp = snaps[snapIdx];
    snaps[snapIdx] = snaps[snapIdx + shift];
    snaps[snapIdx + shift] = temp;
    const ogSnaps = og ? (snapType === 'cents' ? og.centSnaps : (og.randSnap ? og.randSnap.snaps : null)) : null;

    if (ogSnaps) {
      temp = ogSnaps[snapIdx];
      ogSnaps[snapIdx] = ogSnaps[snapIdx + shift];
      ogSnaps[snapIdx + shift] = temp;
    }
    // const ogs = this.ogPriceBands[]
  }

  setSnapValue(event, pb: PriceBand, i: number, snapType: SnapType = 'cents') {
    const snap = snapType === 'cents' ? pb.centSnaps[i] : pb.randSnap.snaps[i];
    const v = +event.detail.value;
    let valid = false;

    if (event.detail.value !== '') {
      const max = snapType === 'cents' ? 99 : PriceBandingComponent.nines(pb.randSnap.digits);

      if (!isNaN(v) && v >= 0 && v <= max && /^\d+$/.test(event.detail.value)) {
        valid = true;
      }
    }
    const classNames = (event.target as HTMLIonInputElement).className.split(' ')
      .filter((cn) => cn !== 'invalid').join(' ');

    if (!valid) {
      (event.target as HTMLIonInputElement).className = classNames + ' invalid';

      if (isNaN(v)) {
        snap.v = null;
      } else {
        snap.v = v;
      }
    } else {
      (event.target as HTMLIonInputElement).className = classNames;
      snap.v = v;
    }
  }

  async check4save(storeId: string = this.selectedStore) {

    if (!this.priceBands || !this.priceBands[storeId]) {
      return;
    }
    const err = this.checkForOverlaps();

    if (err) {
      const ac = await this.alertControl.create({
        header: 'Price Band Overlaps', subHeader: 'Overlapping Price bands ' +
          'can result in contradictions. They are not allowed.', message: err, cssClass: 'custom-alert',
        buttons: ['ok']
      });
      await ac.present();
      return;
    }
    const errors: { index: number; error: string }[] = [];

    for (let i = 0; i < this.priceBands[storeId].length; i++) {
      const pd = this.priceBands[storeId][i];
      let error = this.invalidSnaps(pd, 'rand');

      if (error) {
        errors.push({index: i, error: `Rand Snap ${this.randSnapsIDs[error[0]]} - ${error[1]}`});
      } else {
        error = this.invalidSnaps(pd, 'cents');

        if (error) {
          errors.push({index: i, error: `Cent Snap ${this.centSnapsIDs[error[0]]} - ${error[1]}`});
        }
      }
    }

    if (errors.length > 0) {
      let message = '';
      errors.forEach((e) => message += `Rule ${e.index + 1}: ${e.error}<br>`);
      const ac = await this.alertControl.create({
        header: 'Unresolved Snap Errors', subHeader: errors.length +
          'rule(s) have cent snap errors.', message, cssClass: 'custom-alert', buttons: ['ok']
      });
      await ac.present();
      return;
    } else {
      if (this.priceBands[storeId].length > 0) {
        const changes: { [i: number]: PriceBand } = {};
        this.priceBands[storeId].forEach((pb, idx) => changes[idx] = pb);
        this.setChanges('price_bands', changes, storeId, storeId !== '' ? 'shared' : 'user');
      } else {
        this.setChanges('price_bands', {}, storeId, storeId !== '' ? 'shared' : 'user');
      }
      const which = storeId === '' ? 'your <b>personal</b>' : `${this.stores.stores[storeId].name}'s`;
      const ac = await this.alertControl.create({
        header: `Changes Staged`,
        subHeader: 'Your changes are staged for ' +
          'saving. Hit the save button when you`re happy.',
        message: `You have staged changes to ${which} price bands.` +
          `<br>If you made changes to other stores${storeId !== '' ? ' or your personal settings' : ''} you need to ` +
          'stage those seporatly (for now).',
        cssClass: 'custom-alert',
        buttons: ['ok']
      });
      await ac.present();
    }
  }

  async paste() {
    const storeId = this.selectedStore;

    if (!this.copiedPriceBand || this.copiedPriceBand.length === 0) {

      return;
    }

    if (!this.priceBands[storeId]) {
      this.priceBands[storeId] = [];
    }

    for (let i = 0; i < this.copiedPriceBand.length; i++) {
      // const newPb = await this.linkDepartments(this.copiedPriceBand[i], storeId);
      const newPb = this.copiedPriceBand[i];
      this.priceBands[storeId].push(newPb);
      // this.ogPriceBands[storeId].push(newPb);

      // Set toggles for the copy
      this.toggles[storeId].push({range: !!newPb.range, randSnap: !!newPb.randSnap});
      this.hiddenPBs[storeId].push({departmentFilters: true});
      const maxSelectableDigits = this.rsMaxSelectableDigits(newPb, this.priceBands[storeId].length, storeId);
      // maxSelectableDigits is dependent on toggles and idx for now
      this.randSnapConstraints[storeId].push(maxSelectableDigits);

      // Create a new copy, the previous one was assigned and is mutable.
      this.copiedPriceBand[i] = PriceBandingComponent.copyPB(this.copiedPriceBand[i]);
    }
    this.clear();
  }

  clear() {
    this.copiedPriceBand = [];
    this.copiedIdx = [];
  }

  copyRule(pb: PriceBand, idx: number, storeId: string = this.selectedStore) {
    this.copiedStoreId = storeId;
    this.copiedIdx = [idx];
    this.copiedPriceBand = [PriceBandingComponent.copyPB(pb)];
  }

  copyAll(storeId: string = this.selectedStore) {
    let idx = 0;
    this.copiedStoreId = storeId;
    this.copiedPriceBand = this.priceBands[storeId].map((pb) => {
      this.copiedIdx.push(idx);
      idx++;
      return PriceBandingComponent.copyPB(pb);
    });
  }

  makeLinks(
    what: LinkedDepartmentsLinkType, fromStoreID: string, fromDepID: string, toStoreID: string, toDepID: string
  ) {
    const fIdx = '' + this.depLinks.idxLookUp[fromStoreID];
    const tIdx = '' + this.depLinks.idxLookUp[toStoreID];
    initObj([fIdx, what, fromDepID, tIdx], [toDepID], this.depLinks);
    initObj([tIdx, what, toDepID, fIdx], [fromDepID], this.depLinks);
  }

  async linkDepModal(
    whatLink: LinkedDepartmentsLinkType, fromDeparts: DepInfo[], toDeparts: DepInfo[], noSuggestions: string[],
    fromStoreID: string, toStoreID: string
  ): Promise<string[]> {

    if (fromDeparts.length !== toDeparts.length) {
      throw Error('Suggested links, oldDepartments and toDepartments are not of equal length.');
    }
    const suggestLinks: SuggestedLink[] = fromDeparts.map((a, idx) => ({storeA: a, storeB: toDeparts[idx]}));

    const modal = await this.modalController.create({  // oldDepartments, departments
      component: UnlinkedDepartmentsModalComponent,
      componentProps: {
        type: whatLink, suggestLinks, storeABIDs: {storeA: fromStoreID, storeB: toStoreID}, noLinks: noSuggestions
      },
      cssClass: 'settings-modal',
    });
    await modal.present();
    const {data} = await modal.onWillDismiss();

    if (data) {
      const resolved: { [storeId: string]: DepInfo }[] = data;
      return resolved.map((info) => {

        if (this.makeNewLinks[toStoreID]) {
          this.makeLinks(whatLink, fromStoreID, info[fromStoreID].depId, toStoreID, info[toStoreID].depId);
        }
        return info[toStoreID].depId;
      });
    }
    return null;
  }

  private async initDepLinks(targetStoreID: string) {
    let fromStoreIdx = this.depLinks?.idxLookUp[this.copiedStoreId];
    let toStoreIdx = this.depLinks?.idxLookUp[targetStoreID];

    if (fromStoreIdx !== undefined && toStoreIdx !== undefined) {
      return {fromStoreIdx, toStoreIdx};
    }

    if (fromStoreIdx === undefined) {
      // Initialise store link TODO: Move this to a depLinks function?
      fromStoreIdx = Object.keys(this.depLinks.idxLookUp).length;
      this.depLinks.idxLookUp[this.copiedStoreId] = fromStoreIdx;
      this.depLinks.sIdLookUp[fromStoreIdx] = this.copiedStoreId;
      this.depLinks[fromStoreIdx] = {dep: {}, sub: {}};
    }

    if (toStoreIdx === undefined) {
      // Initialise store link TODO: Move this to a depLinks function?
      toStoreIdx = Object.keys(this.depLinks.idxLookUp).length;
      this.depLinks.idxLookUp[targetStoreID] = toStoreIdx;
      this.depLinks.sIdLookUp[toStoreIdx] = targetStoreID;
      this.depLinks[toStoreIdx] = {dep: {}, sub: {}};
    }

    // Show alert to create links
    const ac = await this.alertControl.create({
      header: 'No Links Between Stores',
      subHeader: 'Links are needed between stores when copying over departments and sub-departments',
      message: 'If you do not create links between these stores the next time you try to copy over a priceBand you' +
        ' will have to manually set links again.<br><br>',
      cssClass: ['custom-alert'],
      buttons: ['Do Not Link', {text: 'Create Link', role: 'y'}],
      backdropDismiss: false
    });

    await ac.present();
    const response = await ac.onDidDismiss();

    if (response.role === 'y') {
      this.makeNewLinks[targetStoreID] = true;
    }
    return {fromStoreIdx, toStoreIdx};
  }

  private async linkDepartments(copiedPb: PriceBand, storeId: string) {
    //const copiedPbCopy = JSON.parse(JSON.stringify(copiedPb));
    const {fromStoreIdx, toStoreIdx} = await this.initDepLinks(storeId);

    if (toStoreIdx === undefined || fromStoreIdx === undefined) {
      return copiedPb;
    }
    const linkedTypeOptions: { what: string; key: LinkedDepartmentsLinkType }[] =
      [{what: 'departs', key: 'dep'}, {what: 'subDeparts', key: 'sub'}];

    for (const {what, key} of linkedTypeOptions) {
      let newIDs2Use = [];

      const unLinked = [];
      const unLinkable = [];
      const copiedDLinks = this.depLinks[fromStoreIdx];

      /*
        key:
        fromDeparts:    list of copiedDepartments where id exists in target
        toDeparts:      list of target departments where id exists in copied
        unLinkableName: list of departments that have no matches or similar thing
      */

      // TODO: Clean this up. Longer then it needs to be
      if (copiedPb[what]) {
        // for (const depart of copiedPb[what]) {
        //   if (copiedDLinks && copiedDLinks[key] && copiedDLinks[key][depart]) {
        //     const linkedDept = this.depLinks[fromStoreIdx][key][depart][toStoreIdx];
        //     if (linkedDept) {
        //       newIDs2Use.push(linkedDept);
        //     } else if( !this.departments[storeId][key][depart]) {
        //       unLinkable.push(depart);
        //     } else {
        //       unLinked.push(depart);
        //     }
        //   } else {
        //     unLinked.push(depart);
        //   }
        // }
        if (unLinked.length > 0 || unLinkable.length > 0) {
          const toDeparts: DepInfo[] = [];
          const fromDeparts: DepInfo[] = [];
          const unLinkableName = [];

          for (const entryID of unLinked) {
            if (this.departments[storeId][key] && this.departments[storeId][key][entryID]) {
              toDeparts.push({name: this.departments[storeId][key][entryID].name, depId: entryID});
              fromDeparts.push({name: this.departments[this.copiedStoreId][key][entryID].name, depId: entryID});
            } else {
              unLinkableName.push(this.departments[this.copiedStoreId][key][entryID].name);
            }
          }
          const resolvedIDs = await this.linkDepModal(key, fromDeparts, toDeparts, unLinkableName, this.copiedStoreId, storeId);

          if (resolvedIDs && resolvedIDs.length) {
            newIDs2Use = newIDs2Use.concat(resolvedIDs);
          }
        }

        if (newIDs2Use.length) {
          copiedPb[what] = newIDs2Use;
        }
      }
    }
    return copiedPb;
  }

  private rsMaxSelectableDigits(pb: PriceBand, idx: number, storeId: string): { digs: number; selectable: number } {
    if (this.toggles[storeId][idx].range && pb.range && pb.range.end && pb.range.end.v) {
      const end = pb.range.end.v;
      const digs = ('' + end).length;

      if (end < 1000) {
        if (end > 10) {
          return {digs, selectable: 1};
        }
        return null;
      } else if (end < 10000) {
        return {digs, selectable: 2};
      } else {
        return {
          digs, selectable: ('' + end).length - 3
        };
      }
    }
    return {digs: 6, selectable: 3};
    // if (pb.range && pb.range.end && pb.range.end.v) {
    //
    //   return Math.floor(Math.log10(pb.range.end.v)) + 1;
    // }
    // return pb.randSnaps.digit ? pb.randSnaps.digit + 3 : 4;
  }

  private checkForOverlaps() {
    // const personal = this.priceBands[''];
    //
    // /*
    // PERSONAL     |    STORE X
    // pb  pb  pb |  pb  pb  pb
    // __________
    //     ______
    // ________________________
    //     ____________________
    //         ________________
    //               __________
    //                   ______
    // */
    //
    // // Check for personal overlaps first as it's joined with all stores
    // for (let i = 0; i < personal.length; i++) {
    //   for (let j = i +1; j < personal.length; j++) {
    //     if (this.priceBandOverLap(personal[i], personal[j])) {
    //       return `Personal Price Bands ${i +1} and ${j +1} overlap.`;
    //     }
    //   }
    // }

    for (const storeId of Object.keys(this.priceBands)) {
      if (this.priceBands[storeId] && this.priceBands[storeId].length) { // && storeId !== ''
        // for (let i = 0; i < personal.length; i++) {
        //   for (let j = 0; j < this.priceBands[storeId].length; j++) {
        //     if (this.priceBandOverLap(personal[i], this.priceBands[storeId][j])) {
        //       return `Personal Price Band ${i +1} overlaps with Price Band ${j +1} of ` +
        //         `${this.stores.stores[storeId].name}.`;
        //     }
        //   }
        // }

        for (let i = 0; i < this.priceBands[storeId].length; i++) {
          for (let j = i + 1; j < this.priceBands[storeId].length; j++) {
            if (this.priceBandOverLap(this.priceBands[storeId][i], this.priceBands[storeId][j])) {
              return `Price Bands ${i + 1} and ${j + 1} of ${this.stores.stores[storeId].name} overlap. Remember you
              can filter departments`;
            }
          }
        }
      }
    }
    return null;
  }

  private priceBandOverLap(pb0: PriceBand, pb1: PriceBand): boolean {
    if (pb0.range && pb1.range) {
      if (!rangeOverlap(pb0.range, pb1.range)) {
        return false;
      }
    }
    let subDepOverlap = false;
    let depOverlap = false;

    if (pb0.subDeparts && pb0.subDeparts.length && pb1.subDeparts && pb1.subDeparts.length) {
      if (pb0.subDeparts.some((sd) => pb1.subDeparts.includes(sd)) ||
        pb1.subDeparts.some((sd) => pb0.subDeparts.includes(sd))) {
        subDepOverlap = true;
      }
    } else {
      subDepOverlap = true;
    }

    if (!subDepOverlap) {
      if (pb0.departs && pb0.departs.length && pb1.departs && pb1.departs.length) {
        if (pb0.departs.some((sd) => pb1.departs.includes(sd)) ||
          pb1.departs.some((sd) => pb0.departs.includes(sd))) {
          depOverlap = true;
        }
      } else {
        depOverlap = true;
      }
    }
    return subDepOverlap || depOverlap;
  }

  private invalidSnaps(pb: PriceBand, snapType: SnapType = 'cents'): [number, string] | void {
    const {snaps, range} = snapType === 'cents' ?
      {snaps: pb.centSnaps, range: pb.range} :
      (pb.randSnap ? {snaps: pb.randSnap.snaps, range: PriceBandingComponent.randSnapRange(pb.randSnap.digits)} :
        {snaps: null, range: null});

    if (!snaps || snaps.length === 0) {
      return;
    }

    for (let i = 0; i < snaps.length; i++) {
      const error = PriceBandingComponent.invalidSnap(snaps[i], snapType, range);
      if (error) {
        return [i, error];
      }
    }

    for (let i = 0; i < snaps.length; i++) {
      for (let j = i + 1; j < snaps.length; j++) {
        if (rangeOverlap(snaps[i].range, snaps[j].range)) {
          return [i, `Range overlap between ${snapType} snap <i>${this.snapID(i, snapType)}</i> and ` +
          `<i>${this.snapID(j, snapType)}</i>`];
        }
        if (inRange(snaps[i].v, snaps[j].range)) {
          return [i, `The snap value of ${snapType} snap <i>${this.snapID(i, snapType)}</i> falls within the range ` +
          `of ${snapType} snap ${this.snapID(j, snapType)}`];
        }
        if (inRange(snaps[j].v, snaps[i].range)) {
          return [j, `The snap value of ${snapType} snap <i>${this.snapID(j, snapType)}</i> falls within the range ` +
          `of ${snapType} snap ${this.snapID(i, snapType)}`];
        }
      }
    }
  }

  private snapID(i: number, snapType: SnapType): string {
    return snapType === 'cents' ? this.centSnapsIDs[i] : this.randSnapsIDs[i];
  }
}
