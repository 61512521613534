import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-page-alert',
  templateUrl: './page-alert.component.html',
  styleUrls: ['./page-alert.component.scss'],
})
export class PageAlertComponent implements OnInit {

  @Input() options: {
    header: string; subHeader?: string; message?: string;
    buttons?: (string | {text: string; roll: string})[];
    // cssClass?: string | string[];
    // backgroundDismiss?: boolean;
  };
  @Input() userName: string;

  buttons: {text: string; roll: string}[];

  constructor(
    private modalController: ModalController,
  ) { }

  ngOnInit() {

    if (!this.options.buttons) {
      this.options.buttons = ['Ok', {text: 'Dont Show Again', roll: 'DELETE'}];
    }
    this.buttons = [];

    for (const but of this.options.buttons) {
      if (typeof but === 'string') {
        this.buttons = this.buttons.concat({text: but, roll: null});
      } else {
        this.buttons = this.buttons.concat(but);
      }
    }

    // if (!this.options.hasOwnProperty('backgroundDismiss')) {
    //   this.options.backgroundDismiss = true;
    // }

    this.options.header = this.options.header.replace('<userName>', this.userName);

    if (this.options.subHeader) {
      this.options.subHeader = this.options.subHeader.replace('<userName>', this.userName);
    }

    if (this.options.message) {
      this.options.message = this.options.message.replace('<userName>', this.userName);
    }
  }

  close(role) {
    this.modalController.dismiss(null, role).then();
  }
}
