import {Component, Input} from '@angular/core';
import {IFilter, IFilterGroup} from '../../../shared-models/filter-groups/filter-groups';
import {ColDef} from 'ag-grid-community';
import {getHumanReadableIdentifier} from '../../../shared-utils/primitives/human-readablility.utils';
import {getKeyType} from '../../../shared-utils/data-structures/key-types.utils';
import {
  FilterTypesBoolean,
  FilterTypesDate,
  FilterTypesNumber,
  FilterTypesString
} from '../../../shared-utils/data-structures/filter-types.utils';
import {IFilterType} from '../../../shared-models/filter-groups/filter-types';
import {Store} from '@ngrx/store';
import {
  deleteAdvancedFilterGroupFilter,
  setFilterAfterValueChange
} from '../../../../features/core/store-shared-filter/shared-filter.actions';
import {getDeepCopyOfObject} from '../../../shared-utils/object/object.utils';
import {
  CollectionSharedSingularDocumentsService
} from '../../../shared-services/firebase/collection-shared-singular-documents.service';

@Component({
  selector: 'app-shared-modal-filters-advanced-group-item',
  templateUrl: './shared-modal-filters-advanced-group-item.component.html',
  styleUrls: ['./shared-modal-filters-advanced-group-item.component.scss'],
})
export class SharedModalFiltersAdvancedGroupItemComponent {

  filterTypeString = FilterTypesString;
  filterTypeNumber = FilterTypesNumber;
  filterTypeBoolean = FilterTypesBoolean;
  filterTypeDate = FilterTypesDate;

  colDefs: ColDef[];
  filter: IFilter;
  filterTypes: IFilterType[] = [];

  constructor(
    private readonly store: Store,
    private sharedService: CollectionSharedSingularDocumentsService
  ) {
  }

  get getFilterTypeInputCount(): string {
    const found = this.filterTypes.find((type: IFilterType): boolean => type.description === this.filter.filterType);
    return found?.inputs || 'single';
  }

  @Input() set setColDef(colDefs: ColDef[]) {
    colDefs.forEach((col: ColDef): void => {
      if (col.headerName === '') {
        col.headerName = getHumanReadableIdentifier(col, 'headerName', 'field');
      }
    });
    this.colDefs = colDefs.sort((a: ColDef, b: ColDef): number => a.headerName > b.headerName ? 1 : -1);
  }

  @Input() set setFilter(inputFilter: IFilter | IFilterGroup) {
    this.filter = undefined;
    if (inputFilter) {
      this.filter = getDeepCopyOfObject(inputFilter as IFilter);
      this.setSelectedColumnByFilter();
    }
  }

  onColumnChange(event: Event): void {
    const customEvent = event as CustomEvent<{ value: string }>;
    this.filter.column = customEvent.detail.value;
    this.filter.columnType = getKeyType(this.filter.column);
    this.setFilterType();
    this.store.dispatch(setFilterAfterValueChange({filter: this.filter}));
  }

  onDeleteItemClick(): void {
    this.store.dispatch(deleteAdvancedFilterGroupFilter({filter: this.filter}));
  }

  onFilterTypeChange(event: Event): void {
    const customEvent = event as CustomEvent<{ value: string }>;
    this.filter.filterType = customEvent.detail.value;
    this.store.dispatch(setFilterAfterValueChange({filter: this.filter}));
  }

  onValueChange(): void {
    this.store.dispatch(setFilterAfterValueChange({filter: this.filter}));
  }

  trackByFunction(index: number): number {
    return index;
  }

  private setSelectedColumnByFilter(): void {
    const found = this.colDefs.find((col: ColDef): boolean => col.field === this.filter.column);
    if (found) {
      this.filter.column = found.field;
      this.filter.columnType = getKeyType(found.field);
      this.setFilterType();
    } else {
      this.filter.column = undefined;
    }
  }

  private setFilterType(): void {
    this.filterTypes = [];
    switch (this.filter.columnType) {
      case 'boolean':
        this.filterTypes = this.filterTypeBoolean;
        break
      case 'date':
        this.filterTypes = this.filterTypeDate;
        break;
      case 'number':
        this.filterTypes = this.filterTypeNumber;
        break;
      default:
      case 'string':
        this.filterTypes = this.filterTypeString;
        break;
    }
  }

}
