import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {IndicatorStatus} from '../data-freshness-indicator/data-freshness-indicator.component';
import {ModalController, PopoverController} from '@ionic/angular';
import {StoreDataFreshnessAge} from '../../../../shared-utilities/services-old/data-freshness.service';
import {TimeBreakDown, timeBreakDown2String} from '../../../../shared-utilities/functions-old/date-functions';
import {ISharedModalBasic} from '../../../../shared/shared-models/modals/shared-modal-basic';
import {Icons} from '../../../shared-icons/icons';
import {
  SharedModalBasicComponent
} from '../../../../shared/shared-components/components/shared-modal-basic/shared-modal-basic.component';
import {selectImageURL} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {getImageURL} from '../../../../features-as-modules/feature-core/store/core.actions';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-freshness-popup',
  templateUrl: './data-freshness-popup.component.html',
  styleUrls: ['./data-freshness-popup.component.scss'],
})
export class DataFreshnessPopupComponent implements OnInit, OnDestroy {

  @Input() dataObs: Observable<{
    age: StoreDataFreshnessAge;
    currentStatuses: { [key in keyof StoreDataFreshnessAge]: IndicatorStatus };
    currentStatus: IndicatorStatus;
  }>;

  @Input() popoverReference: PopoverController;

  age: StoreDataFreshnessAge;
  currentStatuses: { [key in keyof StoreDataFreshnessAge]: IndicatorStatus };
  currentStatus: IndicatorStatus;

  keyOrder: string[] = [];
  ageStrings: { [key in keyof StoreDataFreshnessAge]: string } = {};

  imageURL$: Observable<string>;
  imageUrl: string;

  private subscription: Subscription;

  constructor(
    private modalController: ModalController,
    private readonly store: Store
  ) {
  }

  ngOnInit(): void {
    const image = 'mgt_cloud_flow_chart.svg';
    this.store.dispatch(getImageURL({path: `/site_media/help/data_freshness/${image}`, image}));
    this.imageURL$ = this.store.select(selectImageURL(image));
    this.imageURL$.subscribe((image: string): void => {
      this.imageUrl = image;
    });
    this.subscription = this.dataObs.subscribe((data) => {
      if (data.currentStatus) {
        this.age = data.age;
        this.currentStatuses = data.currentStatuses;
        this.currentStatus = data.currentStatus;
        this.keyOrder = Object.keys(this.currentStatuses);
        this.keyOrder.sort();

        this.keyOrder.forEach((key: string): void => {
          if (this.age[key].msTotal < 0) {
            // TODO: Need to use server time. Then this wont be necessary anymore
            this.age[key] = {d: 0, h: 0, m: 0, s: 0, ms: 0, msTotal: 0};
          }
          this.ageStrings[key] = timeBreakDown2String(this.age[key] as TimeBreakDown);
        });
      }
    });

    setInterval(() => {
      this.tick();
    }, 1000);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  trackByFunction(index: number): number {
    return index;
  }

  async onFreshnessExpClick(): Promise<void> {
    const componentProps: ISharedModalBasic = {
      buttonAccept: false,
      buttonAcceptText: '',
      buttonClose: true,
      buttonCloseCross: true,
      buttonCloseText: 'Close',
      modalTitle: 'Data Freshness Indicator Explanation',
      modalTitleIcon: Icons.infoSlabCircleOutline,
      modalTitleIconColor: 'yellow',
      contextHeading: 'NOTE: Explanation of the purpose and functionality of the coloured indicator dots.',
      contextDescription: [
        `This flow chart illustrates how Gallix interacts with your server and explains the meaning of the data freshness indicators.`,
        ``,
        `Remember, a red indicator doesn’t necessarily mean your server is disconnected from Gallix; it just signals that the displayed data is outdated. Gallix remains usable, and any actions will process once the server is online.`,
        ``,
        `If the indicator is red and you need the latest stock data, try refreshing the page to trigger an update. If the indicator remains red for more than two minutes, please reach out to us.`,
        ``,
        `When the server is offline, your actions will queue and automatically process once the connection is restored.`,
      ],
      isImageVisible: true,
      imageUrl: this.imageUrl,
    };

    const modalController: HTMLIonModalElement = await this.modalController.create({
      component: SharedModalBasicComponent,
      componentProps: {
        ...componentProps,
        popoverReference: this.popoverReference
      },
      cssClass: ['shared-basic-modal-css-larger'],
    });
    await modalController.present();
    await modalController.onDidDismiss();
  }

  private tick(): void {
    this.keyOrder.forEach((key) => {
      if (this.age[key].s < 58) {
        this.age[key].s += 1;
      } else {
        this.age[key].s = 0;
        this.age[key].m += 1;
      }
      this.ageStrings[key] = timeBreakDown2String(this.age[key] as TimeBreakDown);
    });
  }

}
