import {Component, Input, OnInit} from '@angular/core';
import {IonicModule, ModalController, PopoverController} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

/**
 * @interface SelectModifyButton
 * A custom button to modify the selection. Could be select all negative, or select a default set etc.
 *
 * @member {string} text Text displayed on the button.
 * @member {string} [cssClass] CSS class to be added to the button for additional styling.
 * @member {string} [name] An identifier for the button, not currently used.
 * @member {Function} handler Callback to modify the selection. Receives an object with boolean properties
 * indicating current selection states (`checks`) and an optional list of disabled keys (`disabled`). Returns a new
 * `checks` object indicating updated selection states.
 */
export interface SelectModifyButton {
  /** @member {string} text Text displayed on the button. */
  text: string;
  /** @member {string} [cssClass] CSS class to be added to the button for additional styling. */
  cssClass?: string;
  /** @member {string} [name] An identifier for the button, not currently used. */
  name?: string;
  /** @member {Function} handler Callback to modify the selection. Receives an object with boolean properties
   * indicating current selection states (`checks`) and an optional list of disabled keys (`disabled`). Returns a new
   * `checks` object indicating updated selection states. */
  handler: (checks: { [sKey: string]: boolean }, disabled?: string[]) => { [sKey: string]: boolean };
}

/**
 * @interface SelectMainButton
 *
 * @member {string} text Text displayed on the button.
 * @member {string} cssClass ? CSS class to be added to the button for styling.
 * @member {string} name ? An identifier for the button, not currently used.
 * @member {Function} handler Function called when the button is clicked. The function takes the final list of selected
 * keys as a parameter and handles any necessary actions before closing the popover.
 */
export interface SelectMainButton {
  /** @member {string} text Text displayed on the button.*/
  text: string;
  /** @member {string} [cssClass] CSS class to be added to the button for styling.*/
  cssClass?: string;
  /** @member {string} [name] An identifier for the button, not currently used.*/
  name?: string;
  /** @member {Function} handler Function called when the button is clicked. The function takes the final list of
   * selected keys as a parameter and handles any necessary actions before closing the popover.*/
  handler: (selection: string[]) => void;
}


/**
 * Represents a configurable popover component for selections.
 *
 * @member {string} title ? Title on the top of the popover, describes what is being selected.
 * @member {Object} selection Selection options, an object of string key-value pairs.
 * @member {Object} selectionClasses ? Optional classes to style options in the selection separately.
 * @member {Object} selectionStyles ? Optional styles to set on corresponding selection options.
 * @member {string[] | string} value ? Specifies keys from the selection that are selected. Null by default.
 * @member {string[]} order ? Sort order of the keys in the selection parameter. Keys are sorted alphabetically by
 * default.
 * @member {boolean} multiple ? Allows multiple selections instead of a single option (checkboxes instead of radio
 * buttons). Defaults to false.
 * @member {boolean} search ? Whether to show a search bar. By default, it appears when there are 15+ options.
 * @member {string[]} disabled ? List of keys that are disabled (greyed out).
 * @member {'top' | 'bottom' | null | undefined} disabledTo ? If set, moves disabled options to the "top" or "bottom"
 of the list, respecting the order of options.
 * @member {boolean} selectAll ? Whether a select all button should be displayed, allowed only when `multiple` is true.
 * Defaults to false.
 * @member {number} maxHeight ? set max height of popover list.
 * @member {number} minHeight ? set min height of popover list.
 * @member {SelectModifyButton[]} selectModButtons ? Buttons above the "main" buttons providing unique modifiers of the
 * selection.
 * @member {SelectMainButton} additionalMainButton ? A third option button that, when pressed, will close the popover.
 * @member {string} cancelText ? Text displayed on the cancel button, default is 'CANCEL'.
 * @member {string} okText ? Text displayed on the confirm button, default is 'OK'.
 */
@Component({
  selector: 'app-select-popover',
  templateUrl: './select-popover.component.html',
  styleUrls: ['./select-popover.component.scss']
})
export class SelectPopoverComponent implements OnInit {
  @Input() title?: string;
  @Input() selection: { [key: string]: any };
  @Input() selectionClasses?: { [key: string]: string | string[] };
  @Input() selectionStyles?: { [key: string]: string };
  @Input() value?: string[] | string;
  @Input() order?: string[];
  @Input() multiple = false;
  @Input() search?: boolean;
  @Input() disabled?: string[];
  @Input() disabledTo: null | undefined | 'top' | 'bottom';
  @Input() selectAll = false;
  @Input() maxListHeight? = 195;
  @Input() minListHeight? = 190;
  @Input() selectModButtons?: SelectModifyButton[];
  @Input() additionalMainButton?: SelectMainButton;
  @Input() cancelText = 'CANCEL';
  @Input() okText = 'OK';

  // TODO: This is temporary for the header height thing
  @Input() asModal?: boolean;
  allSelected = false;

  checks: { [sKey: string]: boolean } = {};
  checked: string;

  classes: { [key: string]: string } = {};

  private orderBU: string[];

  private controller: PopoverController | ModalController;

  constructor(
    private popoverControl: PopoverController,
    private modalController: ModalController,
  ) {
  }

  ngOnInit() {
    const allSKeysSorted = Object.keys(this.selection).sort();

    if (this.order) {
      const missing = allSKeysSorted.filter((sKey) => !this.order.includes(sKey));

      if (missing.length) { this.order = this.order.concat(missing); }
    } else {
      this.order = allSKeysSorted;
    }
    this.fillInChecks();
    // this.order = this.order ? this.order : Object.keys(this.selection).sort();
    this.search = this.search === undefined ? (this.order.length >= 15) : this.search;
    this.disabled = this.disabled ? this.disabled : [];
    this.moveDisabled();

    if (this.search) {
      this.orderBU = this.order.map(a => a);
    }

    for (const sKey of this.order) {
      this.classes[sKey] = 'select-option';

      if (this.selectionClasses && this.selectionClasses[sKey]) {
        if (typeof this.selectionClasses[sKey] === 'string') {
          this.classes[sKey] += ' ' + this.selectionClasses[sKey];
        } else if (this.selectionClasses.hasOwnProperty(sKey)) {
          this.classes[sKey] += ' ' + (this.selectionClasses[sKey] as string[]).reduce((p, c) => p + ' ' + c);
        }
      }
    }

    if (this.value) {
      if (this.multiple) {
        this.value = typeof this.value === 'string' ? [this.value] : this.value;

        for (const sKey of this.value) {
          this.checks[sKey] = true;
        }

        if (this.value.length === this.order.length) {
          this.allSelected = true;
        }
      } else {
        this.value = typeof this.value === 'string' ? this.value : this.value[0];
        this.checked = this.value;
      }
    }

    if (!this.multiple) {
      this.selectAll = false;
    }

    this.controller = this.asModal ? this.modalController : this.popoverControl;

    const content = document.querySelector('.variable-list-div');
    const minHeightStyle = `min-height:${this.minListHeight}px !important;`;
    const maxHeightStyle = `max-height:${this.maxListHeight}px !important;`;
    content.setAttribute('style', `${minHeightStyle} ${maxHeightStyle}`);

    // if (!this.asModal && this.title) {
    //   DocumentFunctions.awaitID('pop-title').promise.then((header: HTMLIonHeaderElement) => {
    //     setTimeout(() => {
    //       const content = document.getElementById('pop-select-content');
    //
    //       if (content && this.maxHeight) {
    //         if (content.offsetHeight >= 780) {
    //           // content.style.height = `calc(100% - ${header.offsetHeight}px)`;
    //           content.setAttribute('style', `max-height:${this.maxHeight}px !important;`);
    //         } else {
    //           // content.style.height = `calc(90% - ${header.offsetHeight}px)`;
    //           content.setAttribute('style', `max-height:${this.maxHeight- 100}px !important;`);
    //         }
    //
    //
    //
    //       }
    //     }, 1);
    //   });
    // }
  }

  selectSKey(sKey: string) {
    if (this.disabled.includes(sKey)) {
      return;
    }

    if (this.multiple) {
      this.checks[sKey] = this.checks.hasOwnProperty(sKey) ? !this.checks[sKey] : true;

      if (this.checks[sKey]) {

        for (const sk of (this.orderBU ? this.orderBU : this.order)) {
          if (!this.checks[sk]) {
            return;
          }
        }
        this.allSelected = true;
      } else {
        this.allSelected = false;
      }
    } else {
      this.checked = sKey;
    }
  }

  toggleAll() {
    let setValue = false;

    for (const sKey of this.order) {
      if (!this.checks[sKey] && !this.disabled.includes(sKey)) {
        setValue = true;
        break;
      }
    }

    for (const sKey of this.order) {
      if (!this.disabled.includes(sKey)) {
        this.checks[sKey] = setValue;
      }
    }
    this.allSelected = setValue;
  }

  cancel() {
    this.controller.dismiss(null, 'cancel').then();
  }

  ok() {
    if (this.multiple) {
      const orderCheck: string[] = this.orderBU ? this.orderBU : this.order;
      if (this.allSelected) {
        const disable: string[] = [];
        this.disabled.forEach((d: string): void => {
          if (!this.value.includes(d)) {
            disable.push(d);
          }
        });
        this.controller.dismiss(
          orderCheck.filter((sKey: string) => !disable.includes(sKey)),
          'ok-all'
        ).then();
      }
      let selection = [];
      for (const sKey of orderCheck) {
        if (this.checks[sKey]) {
          selection = selection.concat(sKey);
        }
      }
      this.controller.dismiss(selection, 'ok').then();
    } else {
      this.controller.dismiss(this.checked, 'ok').then();
    }
  }

  selectionModHandle(handler: (checks: { [sKey: string]: boolean }, disabled?: string[]) => {
    [sKey: string]: boolean;
  }): void {
    const checks = handler(this.checks, this.disabled);

    if (this.multiple) {
      this.allSelected = true;
      for (const sKey of Object.keys(checks)) {
        if (this.checks.hasOwnProperty(sKey)) {
          this.checks[sKey] = checks[sKey];
          if (!this.checks[sKey] && this.allSelected) {
            this.allSelected = false;
          }
        } else {
          console.error(`Key '${sKey}' not in original selection keys`);
        }
      }

    } else {
      const check = Object.keys(checks).filter(k => checks[k]);
      if (check.length > 1) {
        console.error('Multiple items set to true. Multiple selection is false.');
      }
      this.checked = check.length === 1 ? check[0] : null;
    }
  }

  async additionalHandle(handler: (selection: string[]) => void) {
    let selection = [];

    for (const sKey of (this.orderBU ? this.orderBU : this.order)) {
      if (this.checks[sKey]) {
        selection = selection.concat(sKey);
      }
    }
    handler(selection);
    this.controller.dismiss().then();
  }

  searchOptions(event) {
    let subStr = event.target.value;

    if (subStr) {
      subStr = subStr.toLowerCase();
      this.order = this.orderBU.filter(
        a => a.toLowerCase().includes(subStr) || (this.selection[a] as string).toLowerCase().includes(subStr)
      );
    } else {
      this.order = this.orderBU.map(a => a);
    }
  }

  private fillInChecks(fill: boolean = false) {
    const allKeys = this.orderBU ? this.orderBU : this.order;
    const toFill = allKeys.filter((sKey) => !this.checks.hasOwnProperty(sKey));

    toFill.forEach((sKey) => (this.checks[sKey] = fill));
  }

  private moveDisabled() {
    if (this.disabled.length === 0 || !this.disabledTo) {
      return;
    }
    this.disabled.sort((a, b) => this.order.indexOf(a) - this.order.indexOf(b));
    this.disabled.forEach((key) => this.order.splice(this.order.indexOf(key), 1));

    switch (this.disabledTo.toLowerCase()) {
      case 'top':
        this.order = this.disabled.concat(this.order);
        break;
      case 'bottom':
        this.order = this.order.concat(this.disabled);
        break;
      default:
        throw Error(`disabledTo invalid value: ${this.disabledTo}`);
    }
  }
}
