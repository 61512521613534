<div id="content">

  <div id="select-store" *ngIf="stores.order.length >= 1">
    Selected Store:
    <ion-select [(ngModel)]="selectedStore" class="ion-no-padding" placeholder="Select Store"
                (ionChange)="alertAboutStores()"
    >
      <!--      <ion-select-option [value]="''">Personal: Apply to all my stores</ion-select-option>-->
      <ion-select-option *ngFor="let storeId of stores.order" [value]="storeId">
        {{ stores.stores[storeId].name }}
      </ion-select-option>
    </ion-select>
  </div>

  <div id="add">
    <div>
      <!--      <div *ngIf="storesWithSettings.length > 1; else noRules">-->

      <!--      </div>-->
      <ng-template #noRules>
        <div>
          <span>No rules set</span>
        </div>
      </ng-template>
    </div>
    <div class="buttons">
      <div [ngClass]="{'can-paste': copiedPriceBand?.length > 0, 'can-copy': priceBands[selectedStore]?.length > 0}"
           style="display: inline-flex; justify-content: center; align-items: center">
        <span class="mdi mdi-clipboard-off-outline copy-paste" (click)="clear()"
              [matTooltip]="help.clearClipboard" [ngClass]="{'can-paste': copiedPriceBand?.length > 0}"></span>
        <span class="mdi mdi-content-paste copy-paste" (click)="paste()" [matTooltip]="help.paste"
              [ngClass]="{'can-paste': copiedPriceBand?.length > 0}"></span>
        <span class="mdi mdi-content-copy copy-paste" (click)="copyAll()" [matTooltip]="help.copyAll"
              [ngClass]="{'can-copy': priceBands[selectedStore]?.length > 0}"></span>
      </div>

      <button mat-icon-button (click)="addRule()" [disabled]="!selectedStore">
        <ion-icon name="add-circle-outline" size="large"></ion-icon>
      </button>
    </div>
  </div>

  <div *ngFor="let pb of priceBands[selectedStore]; let idx = index" class="price-band">
    <div class="range">
      <div class="prop-header">
        <div>
          <div class="text">
            In range:
          </div>
          <span (click)="toggleRange(pb, idx)">
            <ion-toggle [(ngModel)]="toggles[selectedStore][idx].range"></ion-toggle>
          </span>
          <button mat-icon-button *ngIf="pb.range"
                  (click)="hiddenPBs[selectedStore][idx].range = !hiddenPBs[selectedStore][idx].range">
            <ion-icon name="caret-{{ hiddenPBs[selectedStore][idx].range ? 'down' : 'up' }}-outline"></ion-icon>
          </button>
        </div>
        <div>
          <ion-icon name="copy-outline" [ngClass]="{copied: selectedStore === copiedStoreId && copiedIdx.includes(idx)}"
                    (click)="copyRule(pb, idx)" [matTooltip]="help.copySingleRule" style="margin-right: 10px;
                    color: var(--ion-color-white-contrast);"></ion-icon>
          Rule: {{ idx + 1 }}
          <button mat-icon-button matTooltip="Remove this rule" (click)="remove(idx)">
            <ion-icon name="close-outline"></ion-icon>
          </button>
        </div>
      </div>
      <div *ngIf="pb.range" [hidden]="hiddenPBs[selectedStore][idx].range" class="range-value">
        <span>
          From R
          <ion-input class="ion-no-padding" debounce="600"
                     (ionChange)="setRange($event, pb.range, 'start'); resetRandSnapConstraints(selectedStore, idx)"
                     [value]="getOgValue('start', idx)"
                     [placeholder]="pb.range.start && pb.range.start.v ? pb.range.start.v : 0"
          ></ion-input>
          <button class="inclusive"
                  (click)="toggleInclusive(pb, 'start')">{{ pb.range.start && pb.range.start.inc ? 'Inclusive' : 'Exclusive' }}</button>
        </span>
        <span>
          To R<ion-input class="ion-no-padding" debounce="600"
                         (ionChange)="setRange($event, pb.range, 'end'); resetRandSnapConstraints(selectedStore, idx)"
                         [value]="getOgValue('end', idx)"
                         [placeholder]="pb.range.end && pb.range.end.v ? pb.range.end.v : null"
        ></ion-input>
          <button class="inclusive"
                  (click)="toggleInclusive(pb, 'end')">{{ pb.range.end && pb.range.end.inc ? 'Inclusive' : 'Exclusive' }}</button>
        </span>
      </div>
    </div>
    <div class="departments-filters" *ngIf="selectedStore !== ''">
      <div class="prop-header"
           (click)="hiddenPBs[selectedStore][idx].departmentFilters = !hiddenPBs[selectedStore][idx].departmentFilters">
        <div class="text">
          Departments or Sub-Departments
        </div>
        <ng-container *ngIf="hiddenPBs[selectedStore][idx].departmentFilters; else upTemplate">
          <ion-icon name="caret-down-outline"></ion-icon>
          {{ pb.departs || pb.subDeparts ? '*' : '' }}
        </ng-container>
        <ng-template #upTemplate>
          <ion-icon name="caret-up-outline"></ion-icon>
        </ng-template>
      </div>
      <div class="filters" [hidden]="hiddenPBs[selectedStore][idx].departmentFilters">
        <button mat-raised-button (click)="filterDeps($event, pb, 'dep')">
          Departments {{ pb.departs ? '(' + pb.departs.length + ')' : '' }}
        </button>
        <button mat-raised-button (click)="filterDeps($event, pb, 'sub')">
          Sub-Departments {{ pb.subDeparts ? '(' + pb.subDeparts.length + ')' : '' }}
        </button>
      </div>
    </div>
    <div class="rand-snaps">
      <!--   TODO: NBNBNBNBNBNBNBNBNBNBNBNB
                    I Have to allow multiple, non equal digits. so between 1 and 1000 you can snap AAAAX AAAXX AXXXX XXXX etc
                    counter point from 2 days later... Why?-->
      <div class="prop-header">
        <div>
          <div class="text">
            Rand Snaps:
          </div>
          <span (click)="toggleRandSnaps(pb, idx)">
            <ion-toggle [(ngModel)]="toggles[selectedStore][idx].randSnap"></ion-toggle>
          </span>
          <!--          (click)="hiddenPBs[selectedStore][idx].randSnaps = !hiddenPBs[selectedStore][idx].randSnaps"-->
          <button *ngIf="pb.randSnap"
                  (click)="hiddenPBs[selectedStore][idx].randSnaps = !hiddenPBs[selectedStore][idx].randSnaps">
            <ion-icon name="caret-{{ hiddenPBs[selectedStore][idx].randSnaps ? 'down' : 'up' }}-outline"></ion-icon>
          </button>
        </div>
        <!--        <button mat-icon-button *ngIf="!hiddenPBs[selectedStore][idx].randSnaps" (click)="addRandSnap(pb)">-->
        <!--          <ion-icon name="add-circle-outline"></ion-icon>-->
        <!--        </button>-->
      </div>
      <div *ngIf="pb.randSnap" [hidden]="hiddenPBs[selectedStore][idx].randSnaps">
        <div>
          <ng-container *ngIf="randSnapConstraints[selectedStore][idx]; else notAllowedRSTemplate">
            <div class="digit-select">
              Digits to snap:
              <div>
                <button (click)="randSnapDigit(1, pb, idx, selectedStore)">
                  <ion-icon name="chevron-back-outline"></ion-icon>
                </button>
                <!--              <ng-container *ngTemplateOutlet="selectDecimal; context: {length: 10, decimals: rsMaxSelectableDigits(pb), digit: pb.randSnap.digits}"></ng-container>-->
                <div class="digit-highlight" matTooltip="Select digits that will be snapped">

                  R&nbsp;
                  <span
                    *ngFor="let _ of [].constructor(randSnapConstraints[selectedStore][idx].digs - pb.randSnap.digits)">0</span>
                  <span class="high">
                    <ng-container *ngFor="let _ of [].constructor(pb.randSnap.digits)">0</ng-container>
                  </span>
                  .00
                  <!--                  R <span *ngFor="let _ of [].constructor(randSnapConstraints[selectedStore][idx].digs); let i = index"-->
                  <!--                          [class]="i >= randSnapConstraints[selectedStore][idx].digs - pb.randSnap.digits ? 'high' : ''"-->
                  <!--                  >-->
                  <!--                    0-->
                  <!--                  </span>.00-->
                </div>
                <button (click)="randSnapDigit(-1, pb, idx, selectedStore)">
                  <ion-icon name="chevron-forward-outline"></ion-icon>
                </button>
              </div>
              <button mat-icon-button *ngIf="!hiddenPBs[selectedStore][idx].centSnaps" (click)="addRandSnap(idx)"
                      matTooltip="Add rand digits snap"
              >
                <ion-icon name="add-circle-outline"></ion-icon>
              </button>
            </div>
            <div class="list">
              <div *ngFor="let rSnap of pb.randSnap.snaps; let snapIdx = index">
                <div class="range-value">
                  <span>
                    <button class="smol-butt" matTooltipPosition="after" matTooltip="Remove this snap"
                            (click)="remove(idx, snapIdx, 'rand')">
                      <ion-icon name="close-outline"></ion-icon>
                    </button>
                    {{ randSnapsIDs[snapIdx] }}:
                  </span>
                  <span>
                    From
                    <ion-input class="ion-no-padding" debounce="600"
                               (ionChange)="setRange($event, rSnap.range, 'start', 'rand', pb.randSnap.digits)"
                               [value]="getOgValue('start', idx, snapIdx, 'rand')"
                               [placeholder]="pb.randSnap.snaps[snapIdx].range.start.v && pb.randSnap.snaps[snapIdx].range.start ? pb.randSnap.snaps[snapIdx].range.start.v : 0"
                    ></ion-input>
                    <button class="inclusive"
                            (click)="toggleInclusive(rSnap, 'start')">{{ rSnap.range.start && rSnap.range.start.inc ? 'Inclusive' : 'Exclusive' }}</button>
                  </span>
                  <span>
                    To
                    <ion-input class="ion-no-padding" debounce="600"
                               (ionChange)="setRange($event, rSnap.range, 'end', 'rand', pb.randSnap.digits)"
                               [value]="getOgValue('end', idx, snapIdx, 'rand')"
                               [placeholder]="pb.randSnap.snaps[snapIdx].range.end.v && pb.randSnap.snaps[snapIdx].range.end ? pb.randSnap.snaps[snapIdx].range.end.v : null"
                    ></ion-input>
                    <button class="inclusive"
                            (click)="toggleInclusive(rSnap, 'end')">{{ rSnap.range.end && rSnap.range.end.inc ? 'Inclusive' : 'Exclusive' }}</button>
                  </span>
                </div>
                <div class="snap-to-container">
                       <span>
                    Snap To
                    <ion-input class="ion-no-padding" debounce="600"
                               (ionChange)="setSnapValue($event, pb, snapIdx, 'rand')"
                               [value]="getOgValue('snap', idx, snapIdx, 'rand')"
                               [placeholder]="pb.randSnap.snaps[snapIdx].v && pb.randSnap.snaps[snapIdx] ? pb.randSnap.snaps[snapIdx].v: null"
                    ></ion-input>
                    <span class="shift-controls" *ngIf="pb.randSnap.snaps.length > 1">
                      <button class="smol-butt" matTooltipPosition="before" *ngIf="snapIdx !== 0"
                              matTooltip="Move this snap up"
                              (click)="shiftSnap(idx, snapIdx, 'rand', -1)">
                        <ion-icon name="chevron-up-outline"></ion-icon>
                      </button>
                      <button class="smol-butt" matTooltipPosition="before"
                              *ngIf="snapIdx !== pb.randSnap.snaps.length - 1" matTooltip="Move this snap down"
                              (click)="shiftSnap(idx, snapIdx, 'rand', 1)"
                      >
                        <ion-icon name="chevron-down-outline"></ion-icon>
                      </button>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #notAllowedRSTemplate>
            <div class="range-too-small orange-msg">
              <p>This price band range is too low to support Rand Snaps</p>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="cent-snaps">
      <div class="prop-header">
        <div (click)="hiddenPBs[selectedStore][idx].centSnaps = !hiddenPBs[selectedStore][idx].centSnaps">
          <div class="text">
            Cent Snaps:
          </div>
          <ng-container *ngIf="hiddenPBs[selectedStore][idx].centSnaps; else upTemplate">
            <ion-icon name="caret-down-outline"></ion-icon>
            {{ pb.centSnaps ? '*' : '' }}
          </ng-container>
          <ng-template #upTemplate>
            <ion-icon name="caret-up-outline"></ion-icon>
          </ng-template>
        </div>
        <button mat-icon-button *ngIf="!hiddenPBs[selectedStore][idx].centSnaps" (click)="addCentSnap(idx)">
          <ion-icon name="add-circle-outline"></ion-icon>
        </button>
      </div>
      <div class="list" *ngIf="pb.centSnaps" [hidden]="hiddenPBs[selectedStore][idx].centSnaps">
        <div *ngFor="let cSnap of pb.centSnaps; let snapIdx = index">
          <div class="range-value">
            <span>
              <button class="smol-butt" matTooltipPosition="after" matTooltip="Remove this snap"
                      (click)="remove(idx, snapIdx, 'cents')">
                <ion-icon name="close-outline"></ion-icon>
              </button>
              {{ centSnapsIDs[snapIdx] }}:
            </span>
            <span>
              From
              <ion-input class="ion-no-padding" debounce="600"
                         (ionChange)="setRange($event, cSnap.range, 'start', 'cents')"
                         [value]="getOgValue('start', idx, snapIdx)"
                         [placeholder]="pb.centSnaps[snapIdx].range.start.v && pb.centSnaps[snapIdx].range.start ? pb.centSnaps[snapIdx].range.start.v : 0"
              ></ion-input> cents
              <button class="inclusive"
                      (click)="toggleInclusive(cSnap, 'start')">{{ cSnap.range.start && cSnap.range.start.inc ? 'Inclusive' : 'Exclusive' }}</button>
            </span>
            <span>
              To
              <ion-input class="ion-no-padding" debounce="600"
                         (ionChange)="setRange($event, cSnap.range, 'end', 'cents')"
                         [value]="getOgValue('end', idx, snapIdx)"
                         [placeholder]="pb.centSnaps[snapIdx].range.end.v && pb.centSnaps[snapIdx].range.end ? pb.centSnaps[snapIdx].range.end.v : null"
              ></ion-input> cents
              <button class="inclusive"
                      (click)="toggleInclusive(cSnap, 'end')">{{ cSnap.range.end && cSnap.range.end.inc ? 'Inclusive' : 'Exclusive' }}</button>
            </span>
          </div>
          <div class="snap-to-container">
                 <span>
              Snap To
              <ion-input class="ion-no-padding" debounce="600" (ionChange)="setSnapValue($event, pb, snapIdx)"
                         [value]="getOgValue('snap', idx, snapIdx)"
                         [placeholder]="pb.centSnaps[snapIdx].v && pb.centSnaps[snapIdx]? pb.centSnaps[snapIdx].v : null"
              ></ion-input> cents
              <span class="shift-controls" *ngIf="pb.centSnaps.length > 1">
                <button class="smol-butt" matTooltipPosition="before" *ngIf="snapIdx !== 0"
                        matTooltip="Move this snap up"
                        (click)="shiftSnap(idx, snapIdx, 'cents', -1)"
                >
                  <ion-icon name="chevron-up-outline"></ion-icon>
                </button>
                <button class="smol-butt" matTooltipPosition="before" *ngIf="snapIdx !== pb.centSnaps.length - 1"
                        matTooltip="Move this snap down"
                        (click)="shiftSnap(idx, snapIdx, 'cents', 1)"
                >
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </button>
              </span>
            </span>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div id="save-button">
    <button mat-raised-button (click)="check4save()">validate</button>
  </div>
</div>
