import {Component, Input} from '@angular/core';
/**
 * A component that renders an SVG of the short Gallix Logo (just the G)
 * @member{boolean} varColour a boolean that must be set to true to make the logo use variables for the colour of the logo
 */
@Component({
  selector: 'app-logo-g',
  templateUrl: './logo-g.component.html',
  styleUrls: ['./logo-g.component.scss'],
})
export class LogoGComponent {
  /**
   * assign if the background for the logo is a constant or variable.
   *
   * @param varColour ? set to true for the logo color to change with preferred theme .
   */
  @Input() varColour?: boolean
}
