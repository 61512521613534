import {createAction, props} from '@ngrx/store';
import {StockItem, TableNavSettings, VatRates} from '../../../shared-utilities/models-old/datastructures';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {IAgGridExportSettings} from '../models/ag-grid-export-settings';
import {IPriceBandPerStore} from '../../../shared/shared-models/price-banding/price-band';
import {IStore} from '../../../shared/shared-models/store/store';
import {NGPReport} from '../../../shared-utilities/models-old/ngp-reports/ngp-report';

//===============================================================================================================
// Export to CSV
//===============================================================================================================
export const onExportDataToCSV = createAction(
  '[Shared Grid][Export Data to CSV] On Export Data to CSV',
  props<{ settings: IAgGridExportSettings }>()
);

//===============================================================================================================
// Get Table Nav Settings
//===============================================================================================================
export const getTableNavSettings = createAction(
  '[Shared Grid][Table Nav] Get Table Nav Settings'
);

export const getTableNavSettingsSuccess = createAction(
  '[Shared Grid][Table Nav] Get Table Nav Settings Success',
  props<{ settings: TableNavSettings }>()
);

export const getTableNavSettingsFailure = createAction(
  '[Shared Grid][Table Nav] Get Table Nav Settings Failure',
  props<{ error: IError }>()
);

//===============================================================================================================
// Price Banding
//===============================================================================================================
export const getPriceBandingForStores = createAction(
  '[Shared Grid][Price Banding] Get Price Banding for Stores'
);

export const getPriceBandingForStoresSuccess = createAction(
  '[Shared Grid][Price Banding] Get Price Banding for Stores Success',
  props<{ priceBandsPerStore: IPriceBandPerStore }>()
);

export const getPriceBandingForStoresFailure = createAction(
  '[Shared Grid][Price Banding] Get Price Banding for Stores Failure',
  props<{ error: IError }>()
);

export const setIsGetPriceBandingForStoresLoading = createAction(
  '[Shared Grid][Price Banding] Set Is Get Price Banding for Stores Loading',
  props<{ isLoading: boolean }>()
);

// ====================================================================================================
// Set Disabled/Enabled items
// ====================================================================================================
export const setItemDisabledStatus = createAction(
  '[Shared Grid][Item Disabling] Set Item to be Disabled',
  props<{ item: NGPReport | StockItem, enable: boolean }>(),
);

export const setItemDisabledWithStoreID = createAction(
  '[Shared Grid][Item Disabling] Set Item to be Disabled With StoreID',
  props<{ ngpReport: NGPReport | StockItem; store: IStore }>(),
);

//===============================================================================================================
// Get the vat Rate conversions for each store
//===============================================================================================================

export const getVatRateConversion = createAction(
  '[Shared Grid][VAT Rate] Get Vat Rate Conversion',
);

export const getVatRateConversionWithExistingVatRates = createAction(
  '[Shared Grid][VAT Rate] Get Vat Rate Conversion With Existing Vat Rates',
  props<{ userStores: IStore[], vatRates: { [storeId: string]: VatRates } }>(),
);

export const getVatRateConversionWithoutExistingVatRates = createAction(
  '[Shared Grid][VAT Rate] Get Vat Rate Conversion Without Existing Vat Rates',
  props<{ userStores: IStore[] }>(),
);

export const getVatRateConversionSuccess = createAction(
  '[Shared Grid][VAT Rate] Get Vat Rate Conversion Success',
  props<{ vatRates: { [storeId: string]: VatRates } }>(),
);

export const getVatRateConversionFailure = createAction(
  '[Shared Grid][VAT Rate] Get Vat Rate Conversion Failure',
  props<{ error: IError }>(),
);

