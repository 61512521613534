import {StockItem, StockItemLocallyExtended, StockItemUnsafeAdditions} from '../datastructures';

/** A string representation of the different types a stock items value could be. */
export type StockItemValTypeStr = 'string' | 'number' | 'date' | 'boolean' | 'string[]';

/**
 * Return a string representation of the type of the stock items key. ie. the {@link StockItemValTypeStr}
 */
export const getStockValueType = (key: keyof (StockItemUnsafeAdditions & StockItemLocallyExtended)): StockItemValTypeStr => {
  switch (key) {
    case 'storeId':
    case 'stockId':
    case 'code':
    case 'desc':
    case 'suppCode':
    case 'dep':
    case 'subDep':
    case 'suppUsedLast5':
    case 'suppUsedLast4':
    case 'suppUsedLast3':
    case 'suppUsedLast2':
    case 'suppUsedLast1':
    case 'suppUsedLastPrice5':
    case 'suppUsedLastPrice4':
    case 'suppUsedLastPrice3':
    case 'suppUsedLastPrice2':
    case 'suppUsedLastPrice1':
    case 'reportItemCode':
    case 'regularSuppCode':
    case 'binL':
    case 'genCode':
    case 'barcode':
    case 'lineColourCode':
      return 'string';
    case 'category':
    case 'prvSellingPri':
    case 'sellPriExcl1':
    case 'sellPriIncl1':
    case 'onHand':
    case 'avCost':
    case 'latestCost':
    case 'gp1':
    case 'nominalGP':
    case 'recommendedGP':
    case 'salesOrder':
    case 'purchaseOrder':
    case 'dlvColl':
    case 'vatR':
    case 'reportItemFactor':
    case 'onHoldCode':
    case 'user':
    case 'maxDisc':
    case 'packSize':
    case 'unitsYear':
    case 'ordLvl':
      return 'number';
    case 'suppUsedLastDate5':
    case 'suppUsedLastDate4':
    case 'suppUsedLastDate3':
    case 'suppUsedLastDate2':
    case 'suppUsedLastDate1':
    case 'lastMoved':
    case 'lastSold':
    case 'lastPurchase':
    case 'prvSellingPriDate':
    case 'created':
    case 'lastStockTake':
      return 'date';
    case 'discExempt':
    case 'noDecimal':
    case 'web':
    case 'sellUnderCost':
    case 'sellIntoNegative':
      return 'boolean';
    case '_tags':
      return 'string[]';
    default:
      return null;
  }
};
