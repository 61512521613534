import {Injectable} from '@angular/core';
import {BehaviorSubject, interval, Observable, Subscription} from 'rxjs';

import {FirebaseService} from './firebase.service';
import {timeBreakDown, TimeBreakDown} from '../functions-old/date-functions';
import {mergeMap} from 'rxjs/operators';
import {IStoreDataFreshnessFirestore} from '../../shared/shared-models/firebase/store-data-freshness';

// Todo move to new IStoreDataFreshnessInterface
export interface StoreDataFreshness {
  sales?: Date;
  shelfTalkers?: Date;
  stock?: Date;
  suppliers?: Date;
}

export const DATA_FRESHNESS_KEYS = ['sales', 'shelfTalkers', 'stock', 'suppliers'] as const;

export type StoreDataFreshnessAge = { [key in keyof StoreDataFreshness]: TimeBreakDown };

interface AgeObsOpts {
  storeId?: string; // 'all'
  keys?: (keyof StoreDataFreshness)[];
  frequency?: number;
}

@Injectable({
  providedIn: 'root'
})
export class DataFreshnessService {

  private stores: { order: string[]; names: { [storeId: string]: string } } = {order: [], names: {}};
  private storeSubs: { [storeId: string]: Subscription } = {};

  private allFreshnessBS = new BehaviorSubject<{ [storeId: string]: StoreDataFreshness }>({});
  private storeFreshnessBS: { [storeId: string]: BehaviorSubject<StoreDataFreshness> } = {};

  private allFreshnessChangesBS = new BehaviorSubject<{
    storeId: string;
    changes: StoreDataFreshness
  }>(null);
  private storeFreshnessChangesBS: { [storeId: string]: BehaviorSubject<StoreDataFreshness> } = {};


  constructor(
    private firebase: FirebaseService,
  ) {


    this.firebase.stores.subscribe((stores) => {
      const removed = this.stores.order.filter((storeId) => !stores.stores.hasOwnProperty(storeId));
      const added = stores.order.filter((storeId) => !this.stores.names.hasOwnProperty(storeId));

      removed.forEach((storeId) => {
        if (this.storeSubs[storeId]) {
          this.storeSubs[storeId].unsubscribe();
          delete this.storeSubs[storeId];
          delete this.storeFreshnessBS[storeId];
          delete this.storeFreshnessChangesBS[storeId];
        }
      });

      added.forEach((storeId) => {
        this.storeFreshnessBS[storeId] = new BehaviorSubject<StoreDataFreshness>(null);
        this.storeFreshnessChangesBS[storeId] = new BehaviorSubject<StoreDataFreshness>(null);

        const obs = this.firebase.subStoreDoc('data/singular_documents/data_timestamps', storeId);
        this.storeSubs[storeId] = obs.subscribe((data: IStoreDataFreshnessFirestore) => {
          const current = this.allFreshnessBS.value;
          const freshness: StoreDataFreshness = {};
          const changes: StoreDataFreshness = {};

          if (data) {
            for (const key of (Object.keys(data) as (keyof IStoreDataFreshnessFirestore)[])) {
              const date = data[key].toDate();
              let saveKey: keyof StoreDataFreshness;

              switch (key) {
                // Todo this needs to be done in either service or effects
                case 'shelf_talkers':
                  saveKey = 'shelfTalkers';
                  break;
                default:
                  saveKey = key;
              }
              freshness[saveKey] = date;

              if (!current[storeId]?.[saveKey] || current[storeId][saveKey].getTime() !== date.getTime()) {
                changes[saveKey] = date;
              }
            }
          }

          if (Object.keys(changes).length) {
            current[storeId] = freshness;
            this.allFreshnessBS.next(current);
            this.storeFreshnessBS[storeId].next(freshness);
            this.allFreshnessChangesBS.next({storeId, changes});
            this.storeFreshnessChangesBS[storeId].next(changes);
          }
        });
      });
    });
  }

  get freshness(): Observable<{ [storeId: string]: StoreDataFreshness }> {
    return this.allFreshnessBS.asObservable();
  }

  get freshnessChanges(): Observable<{ storeId: string; changes: StoreDataFreshness }> {
    return this.allFreshnessChangesBS.asObservable();
  }

  storeFreshness(storeId: string): Observable<StoreDataFreshness> {
    return this.storeFreshnessBS[storeId].asObservable();
  }

  storeFreshnessChanges(storeId: string): Observable<StoreDataFreshness> {
    return this.storeFreshnessBS[storeId].asObservable();
  }

  createAgeObserver(opts?: AgeObsOpts): Observable<{
    [storeId: string]: StoreDataFreshnessAge
  } | StoreDataFreshnessAge> {

    const options: AgeObsOpts = {
      storeId: 'all', keys: DATA_FRESHNESS_KEYS.map((k) => k), frequency: 10000
    };

    if (opts) {
      Object.keys(opts).forEach((key) => {
        if (opts[key]) {
          options[key] = opts[key];
        }
      });
    }

    const processStore = (freshness: StoreDataFreshness, time) => {
      const age: StoreDataFreshnessAge = {};

      for (const key of options.keys) {
        if (freshness?.[key]) {
          age[key] = timeBreakDown(time - freshness[key].getTime());

        }
      }
      return age;
    };

    return interval(options.frequency).pipe(mergeMap((_) => {
      const freshness = this.allFreshnessBS.value;
      const storeIds = options.storeId === 'all' ? Object.keys(freshness) : options.storeId;
      const time = (new Date()).getTime();

      if (typeof storeIds === 'string') {
        return [processStore(freshness[storeIds], time)];
      } else {
        const ages: { [storeId: string]: StoreDataFreshnessAge } = {};

        for (const storeId of storeIds) {
          ages[storeId] = processStore(freshness[storeId], time);
        }
        return [ages];
      }
    }));
  }
}
