import {Component, Input} from '@angular/core';

/**
 * A component that renders an SVG icon for "Receipt Text Send Outline"
 *
 * @member {string} tooltip ? The tooltip that needs to be displayed on this icon.
 */
@Component({
  selector: 'app-icon-receipt-text-send-outline',
  templateUrl: './icon-receipt-text-send-outline.component.html',
  styleUrls: ['./icon-receipt-text-send-outline.component.scss'],
})
export class IconReceiptTextSendOutlineComponent  {

  /**
   * Assign a tooltip that needs to be displayed on this icon as soon as the user hovers on it.
   *
   * @param tooltip ? The tooltip that needs to be displayed on this icon.
   */
  @Input() tooltip?: string;
}
