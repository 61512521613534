import {FirebaseOptions} from '@angular/fire/app';

export const environment = {
  environment: 'QA',
  production: false,
  storageUrl: 'gs://gallix-qa.appspot.com',
  firebaseConfig: {
    apiKey: 'AIzaSyBvoX7ultn4eUAUVENdbq2EkNXBrMCckN4',
    authDomain: 'gallix-qa.firebaseapp.com',
    projectId: 'gallix-qa',
    storageBucket: 'gallix-qa.appspot.com',
    messagingSenderId: '695323828389',
    appId: '1:695323828389:web:8f9fbdda36afd66088ed4c',
    measurementId: 'G-K4KT67B6EL'
  } as FirebaseOptions
};
