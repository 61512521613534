import {Component, Input} from '@angular/core';
import {setFitToGrid} from '../../../store/settings.actions';
import {Store} from '@ngrx/store';
import {Icons} from '../../../../../shared-modules/shared-icons/icons';

@Component({
  selector: 'app-settings-fit-to-grid',
  templateUrl: './settings-fit-to-grid.component.html',
  styleUrls: ['./settings-fit-to-grid.component.scss'],
})
export class SettingsFitToGridComponent {

  @Input() showAllColDefs: boolean;
  protected readonly icons: typeof Icons = Icons;

  constructor(
    private readonly store: Store,
  ) {
  }

  onToggleFitToTableClick(): void {
    this.store.dispatch(setFitToGrid({fitToGrid: !this.showAllColDefs}));
  }


}
