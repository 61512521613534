<app-modal-header [close]="close" [title]="'General NGP Settings ' + currentStore.name"></app-modal-header>

<ion-accordion-group>
  <ion-accordion value="disabledItems">
    <ion-item slot="header">
      <ion-label>Item Disabling Rules</ion-label>
    </ion-item>
    <div class="fixed-height" slot="content">
      <app-del-item-settings
        [isModal]="false"
        [lineColours]="lineColours$ | ngrxPush"
        [settings]="itemDisablingRules"
        [storeId]="currentStore.storeId"
        class="fixed-height">
      </app-del-item-settings>
    </div>
  </ion-accordion>
</ion-accordion-group>
