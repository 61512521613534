import {IFilterType} from '../../shared-models/filter-groups/filter-types';

export enum FilterTypesEnum {
  equalTo = 'equalTo',
  partiallyEqualTo = 'partiallyEqualTo',
  greaterThan = 'greaterThan',
  lessThan = 'lessThan',
  notEqualTo = 'notEqualTo',
  lessThanOrEqualTo = 'lessThanOrEqualTo',
  greaterThanOrEqualTo = 'greaterThanOrEqualTo',
  isOneOf = 'isOneOf',
  isNotAnyOf = 'isNotAnyOf',
  range = 'range'
}

export const FilterTypesString: IFilterType[] = [
  { enum: FilterTypesEnum.equalTo, description: 'equal to', inputs: 'single', symbol: '=' },
  { enum: FilterTypesEnum.partiallyEqualTo, description: 'partially equal to', inputs: 'single', symbol: ':' },
  { enum: FilterTypesEnum.notEqualTo, description: 'not equal to', inputs: 'single', symbol: '!=' },
  // { enum: FilterTypesEnum.isOneOf, description: 'is one of', inputs: 'multiple', symbol: '[]' },
  // { enum: FilterTypesEnum.isNotAnyOf, description: 'is not any of', inputs: 'multiple', symbol: '![]' },
];

export const FilterTypesNumber: IFilterType[] = [
  { enum: FilterTypesEnum.equalTo, description: 'equal to', inputs: 'single', symbol: '=' },
  { enum: FilterTypesEnum.notEqualTo, description: 'not equal to', inputs: 'single', symbol: '!=' },
  { enum: FilterTypesEnum.greaterThan, description: 'greater than', inputs: 'single', symbol: '>' },
  { enum: FilterTypesEnum.lessThan, description: 'less than', inputs: 'single', symbol: '<' },
  { enum: FilterTypesEnum.lessThanOrEqualTo,description: 'less than or equal to', inputs: 'single', symbol: '<=' },
  { enum: FilterTypesEnum.greaterThanOrEqualTo, description: 'greater than or equal to', inputs: 'single', symbol: '>=' },
  // { enum: FilterTypesEnum.isOneOf, description: 'is one of', inputs: 'multiple', symbol: '[]' },
  // { enum: FilterTypesEnum.isNotAnyOf, description: 'is not any of', inputs: 'multiple', symbol: '![]' },
  // { enum: FilterTypesEnum.range, description: 'range', inputs: 'multiple', symbol: '[..]' },
];

export const FilterTypesBoolean: IFilterType[] = [
  { enum: FilterTypesEnum.equalTo, description: 'equal to', inputs: 'single', symbol: '=' },
  { enum: FilterTypesEnum.notEqualTo, description: 'not equal to', inputs: 'single', symbol: '!=' },
  // { enum: FilterTypesEnum.isOneOf, description: 'is one of', inputs: 'multiple', symbol: '[]' },
  // { enum: FilterTypesEnum.isNotAnyOf, description: 'is not any of', inputs: 'multiple', symbol: '![]' },
];

export const FilterTypesDate: IFilterType[] = FilterTypesNumber;

// Enum for Filter Types
export enum FilterTypeTypesense {
  EqualTo = 'equal to',
  PartiallyEqualTo = 'partially equal to',
  NotEqualTo = 'not equal to',
  GreaterThan = 'greater than',
  LessThan = 'less than',
  GreaterThanOrEqualTo = 'greater than or equal to',
  LessThanOrEqualTo = 'less than or equal to',
  DoesNotContain = 'does not contain',
  IsOneOf = 'is one of',
  IsNotAnyOf = 'is not any of',
  Range = 'range',
}

// Enum for Logical Operators
export enum LogicalOperator {
  AND = 'AND',
  OR = 'OR',
}

export const filterTypeToSymbol: { [key in FilterTypeTypesense]: string } = {
  [FilterTypeTypesense.EqualTo]: '=',
  [FilterTypeTypesense.PartiallyEqualTo]: '',
  [FilterTypeTypesense.NotEqualTo]: '!=',
  [FilterTypeTypesense.GreaterThan]: '>',
  [FilterTypeTypesense.LessThan]: '<',
  [FilterTypeTypesense.GreaterThanOrEqualTo]: '>=',
  [FilterTypeTypesense.LessThanOrEqualTo]: '<=',
  [FilterTypeTypesense.DoesNotContain]: '!',
  [FilterTypeTypesense.IsOneOf]: '=',
  [FilterTypeTypesense.IsNotAnyOf]: '!=',
  [FilterTypeTypesense.Range]: '..',
};

// Mapping from LogicalOperator to Symbols
export const operatorMapping: { [key in LogicalOperator]: string } = {
  [LogicalOperator.AND]: '&&',
  [LogicalOperator.OR]: '||',
};

// Your sItemKeyToInt mapping

