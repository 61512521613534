<ng-container *ngIf="params?.displayName && (!params?.column?.getColId())">
  <span>{{ params.displayName }}</span>
</ng-container>

<ng-container
  *ngIf="params?.displayName && params?.column?.getColId() && params?.additionalData?.length > 0 ;
  else checkBox">
  <ion-button fill="clear" [id]="getButtonUniqueID">
    <div class="menu-item-container">
      <app-display-icon
        [colour]="'white'"
        [border]="false"
        [icon]="icons.menu"
        [tooltip]="'Open ' + params.displayName + ' Menu'">
      </app-display-icon>
      <span>{{ params.displayName }}</span>
      <ng-container
        *ngIf="sortIcon?.[storeId]?.[params.column.getColDef().field]">
        <app-display-icon
          [colour]="'yellow'"
          [border]="false"
          [icon]="sortIcon[storeId][params.column.getColDef().field]">
        </app-display-icon>
      </ng-container>
      <ng-container *ngIf="editingColumns?.[storeId]?.[params.column.getColDef().field]">
        <app-display-icon
          [colour]="'red'"
          [border]="false"
          [icon]="icons.pencil">
        </app-display-icon>
      </ng-container>
    </div>
  </ion-button>

  <ion-popover [trigger]="getButtonUniqueID" [dismissOnSelect]="true">
    <ng-template>
      <ion-content>
        <ion-list>
          <ng-container *ngFor="let menuItem of params.additionalData; let index">
            <ng-container *ngIf="isButtonVisible(menuItem)">
              <ion-item
                [id]="menuItem.callbackParams.colId + menuItem.callbackParams.action"
                [button]="true"
                (click)="menuItem.callback(menuItem.callbackParams)">
                <div class="menu-item-container">
                  <app-display-icon
                    [colour]="menuItem.colour"
                    [border]="false"
                    [icon]="menuItem.icon"
                    [tooltip]="menuItem.displayName + ' ' + params.displayName">
                  </app-display-icon>
                  <span>{{ menuItem.displayName }}</span>
                </div>
              </ion-item>
            </ng-container>

            <ion-popover
              side="end"
              alignment="start"
              *ngIf="menuItem.options?.length > 0"
              [trigger]="menuItem.callbackParams.colId + menuItem.callbackParams.action"
              [dismissOnSelect]="true">
              <ng-template>
                <ion-content>
                  <ion-list>
                    <ng-container *ngFor="let opt of menuItem.options">
                      <ion-item
                        [id]="opt.callbackParams.colId + opt.callbackParams.action"
                        [button]="true"
                        (click)="opt.callback(opt.callbackParams)">
                        <div class="menu-item-container">
                          <app-display-icon
                            [colour]="opt.colour"
                            [border]="false"
                            [icon]="opt.icon"
                            [tooltip]="opt.displayName + ' ' + params.displayName">
                          </app-display-icon>
                          <span>{{ opt.displayName }}</span>
                        </div>
                      </ion-item>
                    </ng-container>
                  </ion-list>
                </ion-content>
              </ng-template>
            </ion-popover>
          </ng-container>
        </ion-list>
      </ion-content>
    </ng-template>
  </ion-popover>
</ng-container>

<ng-template #checkBox>
  <ion-checkbox
    *ngIf="params?.column?.getColId() === 'isSelected'"
    [indeterminate]="indeterminate"
    [checked]="selected.length > 0 && selected.length === ngpReport.length"
    (click)="onHeaderCheckboxClick($event)">
  </ion-checkbox>
</ng-template>
