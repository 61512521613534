import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-page-header-old',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {

  @Input() title: string;

  constructor() { }

  ngOnInit() {
    if (!this.title) {
      this.title = '<Page Title>';
    }
  }

}
