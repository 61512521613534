import {Component, OnInit} from '@angular/core';
import {AlertController, IonNav} from '@ionic/angular';
import {forkJoin} from 'rxjs';
import {Feature} from '../../../../shared-utilities/models-old/datastructures';
import {FirebaseService} from '../../../../shared-utilities/services-old/firebase.service';
import {FireAuthService} from '../../../../shared-utilities/services-old/fire-auth.service';
import {StoreObject} from '../../../../shared-utilities/models-old/store/store-object';

interface TempComponentAccess {
  priceBanding?: boolean;
}

@Component({
  selector: 'app-editors-config',
  templateUrl: './editors-config.component.html',
  styleUrls: ['./editors-config.component.scss'],
})
export class EditorsConfigComponent implements OnInit {

  stores: StoreObject;
  changes: { [document: string]: { change: any; feature: Feature | 'user'; storeId?: string } };

  // thingsToSave: { [comp: string]: number };
  readonly canClose: { [path: string]: () => boolean } = {};
  readonly show: { [path: string]: boolean } = {};

  private access: TempComponentAccess = {};

  constructor(
    private firestore: FirebaseService,
    private fireAuth: FireAuthService,
    private alertControl: AlertController,
    private nav: IonNav,
  ) {
  }

  ngOnInit() {
    this.firestore.stores.subscribe((storesObj) => {
      this.stores = storesObj;

      // TODO: BAD !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      for (const storeId of storesObj.order) {
        this.access.priceBanding = true;

        if (this.fireAuth.hasAccess(storeId, {ruleID: 'a.'}) !== true) {
          this.access.priceBanding = false;
          break;
        }
      }
    });
    // setTimeout(() => this.showSection('stockEditors'), 500);
  }

  setChanges = (document: string, change: any, storeId?: string, feature: Feature | 'user' = 'shared') => {
    if (change) {
      if (!this.changes) {
        this.changes = {};
      }
      this.changes[document] = {change, feature, storeId};
    } else {
      if (this.changes && this.changes[document]) {
        delete this.changes[document];

        if (Object.keys(this.changes).length === 0) {
          this.changes = null;
        }
      }
    }
  };

  // setSaves2Save = (compName: string, count: number) => {
  //   if (count > 0) {
  //     if (!this.thingsToSave) { this.thingsToSave = {}; }
  //     this.thingsToSave[compName] = count;
  //   } else {
  //     if (this.thingsToSave && this.thingsToSave.hasOwnProperty(compName)) {
  //       delete this.thingsToSave[compName];
  //       if (Object.keys(this.thingsToSave).length === 0) {
  //         this.thingsToSave = null;
  //       }
  //     }
  //   }
  // };

  close = async () => {
    const ac = await this.alertControl.create({
      header: 'Close Editors Configurator', subHeader: 'Are you sure want to close this config window?',
      message: 'Any unsaved changes to your editors will not be saved.', cssClass: ['custom-alert'],
      buttons: ['No', {text: 'Im sure', role: 'y'}],
    });
    await ac.present();
    const {role} = await ac.onDidDismiss();

    if (role === 'y') {
      this.nav.pop().then();
    }
  };

  showSection(path: string) {
    if (this.show[path]) {
      if (!this.canClose.hasOwnProperty(path) || this.canClose[path]()) {
        delete this.show[path];
      }
    } else {
      this.show[path] = true;
    }
  }

  hasAccess(k: keyof TempComponentAccess): boolean {
    return this.access[k];
  }

  async save() {
    const ac = await this.alertControl.create({header: 'Saving', backdropDismiss: false});
    await ac.present();
    const promises: Promise<void>[] = [];
    for (const document of Object.keys(this.changes)) {
      const {change, storeId, feature} = this.changes[document];


      if (feature === 'user') {
        promises.push(this.firestore.updateUserSingularDocuments(document, change));
      } else {
        promises.push(this.firestore.updateStoreDataDoc(document, storeId, change, feature, true));
      }
    }
    forkJoin(promises).toPromise().then(() => {
      this.changes = null;
      ac.dismiss();
    }).catch((e) => {
      console.error(e);
      ac.dismiss();
    });
  }
}
