import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {MatTooltipModule} from '@angular/material/tooltip';
import {PushModule} from '@ngrx/component';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../shared-modules/shared-module/shared-module.module'
import {SharedIconsModule} from '../../shared-modules/shared-icons/shared-icons.module';
import {SharedGridModule} from '../../shared-modules/shared-grid/shared-grid.module';
import {STORE_SETTINGS_COMPONENTS} from './components/settings-store';
import {SettingsReducer} from './store/settings.reducer';
import {SettingsEffects} from './store/settings.effects';
import {MatButtonModule} from '@angular/material/button';
import {USER_SETTINGS_COMPONENT} from './components/settings-user';

@NgModule({
  declarations: [
    ...STORE_SETTINGS_COMPONENTS,
    ...USER_SETTINGS_COMPONENT,
  ],
  imports: [
    CommonModule,
    IonicModule,
    MatTooltipModule,
    PushModule,
    FormsModule,
    SharedGridModule,
    SharedIconsModule,
    SharedModule,
    StoreModule.forFeature('settings', SettingsReducer),
    EffectsModule.forFeature([SettingsEffects]),
    MatButtonModule,
    ReactiveFormsModule,
  ],
})
export class SettingsModule {
}
