import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {PopoverController} from '@ionic/angular';
import {
  DataFreshnessService,
  StoreDataFreshnessAge
} from '../../../../shared-utilities/services-old/data-freshness.service';
import {IRange, relativeToRange} from '../../../../shared-utilities/functions-old/ranges';
import {DataFreshnessPopupComponent} from '../data-freshness-popup/data-freshness-popup.component';

export type IndicatorStatus = 'good' | 'weak' | 'dead';

@Component({
  selector: 'app-data-freshness-indicator',
  templateUrl: './data-freshness-indicator.component.html',
  styleUrls: ['./data-freshness-indicator.component.scss'],
})
export class DataFreshnessIndicatorComponent implements OnInit, OnDestroy {

  @Input() storeId: string;
  @Input() timestamp: string | string[];
  @Input() lights?: boolean = true;
  @Input() dataKeys?: (keyof StoreDataFreshnessAge)[];
  @Input() frequency?: number;

  // Things below week range will be 'good', above will be 'dead'
  @Input() weekRange?: IRange = {start: {v: 180000}, end: {v: 300000}};

  private age: StoreDataFreshnessAge;
  private currentStatuses: { [key in keyof StoreDataFreshnessAge]: IndicatorStatus } = {};
  private currentStatus: IndicatorStatus;
  private ageSub: Subscription;

  private dataBS: BehaviorSubject<{
    age: StoreDataFreshnessAge;
    currentStatuses: { [key in keyof StoreDataFreshnessAge]: IndicatorStatus };
    currentStatus: IndicatorStatus;
  }>;

  constructor(
    private freshService: DataFreshnessService,
    private popControl: PopoverController,
  ) {
  }

  get status(): 'good' | 'weak' | 'dead' {
    return this.currentStatus;
  }

  ngOnInit(): void {
    const obs = this.freshService.createAgeObserver({
      storeId: this.storeId, keys: this.dataKeys, frequency: this.frequency
    });
    this.ageSub = obs.subscribe((age: StoreDataFreshnessAge) => {
      if (!age) {
        this.currentStatuses = {};
        this.currentStatus = null;
        return;
      }

      let inRange = false;
      let aboveRange = false;

      for (const key of this.dataKeys) {
        if (age[key]?.ms) {
          const relative = relativeToRange(age[key].msTotal, this.weekRange);
          switch (relative) {
            case 'below':
              this.currentStatuses[key] = 'good';
              break;
            case 'in':
              this.currentStatuses[key] = 'weak';
              inRange = true;
              break;
            case 'above':
              this.currentStatuses[key] = 'dead';
              aboveRange = true;
              break;
          }
        }
      }
      this.currentStatus = aboveRange ? 'dead' : (inRange ? 'weak' : 'good');
      this.currentStatus = Object.keys(age)?.length > 0 ? this.currentStatus : 'dead';
      this.age = age;

      if (this.dataBS) {
        this.dataBS.next({age: this.age, currentStatus: this.currentStatus, currentStatuses: this.currentStatuses});
      }
    });
  }

  ngOnDestroy(): void {
    this.ageSub.unsubscribe();
  }

  async onDisplayMoreInfo(event: Event): Promise<void> {
    this.dataBS = new BehaviorSubject({
      age: this.age, currentStatus: this.currentStatus, currentStatuses: this.currentStatuses
    });

    const pc = await this.popControl.create({
      component: DataFreshnessPopupComponent,
      componentProps: {
        dataObs: this.dataBS.asObservable(),
        popoverReference: this.popControl
      }, event
    });
    await pc.present();
    await pc.onDidDismiss();
    this.dataBS.complete();
    this.dataBS = null;
  }

}
