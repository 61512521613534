import {Component, Inject, OnInit} from '@angular/core';
import {Message, StoreInfo} from '../../../../shared-utilities/models-old/datastructures';

@Component({
  selector: 'app-msg-user-signup-notification',
  templateUrl: './msg-user-signup-notification.component.html',
  styleUrls: ['./msg-user-signup-notification.component.scss'],
})
export class MsgUserSignupNotificationComponent implements OnInit {

  //  “{{email}}” the link you created at {{time}} on {{date}}.<br>
  //   {{userName}}.
  email: string;
  time: string;
  date: string;
  userNameMsg: string;

  constructor(
    @Inject('INPUT') private useValue: {
      id: number | string;
      message: Message;
      getStoreInfo: (storeId: string, isStore: boolean) => StoreInfo | { value: string; name?: string };
    },
  ) {
  }

  ngOnInit() {
    const msg = this.useValue.message;
    this.email = msg.payload.data.email;
    this.time = msg.timestamp.toLocaleTimeString();
    this.date = `${msg.timestamp.getDate()}/${msg.timestamp.getMonth()}/${msg.timestamp.getFullYear()}`;
    this.userNameMsg = msg.payload.data.userName ? `The user chose the username: "${msg.payload.data.userName}"` :
      'No username was provided.';
  }

}
