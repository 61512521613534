import {Injectable, NgZone} from '@angular/core';
import {fromEvent, merge, Observable, Subscription, timer} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';
import {ModalController} from '@ionic/angular';
import {
  InactivityModalComponent
} from '../../shared-modules/shared-module/components/inactivity-modal/inactivity-modal.component';

//      s      m   # m
// 1 * 1000 * 60 * 30 = 1800000ms (30 minutes)
const TIMEOUT = 1200000 as const;

@Injectable({
  providedIn: 'root'
})
export class InactivityService {
  private events$: Observable<Event>;
  private inactivitySubscription: Subscription | undefined;
  private subscriptions: { [id: string]: Subscription } = {};

  constructor(
    private ngZone: NgZone,
    private modalController: ModalController,
  ) {
    this.events$ = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'mousedown'),
      fromEvent(document, 'keypress'),
      fromEvent(document, 'touchstart'),
      fromEvent(window, 'load')
    );
  }

  startWatching(): void {
    this.ngZone.runOutsideAngular(() => {
      this.inactivitySubscription = this.events$
        .pipe(
          switchMap(() => timer(TIMEOUT).pipe(takeUntil(this.events$)))
        )
        .subscribe(async () => {
          const mc = await this.modalController.create({
            component: InactivityModalComponent,
            cssClass: ['refresh-modal'],
            backdropDismiss: false
          });
          await mc.present();
          this.unsubscribeAll();
          //logs the user out and takes them to the login page
          // this.fireAuth.logout(false, true).then();
        });
      this.addSubscription(this.inactivitySubscription, 'Idle check');

    });
  }

  addSubscription(subscription: Subscription, subId: string): void {
    if (this.subscriptions[subId]) {
      console.error(`Cannot add Subscription to inactivity.service. Id '${subId}' already exists.`);
      return;
    }
    this.subscriptions[subId] = subscription;

  }

  unsubscribeAll(): void {


    for (const subId in this.subscriptions) {
      if (this.subscriptions.hasOwnProperty(subId)) {
        this.subscriptions[subId].unsubscribe();

      }
    }
    this.subscriptions = {};
  }
}
