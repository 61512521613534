import {Component} from '@angular/core';

@Component({
  selector: 'app-inactivity-modal',
  templateUrl: './inactivity-modal.component.html',
  styleUrls: ['./inactivity-modal.component.scss'],
})
export class InactivityModalComponent {
  onRefreshPageClick(): void {
    window.location.reload();
  }
}
