import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mushroom-easter-egg',
  templateUrl: './mushroom-easter-egg.component.html',
  styleUrls: ['./mushroom-easter-egg.component.scss'],
})
export class MushroomEasterEggComponent {


}
