import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {selectCurrentPage} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {setEditedItem} from '../../../../features/stock-manager/store/stock-manager.actions';

@Component({
  selector: 'app-cell-renderer-text',
  templateUrl: './cell-renderer-text.component.html',
  styleUrls: ['./cell-renderer-text.component.scss'],
})
export class CellRendererTextComponent implements ICellRendererAngularComp {

  value: any;
  params: ICellRendererParams;
  width: string;
  type: string;
  currentPage$: Observable<string>
  currentPage: string;
  constructor(
    private readonly store: Store,
  ) {}

  agInit(params: ICellRendererParams): void {
    this.currentPage$ = this.store.select(selectCurrentPage)
    this.currentPage$.subscribe((page: string) => {
      this.currentPage = page;
    })
    this.value = params.value;
    this.type = params.colDef.field;
    this.params = {...params};
    this.params.data = {...params.data};
    this.params.colDef = {...params.colDef};
    this.params.data.originalValue = {...params.data.originalValue};
    this.params.data.originalValue[this.params.colDef.field] = {...params.data.originalValue[this.params.colDef.field]};
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  onUndoChangesClick(): void {
    this.value = this.params.data.originalValue[this.params.colDef.field].value;
    this.params.data[this.params.colDef.field] = this.value;
    if (this.currentPage ==='ngp-report'){
      GridUtils.removeIsEditingFlagFromNgpItem(this.params.data, this.params.colDef.field, this.store)
    } else{
      this.store.dispatch(setEditedItem({stockItem: this.params.data}))
    }
    GridUtils.updateIsEditedAndForceRefresh(this.params);
  }

}
