<div id="filter-component" [class]="stockPageStyle ? 'stock-page-style': ''">
  <ng-container *ngIf="type === 'search'; else queryFilters">
    <div id="search-filters">
      <div>
        <span>Add Filter</span>
        <span>
          <button [disabled]="lock" (click)="addSearchFilter('tag')" [class]="(newFilter && newFilter.type === 'tag' ? 'selected' : '') + (editing ? ' disabled' : '')">Tags</button>
          <button [disabled]="lock" (click)="addSearchFilter('values')" [class]="(newFilter && newFilter.type === 'values' ? 'selected' : '') + (editing ? ' disabled' : '')">Values</button>
        </span>
      </div>
    </div>
    <div class="new-filter" *ngIf="newFilter">
      <ng-container *ngIf="newFilter.type === 'tag'; else values">
        <div class="tags-list">
          <ng-container *ngIf="newFilter.filter">
            <div class="tag" *ngFor="let tag of newTags">
              {{ tag }}
            </div>
          </ng-container>
          <ion-input class="ion-no-padding" debounce="1000"
                     (ionChange)="addTags($event)"
                     (keydown.backspace)="addTags($event, 'backspace')"
                     (focusout)="addTags($event, 'lose-focus')"
                     matTooltip="Enter one or more tags (comma seperated) to be matched. A match will be accepted if an
                     item has one or more of these tags."
          >
          </ion-input>
        </div>
      </ng-container>
      <ng-template #values>
        <div class="values-filter">
          <div>
            <ion-select class="ion-no-padding" [(ngModel)]="newFilter.filter.itemIntKey" placeholder="Select field">
              <ion-select-option *ngFor="let key of filterable" [value]="key">
                {{ k2t[key] }}
              </ion-select-option>
            </ion-select>
            <div>
              {{ newFilter.filter.itemIntKey ? 'type: ' + typeOKey(newFilter.filter.itemIntKey) : '' }}
            </div>
          </div>
          <div *ngIf="newFilter.filter.itemIntKey" class="function">
            <ng-container *ngIf="newFilter.filter.condition === 'TO'; else normalInput">
              <ion-input class="ion-no-padding range-input range1" debounce="600" (ionChange)="setFilterRange($event, 'start')"
                         [value]="editing && isArray(editing.value) ? editing.value[0] : ''"
              ></ion-input>
              <ion-select class="ion-no-padding" placeholder="Select condition" [(ngModel)]="newFilter.filter.condition">
                <ion-select-option *ngFor="let con of conditionsForType(k2t[newFilter.filter.itemIntKey])" [value]="con">
                  {{ con }}
                </ion-select-option>
              </ion-select>
              <ion-input class="ion-no-padding range-input range2" debounce="600" (ionChange)="setFilterRange($event, 'end')"
                         [value]="editing && isArray(editing.value) ? editing.value[1] : ''"
              ></ion-input>
            </ng-container>
            <ng-template #normalInput>
              <ion-select class="ion-no-padding" placeholder="Select condition" [(ngModel)]="newFilter.filter.condition">
                <ion-select-option *ngFor="let con of conditionsForType(k2t[newFilter.filter.itemIntKey])" [value]="con">
                  {{ con }}
                </ion-select-option>
              </ion-select>
              <ion-input class="ion-no-padding" [type]="key2Type[newFilter.filter.itemIntKey] === 'date' ? 'date' : ''"
                         [value]="editing ? editing.value : ''"
                         debounce="600" (ionChange)="setFilterValue($event)"
              ></ion-input>
            </ng-template>
          </div>
        </div>
      </ng-template>
      <div>
        <button [disabled]="lock" (click)="cancelNew()">Cancel</button>
        <ng-container *ngIf="!editing; else finishEdit">
          <button (click)="addNewFilter()"
                  [disabled]="lock || !(selectedFilterGroup && newFilter.valueValid && newFilter.filter && newFilter.filter.condition)"
          >
            Add
          </button>
        </ng-container>
        <ng-template #finishEdit>
          <button (click)="finishEditing()"
                  [disabled]="lock || !newFilter.valueValid"
          >
            Finish
          </button>
        </ng-template>
      </div>
    </div>


    <ng-container *ngTemplateOutlet="filterGroup; context: {group: filters}"></ng-container>

    <div *ngIf="!newFilter" class="group-controls">
      <div>
        <button [disabled]="lock" (click)="addGroup()">Add Group</button>
        <button (click)="deleteGroup()" [disabled]="lock || !selectedFilterGroup">Delete Group</button>
      </div>
    </div>
  </ng-container>

  <ng-template #queryFilters>
    <div>

    </div>
  </ng-template>

  <div class="main-buttons">
    <ion-button size="small" color="dark" fill="outline" (click)="saveFilters()" [disabled]="lock || newFilter">Save/Load</ion-button>
    <span [matTooltip]="newFilter ? 'Must complete new filter first' : (!changeMade && filters.filters.length > 0 ? 'Filter already applied' : '')">
      <ion-button size="small" color="dark" fill="outline" (click)="applyFilters()" [disabled]="lock|| !changeMade || newFilter">Apply Filters</ion-button>
    </span>
  </div>
</div>


<ng-template #filterGroup let-filter="group">
  <ng-container *ngIf="filter.hasOwnProperty('filters'); else filterElement">
    <div class="filter-group {{ filter.type.toLowerCase() }} {{ selectedFilterGroup === filter ? 'selected' : '' }}"
         (dragenter)="groupDragEnter($event)"
         (dragleave)="groupDragLeave($event)"
         (dragover)="$event.preventDefault()"
         (drop)="groupDragDrop($event, filter)"
    >
      <div class="group-header" (click)="selectedFilterGroup=lock ? selectedFilterGroup : filter">
        <span>
          <i *ngIf="selectedFilterGroup === filter">selected</i>
          <ion-icon name="caret-forward-outline"></ion-icon>
        </span>
        <span (click)="filter.type = lock ? filter.type : cycleFGType(filter.type)">{{ filter.type }}</span>
        <span>
          <ion-icon name="move-outline" draggable="true" *ngIf="filter !==filters; else isRoot"
                    (dragstart)="groupDragStart($event, filter)"
          >
          </ion-icon>
          <ng-template #isRoot>
            <ion-icon name="close-circle-outline" *ngIf="filters.filters.length > 0"
                      color="danger" matTooltip="Clear filters"
                      (click)="clear()"
            >
            </ion-icon>
          </ng-template>
        </span>
      </div>
      <ng-container [class]="'filter-filter ' + filter.type.toLowerCase()">
        <ng-container *ngFor="let child of filter.filters; let idx = index">
          <ng-container *ngTemplateOutlet="filterGroup; context: {group: child}"></ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #filterElement>
    <div [class]="'filter' + (newFilter || (editing && editing !== filter) ? ' disabled' : '')">
      <div (click)="editFilter(filter)">
        <div>{{ k2t[filter.itemIntKey] ? k2t[filter.itemIntKey] : filter.itemIntKey }}</div>
        <div>{{ filter.condition }}</div>
        <div>{{ format(filter.value) }}</div>
      </div>
      <div>
        <ion-icon name="close-outline" color="red" (click)="deleteFilter(filter)"></ion-icon>
      </div>
    </div>
  </ng-template>
</ng-template>
