<app-modal-header [close]="close" [title]="'User Settings'"></app-modal-header>

<ion-content>
  <ion-accordion-group #accordionGroup>
    <ion-accordion value="tableNav">
      <ion-item slot="header">
        <ion-label>Table Settings</ion-label>
      </ion-item>
      <div slot="content" >
        <div class="table-settings">
          <h1>Fit to Table Settings</h1>
          <app-settings-fit-to-grid
            [showAllColDefs]="(fitToGrid$ | ngrxPush)">
          </app-settings-fit-to-grid>

          <h1>Table Navigation Settings</h1>
          <app-shared-grid-table-navigation
            (settingsChange)="setTableNave($event)"
            [navSettings]="tableNavigationSettings$ | ngrxPush">
          </app-shared-grid-table-navigation>
        </div>


      </div>
    </ion-accordion>

    <ion-accordion value="ngpPreviewColumns">
      <ion-item slot="header">
        <ion-label>Set NGP Preview Columns</ion-label>
      </ion-item>
      <div slot="content">
        <app-settings-preview-columns
          [colDefs]="ngpReportGridColDefs"
          [previewColumns]="ngpColPreviewSettings$ | ngrxPush"
          [type]="'ngp'">
        </app-settings-preview-columns>
      </div>
    </ion-accordion>

    <ion-accordion value="stockManagerPreviewColumns">
      <ion-item slot="header">
        <ion-label>Set Stock Manager Preview Columns</ion-label>
      </ion-item>
      <div slot="content">
        <app-settings-preview-columns
          [colDefs]="stockManagerGridColDefs"
          [previewColumns]="stockManagerColPreviewSettings$ | ngrxPush"
          [type]="'stock'">
        </app-settings-preview-columns>
      </div>
    </ion-accordion>
  </ion-accordion-group>
</ion-content>

