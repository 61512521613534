import {sItemIntToKey} from '../../models-old/datastructures';
import {DocumentData} from '@angular/fire/compat/firestore';
import {IStore} from '../../../shared/shared-models/store/store';
import {StockPack, StockPackItem} from '../../models-old/stock/stock-pack';

/**
 * @class ObjectUtils
 * Utilities for manipulating and displaying numbers.
 *
 * @member deleteNonObjectProperties Delete properties not related to an object model / interface.
 * @member castObject Delete properties not related to an object model / interface.
 * @member objectify Objectify a DocumentDataItem for a given store.
 */
export class ObjectUtils {

  /**
   * Delete properties not related to an object model / interface.
   *
   * @param initialObject The object whose properties needs to be checked.
   * @param resultObjectModel Cast an object using item integers to keys.
   */
  static deleteNonObjectProperties<ResultModel, InitialModel>(initialObject: InitialModel, resultObjectModel: ResultModel): ResultModel {
    const result: ResultModel = {} as ResultModel;
    Object.keys(resultObjectModel).forEach((key: string): void => {
      if (key in initialObject) {
        result[key] = initialObject[key];
      } else {
        switch (typeof resultObjectModel[key]) {
          case 'string':
            result[key] = '';
            break;
          case 'boolean':
            result[key] = false;
            break;
        }
      }
    });
    return result;
  }

  /**
   * Cast an object using item integers to keys.
   * This is done by using `sItemIntToKey`.
   *
   * @param castObject The object that needs to be cast to its return type.
   * @param store ? The store owning the stock item.
   */
  static castObject<CastType>(castObject: any, store?: IStore): CastType {
    const newObject: CastType = store
      ? {storeId: store.storeId, store: store.name} as CastType
      : {} as CastType;
    Object.keys(castObject)
      .forEach((key: string): void => {
        newObject[sItemIntToKey[key]] = castObject[key];
      });
    return newObject;
  }

  /**
   * Objectify a `DocumentDataItem` for a given store.
   *
   * @param isCasting Should the object be cast to a specific type?
   * @param next The `DocumentData` array that needs to be objectified.
   * @param idField The object id field.
   * @param store The store owning the stock data.
   */
  static objectify<CastType>(isCasting: boolean, next: DocumentData[], idField: string, store: IStore): any[] {
    let stockPack: StockPack | StockPackItem = {} as StockPack | StockPackItem;
    if (isCasting) {
      stockPack.ids = next.map((rawItem: DocumentData) => {
        stockPack.items[rawItem[idField]] = this.castObject<CastType>(rawItem, store);
        return rawItem[idField];
      });
    } else {
      stockPack = {ids: [], items: {}};
      stockPack.ids = next.map((rawItem: DocumentData) => {
        stockPack.items[rawItem[idField]] = this.castObject<CastType>(rawItem, store);
        return rawItem[idField];
      });
    }
    return [stockPack];
  }

}

