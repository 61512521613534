<svg
  viewBox="0 0 512 512"
  xmlns="http://www.w3.org/2000/svg">
  <title *ngIf="tooltip">{{ tooltip }}</title>
  <g transform="translate(0.000000,252.000000) scale(0.100000,-0.100000)">
    <path
      d="M1625 2509 c-397 -54 -736 -291 -909 -636 -93 -186 -131 -495 -90 -734 63 -367 304 -685 639 -843 160 -76 315 -107 535 -108 l155 0 27 -38 c33 -47 103 -96 176 -123 48 -17 87 -21 240 -25 100 -2 182 0 181 4 0 5 -100 207 -222 449 -163 322 -230 445 -250 460 -25 18 -46 20 -203 23 -96 2 -174 1 -174 -3 0 -3 25 -91 55 -194 30 -104 55 -192 55 -196 0 -15 -185 4 -255 25 -215 67 -404 232 -495 434 -67 147 -90 366 -55 529 57 272 268 508 530 594 117 38 289 44 405 14 153 -40 274 -113 382 -230 148 -161 212 -330 212 -561 0 -154 -18 -239 -80 -364 l-36 -72 103 -193 103 -193 38 44 c182 210 281 483 281 778 0 297 -99 575 -281 788 -141 165 -405 309 -654 357 -101 20 -318 27 -413 14z"/>
    <path
      d="M172 2396 c-59 -19 -107 -58 -139 -114 -24 -39 -28 -58 -28 -117 1 -100 44 -173 128 -216 188 -97 405 82 343 284 -40 131 -177 204 -304 163z"/>
    <path d="M32 1024 l3 -716 24 -19 c21 -17 41 -19 212 -19 l189 0 -2 706 -3 706 -33 29 -32 29 -180 0 -180 0 2 -716z"/>
  </g>
</svg>
