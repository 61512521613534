import {Injectable} from '@angular/core';
import {AngularFirestore, CollectionReference} from '@angular/fire/compat/firestore';
import {combineLatest, Observable, of} from 'rxjs';
import {IColleagues, IColleagueUser, IUserAccess} from '../../shared-models/user-access/user-access';
import {catchError, map, switchMap} from 'rxjs/operators';
import {IUser} from '../../shared-models/user-access/user';
import {IGroupAccessPerStore} from '../../shared-models/user-access/group-access';
import {FirebaseService} from '../../../shared-utilities/services-old/firebase.service';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {hasAccessToRule} from '../../shared-utils/user-access/user-access.utils';
import firebase from 'firebase/compat/app';
import {path_user_access_userId} from '../database-paths';

@Injectable({
  providedIn: 'root',
})
export class CollectionUserAccessColleaguesService {
  constructor(
    private angularFirestore: AngularFirestore,
    private firebaseService: FirebaseService,
  ) {
  }

  public getColleagues(userId: string): Observable<IColleagues> {
    return this.angularFirestore.doc<IUserAccess>(`/user_access/${userId}`).valueChanges().pipe(
      switchMap((userAccess: IUserAccess) => {
        if (userAccess) {
          const storeList = userAccess.storeList;
          return this.getUsersForStores$(storeList, userId);
        } else {
          return of({} as IColleagues);
        }
      }),
      catchError((error: IError) => {
        console.error('Error fetching user access:', error);
        return of({});
      }),
    );
  }

  public async setColleagues(newEmail: string, groupAccessPerStore: IGroupAccessPerStore, colleague: IColleagueUser, merge: boolean = false, newUser: boolean = false, resendInvite: boolean = false): Promise<void> {
    const storeList: string[] = [];
    const storesWithAccessCode: { [storeId: string]: number } = {};

    for (const storeId of Object.keys(groupAccessPerStore)) {
      const groupAccessCode = groupAccessPerStore[storeId].groupAccessCode;

      if (groupAccessCode !== 0) {
        storeList.push(storeId);
        storesWithAccessCode[storeId] = groupAccessCode;
      }

      if (!storesWithAccessCode[storeId]) {
        try {
          await this.angularFirestore.doc(path_user_access_userId(newEmail)).update({
            [`stores.${storeId}`]: firebase.firestore.FieldValue.delete(),
          });
        } catch (error) {
          console.error('Error deleting store entry:', error);
        }
      }
    }

    const userAccessData: IUserAccess = {
      features: 7,
      storeList: storeList,
      stores: storesWithAccessCode,
      adminAccessStores: [],
      created: colleague?.created ?? new Date(),
      lastPasswordLinkTimestamp: resendInvite ? new Date() : colleague.lastPasswordLinkTimestamp,
      status: 'PENDING',
    };

    await this.setUserAccessDocumentForOtherUser(newEmail, userAccessData, merge);
    if (newUser) {
      const userDocument: IUser = {
        id: newEmail,
        pp: '',
        userName: newEmail,
      };
      await this.firebaseService.setUserDocument(newEmail, userDocument, merge);
    }

  }

  public async setNonAccessibleColleague(newEmail: string, groupAccessPerStore: IGroupAccessPerStore): Promise<void> {
    const storeList: string[] = [];
    const storesWithAccessCode: { [storeId: string]: number } = {};

    for (const storeId of Object.keys(groupAccessPerStore)) {
      const groupAccessCode = groupAccessPerStore[storeId].groupAccessCode;

      if (groupAccessCode !== 0) {
        storeList.push(storeId);
        storesWithAccessCode[storeId] = groupAccessCode;
      }
    }

    const userAccessData: IUserAccess = {
      features: 7,
      storeList: storeList,
      stores: storesWithAccessCode,
      adminAccessStores: [],
      status: 'PENDING',
    };

    await this.setUserAccessDocumentUnion(newEmail, userAccessData);

  }

  // TODO: Should we add additional permissions here to inform the user if they do not have access, even though firebase rules will make sure
  private setUserAccessDocumentForOtherUser(userId: string, updates: IUserAccess, merge: boolean = false): Promise<void> {
    // Get the access code and determine whether it is an admin to update the adminAccessStores array accordingly,
    // if the user is not admin or no longer admin it will be cleared from the adminAccessStores array.
    updates.adminAccessStores = [];

    for (const storeId of Object.keys(updates.stores)) {
      if (hasAccessToRule(updates.stores[storeId], 0)) {
        updates.adminAccessStores.push(storeId);
      }
    }

    return new Promise<void>((resolve, reject) => {
      this.angularFirestore.doc(`user_access/${userId}`)
        .set(updates, {merge})
        .then(() => {
          resolve();
        })
        .catch(error => {
          error.message = `Error setting document in user_access collection for document: ${userId}\n${error.message}`;
          reject(error);
        });
    });
  }

  private async setUserAccessDocumentUnion(userId: string, updates: Partial<IUserAccess>): Promise<void> {
    const updateData: { [key: string]: unknown } = {};

    if (updates.stores) {
      if (!updates.adminAccessStores) {
        updates.adminAccessStores = [];
      }

      for (const storeId of Object.keys(updates.stores)) {
        if (hasAccessToRule(updates.stores[storeId], 0)) {
          updates.adminAccessStores.push(storeId);
        }
      }
    }

    for (const key of Object.keys(updates)) {
      const value = updates[key] as IUserAccess;

      if (key === 'stores' && value && typeof value === 'object' && !Array.isArray(value)) {
        for (const storeId of Object.keys(value)) {
          updateData[`stores.${storeId}`] = value[storeId];
        }
      } else if (Array.isArray(value)) {
        updateData[key] = firebase.firestore.FieldValue.arrayUnion(...value);
      } else {
        updateData[key] = value;
      }
    }
    try {
      await this.angularFirestore.doc(path_user_access_userId(userId)).update(updateData);
    } catch (error) {
      console.error('Error updating user_access document:', error);
    }
  }


  private getUsersForStores$(storeIds: string[], currentUserId: string): Observable<IColleagues> {
    const storeIdsCopy = [...storeIds];
    const queries: Observable<unknown>[] = [];
    while (storeIdsCopy.length) {
      const chunk = storeIdsCopy.splice(0, 10);
      const query = this.angularFirestore.collection<IUserAccess>('/user_access/', (ref: CollectionReference<IUserAccess>) => ref
        .where('storeList', 'array-contains-any', chunk))
        .valueChanges({idField: 'userId'});
      queries.push(query);
    }

    return combineLatest(queries).pipe(
      switchMap((userAccessResults: IUserAccess[]) => {
        const allUserAccesses: IUserAccess[] = [].concat(...userAccessResults);
        const filteredUserAccesses = allUserAccesses.filter((user: IUserAccess) => !user.isServer);
        const userMap: { [userId: string]: IUserAccess } = {};
        filteredUserAccesses.forEach((userAccess: IUserAccess) => {
          const userId = userAccess.userId;
          const accessData = {...userAccess} as IUserAccess;

          if (accessData.storeList.length > 0 && currentUserId !== userId) {
            userMap[userId] = accessData;
          }
        });

        const userIds = Object.keys(userMap);

        return this.fetchUsersData(userIds).pipe(
          map((usersData: { [p: string]: IUser }) => {
            const colleagues: IColleagues = {};
            userIds.forEach(userId => {
              const userAccess = userMap[userId];
              const userData = usersData[userId];
              if (userData) {
                colleagues[userId] = {
                  ...userAccess,
                  userData: userData,
                };
              } else {
                colleagues[userId] = {
                  ...userAccess,
                  userData: null,
                };
              }
            });
            return colleagues;
          }),
        );
      }),
      catchError((error: IError) => {
        console.error('Error fetching users for stores:', error);
        return of({});
      }),
    );
  }

  private fetchUsersData(userIds: string[]): Observable<{ [userId: string]: IUser }> {
    if (userIds.length === 0) {
      return of({});
    }
    const validUserIds = userIds.filter((id: string) => id);
    const queries: Observable<IUser[]>[] = [];
    while (validUserIds.length) {
      const chunk = validUserIds.splice(0, 10);
      const query = this.angularFirestore.collection<IUser>('/users', (ref) => ref.where('__name__', 'in', chunk))
        .valueChanges({idField: 'id'});
      queries.push(query);
    }

    return combineLatest(queries).pipe(
      map((results: IUser[][]) => {
        const allUsers = [].concat(...results);
        const usersData: { [userId: string]: IUser } = {};
        allUsers.forEach((user: IUser) => {
          usersData[user.id] = user;
        });
        return usersData;
      }),
      catchError((error: IError) => {
        console.error('Error fetching user data:', error);
        return of({});
      }),
    );
  }
}
