import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {CoreState} from './core.reducer';
import {IStore} from '../../../shared/shared-models/store/store';
import {IUser} from '../../../shared/shared-models/user-access/user';

export const selectCoreState = createFeatureSelector<CoreState>('core');

// ====================================================================================================
// Get User
// ====================================================================================================
export const selectUser = createSelector(
  selectCoreState,
  (state: CoreState) => state.user || {} as IUser,
);

export const selectIsUserLoading = createSelector(
  selectCoreState,
  (state: CoreState) => state.isUserLoading,
);

// ====================================================================================================
// Get User Access
// ====================================================================================================
export const selectUserAccess = createSelector(
  selectCoreState,
  (state: CoreState) => state.userAccess,
);

export const selectIsUserAccessLoading = createSelector(
  selectCoreState,
  (state: CoreState) => state.isUserAccessLoading,
);

// ====================================================================================================
// Set ITA (Is Techo Admin)
// ====================================================================================================
export const selectITA = createSelector(
  selectCoreState,
  (state: CoreState) => state.ita,
);

// ====================================================================================================
// Get User Stores
// ====================================================================================================
export const selectUserStores = createSelector(
  selectCoreState,
  (state: CoreState) => state.userStores || [],
);

export const selectSelectedUserStore = createSelector(
  selectCoreState,
  (state: CoreState) => {
    if (!state.userSelectedStore && state.userStores?.length < 1) {
      return {} as IStore;
    }
    if (state.userSelectedStore) {
      return state.userSelectedStore;
    } else if (state.userStores?.length > 0) {
      return state.userStores[0];
    }
    return {} as IStore;
  },
);

export const selectCurrentPageAndTabObject = createSelector(
  selectCoreState,
  (state: CoreState) => ({
    currentSelectedPage: state.currentSelectedPage,
  }),
);

export const selectCurrentPageAndTab = createSelector(
  selectCoreState,
  (state: CoreState) => state.currentSelectedPage,
);

export const selectCurrentPage = createSelector(
  selectCoreState,
  (state: CoreState) => state.currentSelectedPage.currentPage,
);

// ====================================================================================================
// Get Is Module Loading
// ====================================================================================================
export const selectIsModuleLoading = (module: string): MemoizedSelector<CoreState, boolean> => createSelector(
  selectCoreState,
  (state: CoreState) => Boolean(state[`isModule${module}Loading`]) ?? false,
);

export const selectIsModulesLoading = (modules: string[]): MemoizedSelector<CoreState, boolean> => createSelector(
  selectCoreState,
  (state: CoreState) => {
    let isLoaded = false;
    modules.forEach((module: string): void => {
      if (state[`isModule${module}Loading`]) {
        isLoaded = true;
      }
    });
    return isLoaded;
  },
);

// ====================================================================================================
// Get Image URL
// ====================================================================================================
export const selectImageURL = (image: string): MemoizedSelector<CoreState, string> => createSelector(
  selectCoreState,
  (state: CoreState): string | undefined => {
    if (state.images[image]) {
      return state.images[image];
    }
    return undefined;
  },
);

// ====================================================================================================
// User Messages
// ====================================================================================================
export const selectUserMessageCount = createSelector(
  selectCoreState,
  (state: CoreState) => state.userMessagesCount,
);

