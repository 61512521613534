import {Component, Input} from '@angular/core';

/**
 * A component that renders an SVG icon for "Mail"
 *Component
 * @member {string} tooltip ? The tooltip that needs to be displayed on this icon.
 */
@Component({
  selector: 'app-icon-mail',
  templateUrl: './icon-mail.component.html',
  styleUrls: ['./icon-mail.component.scss'],
})
export class IconMailComponent {

  /**
   * Assign a tooltip that needs to be displayed on this icon as soon as the user hovers on it.
   *
   * @param tooltip ? The tooltip that needs to be displayed on this icon.
   */
  @Input() tooltip?: string;
}
