<app-modal-header [title]="'General NGP Settings ' + storeInfo.name" [close]="close"></app-modal-header>

<ion-content>
  <ion-accordion-group #accordionGroup>
    <ion-accordion value="ngpCleanUpSettings">
      <ion-item slot="header">
        <ion-label>Report Clean Up Settings</ion-label>
      </ion-item>
      <div slot="content">
        <ng-container *ngIf="ngpSettings">
          <div id="content">
            <ion-grid [formGroup]="ngpSettingsFG">
              <ion-row class="section-header">
                <ion-col>Report Clean Up</ion-col>
              </ion-row>
              <ion-row id="itemExpDays">
                <ion-col>
                  Items will&nbsp;<b>automatically</b>&nbsp;be cleared
                  <ion-input debounce="1000" (ionChange)="change($event, 'itemExpDays')" formControlName="itemExpDays" type="number"
                             [class]="'ion-no-padding ' + (changed && changed.itemExpDays ? 'changed' : '')"></ion-input>
                  days.
                  <ion-icon name="help-circle-outline" matTooltip="Items will automatically be removed from the report this many days after they where added."></ion-icon>
                </ion-col>
              </ion-row>
              <ng-container *ngTemplateOutlet="controlErrorTemplate; context: {key: 'itemExpDays'}"></ng-container>

              <ion-row id="requestExpDays">
                <ion-col>
                  You will be asked if you want to remove items after
                  <ion-input debounce="1000" formControlName="requestExpDays" type="number"
                             [class]="'ion-no-padding ' + (changed && changed.requestExpDays ? 'changed' : '')"
                             (ionChange)="checkRemoveValue('requestExpDays', 0); change($event, 'requestExpDays')"></ion-input>
                  days.
                  <ion-icon name="help-circle-outline" matTooltip="If any items in the report where added this many days or more ago, you will be asked if you would like to remove them."></ion-icon>
                </ion-col>
              </ion-row>
              <ng-container *ngTemplateOutlet="controlErrorTemplate; context: {key: 'requestExpDays'}"></ng-container>
            </ion-grid>
            <div id="buttons">
              <button mat-raised-button [disabled]="!changed" (click)="save()">Save</button>
            </div>
          </div>
        </ng-container>
      </div>
    </ion-accordion>

  </ion-accordion-group>

  <ng-template #controlErrorTemplate let-key="key">
    <ion-row *ngIf="ngpSettingsFG.get(key).invalid">
      <ion-grid class="errors">
        <ion-row *ngFor="let error of getErrors(key)">
          <ion-col class="ion-no-padding">{{ error }}</ion-col>
        </ion-row>
      </ion-grid>
    </ion-row>
  </ng-template>
</ion-content>

