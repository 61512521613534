import {Component, Input} from '@angular/core';
import {Icons} from '../../icons';
import {iconComponents} from '../index';

/**
 * DisplayIconComponent renders a icon depending on set inputs from a list of pre-selectable icons.
 *
 * @member {Icons} icon The selected icon that will be displayed.
 * @member {string} tooltip The selected icon's tooltip that will be displayed on icon hover.
 * @member {boolean} border Is a border visible around the icon.
 * @member {'green' | 'red' | 'yellow' | 'white'} colour What colour should the icon be.
 * @member {string[]} classList() This gets a list of classes according to inputs.
 *
 */
@Component({
  selector: 'app-display-icon',
  templateUrl: './display-icon.component.html',
  styleUrls: ['./display-icon.component.scss'],
})
export class DisplayIconComponent {

  iconsList: string[] = Object.keys(Icons);
  iconComponents = iconComponents;

  /**
   * Set the icon to be displayed from a list of Icons enum.
   *
   * @member {Icons} icon The selected icon that will be displayed.
   */
  @Input() icon: Icons;
  /**
   * Set the tooltip for icon to be displayed - can be omitted.
   *
   * @member {string} tooltip The selected icon's tooltip that will be displayed on icon hover.
   */
  @Input() tooltip: string;
  /**
   * Set whether icon should be displayed with a border.
   *
   * @member {boolean} border Is a border visible around the icon.
   * @default false
   */
  @Input() border = false;
  /**
   * Set the colour of the selected icon.
   *
   * @member {'green' | 'red' | 'yellow' | 'white' | 'primary' | 'secondary' | 'black'} colour What colour should the icon be.
   * @default white
   */
  @Input() colour: 'green' | 'green-stroke' | 'red' | 'red-stroke' | 'yellow' | 'yellow-stroke' | 'white' | 'white-stroke' |
    'primary' | 'secondary' | 'primary-stroke' | 'secondary-stroke' | 'black' | 'black-stroke' = 'white';

  /**
   * Get a list of classes according to inputs: border and colour.
   * Examples: `icon-green`, `border-green`
   *
   * @member {string[]} classList() This gets a list of classes according to inputs.
   * @returns A list of classes in a string array.
   */
  get classList(): string[] {
    return [
      `icon-${this.colour}`,
      `${this.border ? 'border-' + this.colour : ''}`,
    ];
  }

  /**
   * Get a single icon from the available icons from the input.
   * Examples: `icon-green`, `border-green`
   *
   * @member {string} getIcon() This gets the single icon from the list.
   * @returns A single icon that is going to be displayed.
   */
  get getIcon(): string {
    return this.iconsList.find((itemName: string): boolean => itemName === this.icon) || 'menu';
  }

}
