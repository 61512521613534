<ion-header *ngIf="title" id="pop-title">
  <ion-toolbar>
    <ion-title>{{ title }}</ion-title>
  </ion-toolbar>
</ion-header>
<!-- [style.max-height.px]="maxHeight || null" [style.min-height.px]="minHeight || null"-->
<ion-content id="pop-select-content">
  <div class="container">
    <div class="search-options" *ngIf="search">
      <ion-input type="search" class="search-bar" placeholder="Search" debounce="500" clearInput="true" (ionChange)="searchOptions($event)" clear-input="true">
        <ion-icon name="search-circle-outline"></ion-icon>
      </ion-input>
    </div>
    <div class="variable-list-div">
      <div *ngIf="selectAll" [class]="'select-option select-all' + (allSelected ? ' selected' : '')">
        <button (click)="toggleAll()"><ion-icon name="checkmark-done-outline"></ion-icon>Select All</button>
      </div>

      <!-- --------------------------------------------- MULTI SELECT ---------------------------------------------- -->
      <div *ngIf="multiple; else single"
           [class]="'options' + (!selectionClasses && !selectionStyles ? ' default-even-grey' : '')"
      >
        <div *ngFor="let sKey of order" [class]="classes[sKey] + (disabled.includes(sKey) ? ' disabled' : '')"
             (click)="selectSKey(sKey)" [style]="selectionStyles ? selectionStyles[sKey] : null"
        >
          <ion-checkbox [(ngModel)]="checks[sKey]" (click)="selectSKey(sKey)" [disabled]="disabled && disabled.includes(sKey)">
          </ion-checkbox>
          <span>{{ selection[sKey] }}</span>
        </div>
      </div>

      <!-- --------------------------------------------- SINGLE SELECT --------------------------------------------- -->
      <ng-template #single>
        <div [class]="'options single'  + (!selectionClasses && !selectionStyles ? ' default-even-grey' : '')">
          <div *ngFor="let sKey of order" [class]="classes[sKey] + (disabled.includes(sKey) ? ' disabled' : '')"
               (click)="selectSKey(sKey)" [style]="selectionStyles ? selectionStyles[sKey] : null"
          >
            <ion-icon name="radio-button-{{ checked === sKey ? 'on' : 'off' }}-outline"></ion-icon>
            <span>{{ selection[sKey] }}</span>
          </div>
        </div>
      </ng-template>
    </div>


    <div [class]="'select-mod-buttons' + (selectModButtons.length === 1 ? ' single' : '')" *ngIf="selectModButtons">
      <button *ngFor="let butt of selectModButtons" (click)="selectionModHandle(butt.handler)" [class]="butt.cssClass">
        {{ butt.text }}
      </button>
    </div>

    <div class="main-buttons">
      <span class="custom">
        <button *ngIf="additionalMainButton" (click)="additionalHandle(additionalMainButton.handler)" [class]="additionalMainButton.cssClass">
          {{ additionalMainButton.text }}
        </button>
      </span>
      <span class="complete">
        <button (click)="cancel()" class="cancel-button">
          {{ cancelText }}
        </button>
        <button (click)="ok()" class="ok-button">
          {{ okText }}
        </button>
      </span>
    </div>
  </div>
</ion-content>
