import {Component, Input} from '@angular/core';

/**
 * A component that renders an SVG icon for "delete clock"
 *
 * @member {string} tooltip ? The tooltip that needs to be displayed on this icon.
 */
@Component({
  selector: 'app-icon-delete-clock',
  templateUrl: './icon-delete-clock.component.html',
  styleUrls: ['./icon-delete-clock.component.scss'],
})
export class IconDeleteClockComponent  {
  /**
   * Assign a tooltip that needs to be displayed on this icon as soon as the user hovers on it.
   *
   * @param tooltip ? The tooltip that needs to be displayed on this icon.
   */
  @Input() tooltip?: string;
}
