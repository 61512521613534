export function getHumanReadableIdentifier<ItemType>(item: ItemType, property: string, originalProperty: string): string {
  if (item[property]) {
    return String(item[property]);
  } else {
    return splitToWord(String(item[originalProperty]));
  }
}

export function splitToWord(text: string): string {
  const splitWords: string = text.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  return splitWords.replace(/\b\w/g, char => char.toUpperCase());
}
