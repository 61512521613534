import {createAction, props} from '@ngrx/store';
import {
  FilterOperations,
  IAdvancedFilterGroup,
  IFilter,
  IFilterGroup
} from '../../../shared/shared-models/filter-groups/filter-groups';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {IStore} from '../../../shared/shared-models/store/store';

// ====================================================================================================
// Advanced Filter Group/s
// ====================================================================================================
export const addFilterAndGroupToSelectedAdvancedFilterGroup = createAction(
  '[Shared Filters][Advanced Filter Groups] Add Filter and Group To Selected Advanced Filter Group',
  props<{ predecessorID: number, shouldAddFilter: boolean }>()
);

export const addFilterToSelectedAdvancedFilterGroup = createAction(
  '[Shared Filters][Advanced Filter Groups] Add Filter To Selected Advanced Filter Group',
  props<{ predecessorID: number }>()
);

export const addGroupToSelectedAdvancedFilterGroup = createAction(
  '[Shared Filters][Advanced Filter Groups] Add Group To Selected Advanced Filter Group',
  props<{ predecessorID: number  }>()
);

export const applyAdvancedFilterGroupFilterToGrid = createAction(
  '[Shared Filters][Advanced Filter Groups] Apply Advanced Filter Group Filter To Grid'
);

export const removeAdvancedFilterGroupFilterFromGrid = createAction(
  '[Shared Filters][Advanced Filter Groups] Remove Advanced Filter Group Filter From Grid'
);

export const cancelEditingOfAdvancedFilterGroup = createAction(
  '[Shared Filters][Advanced Filter Groups] Cancel Editing of Advanced Filter Group',
  props<{ originalAdvancedFilterGroup: IAdvancedFilterGroup }>()
);

export const cloneSelectedAdvancedFilterGroup = createAction(
  '[Shared Filters][Advanced Filter Groups] Clone Selected Advanced Filter Group'
);

export const createAdvancedFilterGroup = createAction(
  '[Shared Filters][Advanced Filter Groups] Create Advanced Filter Group',
  props<{ grid: string }>()
);

export const clearAdvancedFilterGroupsFromGrid = createAction(
  '[Shared Filters][Advanced Filter Groups] Clear Advanced Filter Groups From Grid'
);


export const deleteSelectedAdvancedFilterGroup = createAction(
  '[Shared Filters][Advanced Filter Groups] Delete Selected Advanced Filter Group'
);

export const deleteSelectedAdvancedFilterGroupSuccess = createAction(
  '[Shared Filters][Advanced Filter Groups] Delete Selected Advanced Filter Group Success',
  props<{ id: number }>()
);

export const deleteSelectedAdvancedFilterGroupFailure = createAction(
  '[Shared Filters][Advanced Filter Groups] Delete Selected Advanced Filter Group Failure',
  props<{ error: IError }>()
);

export const deleteAdvancedFilterGroupFilter = createAction(
  '[Shared Filters][Advanced Filter Groups] Delete Advanced Filter Group Filter',
  props<{ filter: IFilter }>()
);

export const deleteAdvancedFilterGroupGroup = createAction(
  '[Shared Filters][Advanced Filter Groups] Delete Advanced Filter Group Group',
  props<{ filterGroup: IFilterGroup }>()
);

export const setAdvancedFilterGroupGrid = createAction(
  '[Shared Filters][Advanced Filter Groups] Set Advanced Filter Group Grid',
  props<{ grid: string }>()
);

export const getAdvancedFilterGroupById = createAction(
  '[Shared Filters][Advanced Filter Groups] Get Advanced Filter Group By Id',
  props<{ id: number, store: IStore }>()
);

export const getAdvancedFilterGroupByIdSuccess = createAction(
  '[Shared Filters][Advanced Filter Groups] Get Advanced Filter Group By Id Success',
  props<{ advancedFilterGroup: IAdvancedFilterGroup }>()
);

export const getAdvancedFilterGroupByIdFailure = createAction(
  '[Shared Filters][Advanced Filter Groups] Get Advanced Filter Group By Id Failure',
  props<{ error: IError }>()
);

export const getAdvancedFilterGroups = createAction(
  '[Shared Filters][Advanced Filter Groups] Get Advanced Filter Groups'
);

export const getAdvancedFilterGroupsSuccess = createAction(
  '[Shared Filters][Advanced Filter Groups] Get Advanced Filter Groups Success',
  props<{ advancedFilterGroups: IAdvancedFilterGroup[] }>()
);

export const getAdvancedFilterGroupsFailure = createAction(
  '[Shared Filters][Advanced Filter Groups] Get Advanced Filter Groups Failure',
  props<{ error: IError }>()
);

export const saveSelectedAdvancedFilterGroup = createAction(
  '[Shared Filters][Advanced Filter Groups] Save Selected Advanced Filter Group'
);

export const saveSelectedAdvancedFilterGroupSuccess = createAction(
  '[Shared Filters][Advanced Filter Groups] Save Selected Advanced Filter Group Success',
  props<{ id: number }>()
);

export const saveSelectedAdvancedFilterGroupFailure = createAction(
  '[Shared Filters][Advanced Filter Groups] Save Selected Advanced Filter Group Failure',
  props<{ error: IError }>()
);

export const saveAdvancedFilterGroup = createAction(
  '[Shared Filters][Advanced Filter Groups] Save Advanced Filter Group',
  props<{ advancedFilterGroup: IAdvancedFilterGroup }>()
);

export const setFilterAfterValueChange = createAction(
  '[Shared Filters][Advanced Filter Groups] Set Filter After Value Change',
  props<{ filter: IFilter }>()
);

export const setSelectedAdvancedFilterGroup = createAction(
  '[Shared Filters][Advanced Filter Groups] Set Selected Advanced Filter Group',
  props<{ advancedFilterGroup: IAdvancedFilterGroup }>()
);

export const setSelectedAdvancedFilterGroupOperator = createAction(
  '[Shared Filters][Advanced Filter Groups] Set Advanced Filter Group Operator',
  props<{ operator: FilterOperations, filterGroupId: number }>()
);
