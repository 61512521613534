import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {ModalController, PopoverController} from '@ionic/angular';
import {
  DisabledRule,
  DisabledRules,
  disableRuleInvolvedKeys,
  hasValue,
  keyToTitle,
  keyToType,
  onHoldDecode,
  PriceBand,
  StockItem,
  StockItemLocallyExtended,
  stockValCCRType2Str,
  StockValChangeCheckConfig,
  StockValChangeCheckResult,
  StockValuesChangeCheckResult,
  SubDep,
  SVCCNumber,
  VatRates,
} from '../models-old/datastructures';
import {floor2Dec, integerSplit} from '../utils-old/formatting';
import {FirebaseService} from '../services-old/firebase.service';
import {getStockValueType} from '../models-old/stock-stuff/stock-item-value-type';
import {inRange, IRange} from './ranges';
import {genUUID} from './hash-functions';
import {objLen} from './object-functions';
import {
  SelectPopoverComponent,
} from '../../shared-modules/shared-module/components/select-popover/select-popover.component';

export interface STFormOptions {
  storeId?: string;
  linkDep?: boolean;
  requireALL?: boolean;
  defaultRequired?: boolean;
  dontOpenLinkedFields?: boolean;
  priceBanding?: STFOPriceBanding;
  syncNomGPIfEditing?: { [stockId: string]: string }; // object used to keep track of what a price change last resulted in
  // the gp being set to. Must be passed to gp and price forms
  checkChanged?(value: string | number, id: { stockId?: string; code?: string }): boolean;
  checkDirt?: boolean;
  // interFieldInteractions?: { [key: string]: any };
}

export interface STFOPriceBanding {
  pbs: PriceBand[];
  autoSet?: boolean;
  checkDirt?: boolean;
}

export interface SelectionsOption {
  options: string[] | { k: string; v: any }[];
  multiple?: boolean;
}

const LOG_PRICE_NGP_SYNC_DEBUGS = false;

export class StockFunctions {

  static identified: { [id: string]: UntypedFormGroup } = {};

  /**
   * Enable / Disable Stock Item rRules application per field basis
   */
    // eslint-disable-next-line @typescript-eslint/naming-convention
  static BaseEnableRuleFunctions = class {

    static byAffectedKey = (key: string): (
      (item: StockItem, rule: DisabledRule, enable: boolean, defaults?: { onHoldCode: number; lineColourCode: number })
        => void
      )[] => {
      switch (key) {
        case 'desc':
          return [this.applyDescPrefix, this.applyDescSuffix];
        case 'onHoldCode':
          return [this.applyOnHoldCode];
        case 'lineColourCode':
          return [this.applyLineColour];
        default:
          return [];
      }
    };

    static returnRule = (item: StockItem, rules: DisabledRules) => {
      if (item.onHand === 0) {
        if (rules.onHandZero) {
          return rules.onHandZero;
        }
      } else if (rules.onHandNotZero) {
        if (rules.onHandZero) {
          return rules.onHandNotZero;
        }
      }
      return null;
    };

    static applyDescPrefix = (
      item: StockItem, rule: DisabledRule, enable: boolean = true,
      defaults: { onHoldCode: number; lineColourCode: number } = {onHoldCode: 0, lineColourCode: null},
    ) => {
      if (enable) {
        if (rule.descPrefix !== undefined && rule.descPrefix !== null) {
          if (item.desc && item.desc.trim().startsWith(rule.descPrefix)) {
            item.desc = item.desc.substring(rule.descPrefix.length + 1);
          }
        }
      } else {
        if (rule.descPrefix !== undefined && rule.descPrefix !== null) {
          if (item.desc && !item.desc.trim().startsWith(rule.descPrefix)) {
            item.desc = rule.descPrefix + ' ' + item.desc;
          }
        }
      }
    };
    static applyDescSuffix = (
      item: StockItem, rule: DisabledRule, enable: boolean = true,
      defaults: { onHoldCode: number; lineColourCode: number } = {onHoldCode: 0, lineColourCode: null},
    ) => {
      if (enable) {
        if (rule.descSuffix !== undefined && rule.descSuffix !== null) {
          if (item.desc && item.desc.trim().endsWith(rule.descSuffix)) {
            item.desc = item.desc.substring(0, item.desc.length - (rule.descSuffix.length + 1));
          }
        }
      } else {
        if (rule.descSuffix !== undefined && rule.descSuffix !== null) {
          if (item.desc && !item.desc.trim().endsWith(rule.descSuffix)) {
            item.desc = item.desc + ' ' + rule.descSuffix;
          }
        }
      }
    };
    static applyOnHoldCode = (
      item: StockItem, rule: DisabledRule, enable: boolean = true,
      defaults: { onHoldCode: number; lineColourCode: number } = {onHoldCode: 0, lineColourCode: null},
    ) => {
      if (enable) {
        if (rule.onHoldCode !== undefined && rule.onHoldCode !== null) {
          if (item.onHoldCode === rule.onHoldCode) {
            item.onHoldCode = defaults.onHoldCode;
          }
        }
      } else {
        if (rule.onHoldCode !== undefined && rule.onHoldCode !== null) {
          item.onHoldCode = rule.onHoldCode;
        }
      }
    };
    static applyLineColour = (
      item: StockItem, rule: DisabledRule, enable: boolean = true,
      defaults: { onHoldCode: number; lineColourCode: number } = {onHoldCode: 0, lineColourCode: null},
    ) => {
      if (enable) {
        if (rule.lineColour !== undefined && rule.lineColour !== null) {
          if (item.lineColourCode === rule.lineColour) {
            item.lineColourCode = defaults.lineColourCode;
          }
        }
      } else {
        if (rule.lineColour !== undefined && rule.lineColour !== null) {
          item.lineColourCode = rule.lineColour;
        }
      }
    };
  };

  // TODO: This is stinky, look into that idea note about time and subbing to daily docs or whatever.
  private static temporaryVatInfo: { [storeId: string]: VatRates } = {};

  static get algoliaSearchable(): string[] {
    return ['code', 'desc', 'suppCode', 'dep', 'subDep', 'gp1', 'regularSuppCode', 'barcode'];
  }

  static get selectables(): string[] {
    return ['onHoldCode', 'lineColourCode', 'dep', 'subDep', 'regularSuppCode'];
  }

  static get defaultRequired(): string[] {
    // TODO
    return ['code', 'desc'];
  }

  static get areDates(): string[] {
    return ['lastMoved', 'lastSold', 'lastPurchase', 'prvSellingPriDate', 'suppUsedLastDate5', 'suppUsedLastDate4',
      'suppUsedLastDate3', 'suppUsedLastDate2', 'suppUsedLastDate1', 'lastStockTake', 'created'];
  }

  static get areDateTime(): string[] {
    return ['created'];
  }

  /* ---------------------------------------------- Stock Value Checks ---------------------------------------------- */

  /**
   * A helper function which takes a value (number) and a SVCCNumber config (Stock Value Change Check config), and
   * optionally the original value and returns an array of error results. If any.
   *
   * This function is to be applied on one field of a stock item.
   *
   * @param value
   * @param config
   * @param original
   */
  static checkStockValNum(value: number, config: SVCCNumber, original?: number): StockValChangeCheckResult[] | null {
    if (!(config && Object.keys(config).length)) {
      throw Error('StockFunctions.checkStockVal, config is required');
    }

    const errors: StockValChangeCheckResult[] = [];

    const appendError = (error: StockValChangeCheckResult) => {
      error.desc = stockValCCRType2Str(error.type, error.thresh);
      errors.push(error);
    };

    if (original !== undefined && original !== null) {
      const change = (value - original) / original;

      if (change !== 0) {
        if (change < 0) {
          if ((hasValue(config.negPct)) && (Math.abs(change) > config.negPct)) {
            appendError({type: 'negPct', thresh: config.negPct, new: value, old: original});
          }
        } else if ((hasValue(config.pct)) && (change > config.pct)) {
          appendError({type: 'pct', thresh: config.pct, new: value, old: original});
        }
      }

      if ((value === 0) && (config.noZero)) {
        appendError({type: 'noZero', thresh: config.noZero, new: value, old: original});
      } else if ((value < 0) && (config.noNeg)) {
        appendError({type: 'noNeg', thresh: config.noNeg, new: value, old: original});
      }
    }
    return errors.length ? errors : null;
  }

  static checkStockValues(
    values: StockItem,
    config: StockValChangeCheckConfig,
    original?: StockItem,
  ): null | StockValuesChangeCheckResult {
    const flags: StockValuesChangeCheckResult = {};
    const og = original ? original : {};

    for (const key of Object.keys(values)) {
      if (config[key]) {
        switch (keyToType[key]) {
          case 'number':
            const f = StockFunctions.checkStockValNum(values[key], config[key], og[key]);

            if (f) {
              flags[key] = f;
            }
            break;
          case 'string':
            console.warn('checkStockValues does not yet support strings');
            break;
          case 'date':
            console.warn('checkStockValues does not yet support dates');
            break;
          default:
            console.warn(`checkStockValues does not yet support ${keyToType[key]}`);
        }
      }
    }
    return objLen(flags) ? flags : null;
  }

  /* ---------------------------------------------------------------------------------------------------------------- */

  static applyRandSnap(v: number, digits: number, randSnap: { range: IRange; v: number }): number {
    const n = Math.floor(v);
    const parts = integerSplit(n, digits);

    if (inRange(parts[1], randSnap.range)) {
      const dec = v !== n ? floor2Dec(v - n) : 0;
      v = parts[0] + randSnap.v + dec;
    }
    return v;
  }

  static applyCentSnap(v: number, centSnap: { range: IRange; v: number }): number {
    const floored = Math.floor(v);
    const dec = floor2Dec(v - floored);
    //
    if (inRange((dec) * 100, centSnap.range)) {
      //
      v = floored + (centSnap.v / 100);
    }
    return v;
  }

  static applyPriceBand(v: number, pb: PriceBand, dep?: { dep: string; subDep: string }): number {
    //
    if (inRange(v, pb.range)) {
      //
      if ((!pb.departs || (pb.departs.length === 0 || (dep && pb.departs.includes(dep.dep)))) &&
        (!pb.subDeparts || (pb.subDeparts.length === 0 || (dep && pb.subDeparts.includes(dep.subDep))))) {
        if (pb.randSnap) {
          for (const rs of pb.randSnap.snaps) {
            const v2 = StockFunctions.applyRandSnap(v, pb.randSnap.digits, rs);

            if (v2 !== v) {
              v = v2;
              break;
            }
          }
        }
        //
        if (pb.centSnaps) {
          for (const cs of pb.centSnaps) {
            const v2 = StockFunctions.applyCentSnap(v, cs);

            if (v2 !== v) {
              v = v2;
              break;
            }
          }
        }
      }
    }
    return v;
  }

  static applyPriceBands(v: number, pbs: PriceBand[], dep?: { dep: string; subDep: string }) {
    for (const pb of pbs) {
      const value = StockFunctions.applyPriceBand(v, pb, dep);

      if (value !== v) {
        return value;
      }
    }
    return v;
  }

  static applyPriceBandObj(
    v: number, storeId: string, pbObj: { personal?: PriceBand[]; stores: { [storeId: string]: PriceBand[] } },
    dep?: { dep: string; subDep: string },
  ): number {
    //

    if (pbObj.personal) {
      for (const pb of pbObj.personal) {
        const value = StockFunctions.applyPriceBand(v, pb, dep);

        if (value !== v) {
          return value;
        }
      }
    }
    if (pbObj.stores[storeId]) {
      for (const pb of pbObj.stores[storeId]) {
        const value = StockFunctions.applyPriceBand(v, pb, dep);

        if (value !== v) {
          return value;
        }
      }
    }
    return v;
  }

  static temporaryVatInit(firebase: FirebaseService, storeId: string) {
    if (!this.temporaryVatInfo.hasOwnProperty(storeId)) {
      this.temporaryVatInfo[storeId] = {};
      firebase.subStoreDoc('data/singular_documents/vat_rates', storeId).subscribe((vr) => {
        this.temporaryVatInfo[storeId] = vr as VatRates;
      }, (error) => {
        console.error('Error getting vat_rates. If on sign-out, then todo', error);
      });
    }
  }

  static isDisabled(item: StockItem, disableRules: DisabledRules): boolean {

    const checkRule = (rule: DisabledRule): boolean => {

      if (rule.descPrefix !== undefined && rule.descPrefix !== null) {
        if (item.desc && !item.desc.trim().startsWith(rule.descPrefix)) {
          return false;
        }
      }

      if (rule.descSuffix !== undefined && rule.descSuffix !== null) {
        if (item.desc && !item.desc.trim().endsWith(rule.descSuffix)) {
          return false;
        }
      }

      if (rule.onHoldCode !== undefined && rule.onHoldCode !== null) {
        if (rule.onHoldCode !== item.onHoldCode) {
          return false;
        }
      }

      if (rule.lineColour !== undefined && rule.lineColour !== null) {
        if (rule.lineColour !== item.lineColourCode) {
          return false;
        }
      }
      return true;
    };

    if (disableRules?.onHandZero && checkRule(disableRules?.onHandZero)) {
      return true;
    }

    return disableRules?.onHandNotZero && checkRule(disableRules?.onHandNotZero);
  }

  static enableItem(
    item: StockItem, disableRules: DisabledRules, enable: boolean = true,
    defaults: { onHoldCode: number; lineColourCode: number } = {
      onHoldCode: 0, lineColourCode: null,
    }): boolean {
    const apply = (rule: DisabledRule) => {
      StockFunctions.BaseEnableRuleFunctions.applyDescPrefix(item, rule, enable, defaults);
      StockFunctions.BaseEnableRuleFunctions.applyDescSuffix(item, rule, enable, defaults);
      StockFunctions.BaseEnableRuleFunctions.applyOnHoldCode(item, rule, enable, defaults);
      StockFunctions.BaseEnableRuleFunctions.applyLineColour(item, rule, enable, defaults);
    };

    if (enable) {
      for (const ruleKey of Object.keys(disableRules)) {
        apply(disableRules[ruleKey]);
      }
      return true;
    } else {
      if (item.onHand === 0) {
        if (disableRules.onHandZero) {
          apply(disableRules.onHandZero);
          return true;
        }
      } else if (disableRules.onHandNotZero) {
        apply(disableRules.onHandNotZero);
        return true;
      }
    }
    return false;
  }

  static enableItemFormGroup(
    fg: UntypedFormGroup, onHand: number, disableRules: DisabledRules, enable: boolean = true,
    defaults: { onHoldCode: number; lineColourCode: number } = {onHoldCode: 0, lineColourCode: null},
  ): boolean {
    const deletionKeys = disableRuleInvolvedKeys(disableRules);
    const og = {} as StockItem;
    const edited = {onHand} as StockItem;

    deletionKeys.forEach(key => {
      og[key] = fg.get(key).value;
      edited[key] = og[key];
    });
    const bool = StockFunctions.enableItem(edited, disableRules, enable, defaults);
    deletionKeys.forEach(key => {
      if (og[key] !== edited[key]) {
        fg.get(key).setValue(edited[key]);
      }
    });
    return bool;
  };

  static calcGP(storeId: string, item: StockItem, newSellPrice?: number): number {
    // TODO: Remember should pull vat rate from table.
    StockFunctions.validateVatRates(storeId, item);
    const vatR = this.temporaryVatInfo[storeId][item.vatR].vatRate;
    const price = ![undefined, null].includes(newSellPrice) ? newSellPrice : item.sellPriIncl1;
    const x = price / (1 + (vatR / 100));
    return ((x - item.latestCost) / x) * 100;
  }

  static sellPriceFromGP(storeId: string, item: StockItemLocallyExtended, newGP?: number): number {
    StockFunctions.validateVatRates(storeId, item);
    const vatR = (this.temporaryVatInfo[storeId][item.vatR].vatRate / 100);
    const gp = ![undefined, null].includes(newGP) ? newGP : (
      ![undefined, null].includes(item.nominalGP) ? item.nominalGP : item.gp1
    );
    const x = -((100 * item.latestCost) / (gp - 100));
    const price = x * (1 + vatR);
    return Math.round(price * 100) / 100;
  }

  static calcPriceExcluding(storeId: string, item: StockItem, newSellPRice?: number) {
    StockFunctions.validateVatRates(storeId, item);
    const vatR = this.temporaryVatInfo[storeId][item.vatR].vatRate / 100;
    const price = ![undefined, null].includes(newSellPRice) ? newSellPRice : item.sellPriIncl1;
    return +(price / (1 + vatR)).toFixed(2);
  }

  static itemFieldTextAlign(key: keyof StockItem): 'left' | 'right' | 'center' {
    // TODO This should be determined by the filed type, and the type info (currently in datastructures.ts) should be
    //  moved here and completed
    switch (key) {
      case 'code':
        return 'right';
      case 'vatR':
        return 'center';
      default:
        switch (getStockValueType(key)) {
          case 'string':
            return 'left';
          case 'number':
          case 'date':
          case 'boolean':
            return 'right';
        }
    }
    return null;
  }

  static stockItemForm(stockItem: any, keys: string[], edits?: any, disabled?: string[] | 'all',
                       selections?: { [key: string]: SelectionsOption }, options?: STFormOptions, identify?: boolean) {
    const fg = new UntypedFormGroup({});

    if (identify) {
      let uid: string = stockItem.stockId ? stockItem.stockId : (
        stockItem.objectID ? stockItem.objectID : (stockItem['0'] ? stockItem['0'] : null));

      if (!uid) {
        while (!uid || this.identified.hasOwnProperty(uid)) {
          uid = genUUID(10);
        }
      }
      this.identified[uid] = fg;
      (fg as any).uniqueid = uid;
    }

    for (const fk of keys) {
      const v = edits && edits.hasOwnProperty(fk) ? edits[fk] : stockItem[fk];
      const selection = selections && selections[fk] ? selections[fk] : null;

      if (!selection && StockFunctions.selectables.includes(fk) && fk !== 'onHoldCode') {
        throw Error(`Selection options must be provided for ${fk}.`);
      }
      const control = this.stockItemFieldFormControl(fk, v, stockItem, selection, null, options);
      control.setParent(fg);
      fg.setControl(fk, control);

      if (disabled && (disabled === 'all' || disabled.includes(fk))) {
        control.disable();
      }
    }
    return fg;
  }

  static deIdentifyFormGroup(fg: UntypedFormGroup): boolean | null {
    const uid: string = (fg as any).uniqueid;

    if (uid) {
      if (this.identified[uid] && this.identified[uid] === fg) {
        delete this.identified[uid];
        return true;
      }
      return false;
    }
    return null;
  }

  static stockItemFormSetControls(
    formGroup: UntypedFormGroup, stockItem: any, keys: string[], edits?: any, disabled?: string[] | 'all',
    selections?: { [key: string]: SelectionsOption }, options?: STFormOptions,
  ) {

    for (const fk of keys) {
      const v = edits && edits.hasOwnProperty(fk) ? edits[fk] : stockItem[fk];
      const selection = selections && selections[fk] ? selections[fk] : null;

      if (!selection && StockFunctions.selectables.includes(fk) && fk !== 'onHoldCode') {
        throw Error(`Selection options must be provided for ${fk}.`);
      }
      const control = this.stockItemFieldFormControl(fk, v, stockItem, selection, null, options);
      control.setParent(formGroup);
      formGroup.setControl(fk, control);

      if (disabled && (disabled === 'all' || disabled.includes(fk))) {
        control.disable();
      }
    }
  }

  static stockItemSelectableSelect(
    popControl: PopoverController | ModalController, key: string, options: { [value: string]: string },
    optionsFilter?: (code: string, opts: { [v: string]: string }) => string[],
    initVal?: string[], sorted?: string[], styles?: { [value: string]: string },
  ): (event, value?: string | string[], code?: string) => Promise<string[] | string> {

    if (!StockFunctions.selectables.includes(key)) {
      throw Error('Not a key known to be selectable.');
    }
    let heldVal = initVal ? initVal : [];

    return async (event, value?: string | string[], code?: string) => {
      value = value ? (typeof value === 'string' ? [value] : value) : heldVal;
      let opts = options;

      if (optionsFilter) {
        const filter = optionsFilter(code, options);
        if (filter) {
          opts = {};
          filter.forEach(v => (opts[v] = options[v]));
        }
      }
      const cssClass = popControl instanceof ModalController ? 'select-pop-as-modal' : [];
      const ac = await popControl.create({
        component: SelectPopoverComponent, componentProps: {
          title: `Select ${keyToTitle[key]}`, selection: opts, value, order: sorted, multiple: key === 'onHoldCode',
          selectAll: key === 'onHoldCode', selectionStyles: styles,
          asModal: popControl instanceof ModalController, cssClass,
        }, event,
      });
      await ac.present();
      const {data} = await ac.onDidDismiss();
      heldVal = data;
      return data;
    };
  }

  static stockItemFieldFormControl(key: string, initValue?: any, item?: StockItem, selection?: SelectionsOption,
                                   required?: boolean, options?: STFormOptions): UntypedFormControl {
    // General validators
    const validators = [];

    if (options) {
      if (options.requireALL) {
        required = true;
      }

      if (options.defaultRequired) {
        required = required ? required : StockFunctions.defaultRequired.includes(key);
      }
    } else {
      options = {};
    }

    if (required) {
      validators.push(Validators.required);
    }

    switch (key) {
      // ----------------------------------------------------------------------- strings
      case 'code':
        validators.push(Validators.maxLength(15));
        if (!required) {
          validators.push(Validators.required);
        }
        break;
      case 'desc':
        validators.push(Validators.maxLength(50));
        if (!required) {
          validators.push(Validators.required);
        }
        break;
      case 'suppCode':
      case 'reportItemCode':
      case 'genCode':
        validators.push(Validators.maxLength(15));
        break;
      case 'binL':
      case 'barcode':
        validators.push(Validators.maxLength(30));
        break;
      case 'subDep':
        if (options.linkDep) {
          validators.push(StockFunctions.conditionalInList('subDep', item, selection.options));
          break;
        } else if (typeof selection.options[0] !== 'string') {
          selection.options = selection.options.map(o => o.k);
        }
        validators.push(StockFunctions.inList(selection.options as string[]));
        break;
      case 'dep':
        validators.push(StockFunctions.inList(selection.options as string[]));

        if (options.linkDep && !options.dontOpenLinkedFields) {
          // Todo: Missing code?
        }
        break;
      case 'regularSuppCode':
        validators.push(StockFunctions.inList(selection.options as string[]));
        break;
      case 'suppUsedLast5':
      case 'suppUsedLast4':
      case 'suppUsedLast3':
      case 'suppUsedLast2':
      case 'suppUsedLast1':
      case 'link':
        break;
      //  ---------------------------------------------------------------------- numbers
      case 'onHand':
      case 'salesOrder':
      case 'purchaseOrder':
      case 'dlvColl':
      case 'reportItemFactor':
      case 'maxDisc':
      case 'packSize':
      case 'unitsYear':
      case 'ordLvl':
        validators.push(Validators.pattern(/^\s*(?:[1-9]\d*(\.\d*)?|0?(?:\.\d*)?)\s*$/));
        break;
      case 'prvSellingPri':
      case 'sellPriExcl1':
      case 'sellPriIncl1':
      case 'avCost':
      case 'latestCost':
      case 'gp1':
      case 'nominalGP':
      case 'recommendedGP':
      case 'suppUsedLastPrice5':
      case 'suppUsedLastPrice4':
      case 'suppUsedLastPrice3':
      case 'suppUsedLastPrice2':
      case 'suppUsedLastPrice1':
        validators.push(Validators.pattern(/^\s*(?:[1-9]\d*(\.\d*)?|0?(?:\.\d*)?)\s*$/));

        if (options.priceBanding) {
          if (['sellPriExcl1', 'sellPriIncl1'].includes(key)) {
            validators.push(StockFunctions.priceBand(options.priceBanding.pbs, item, options.priceBanding.autoSet,
              options.priceBanding.checkDirt, options.checkChanged));
          }
        }
        validators.push(StockFunctions.moneyify());

        if (options.syncNomGPIfEditing && options.storeId) {
          if (key === 'nominalGP' && item.hasOwnProperty('vatR') && item.hasOwnProperty('nominalGP')) {
            validators.push(StockFunctions.gpPriceSync(item, options.storeId, options.checkDirt, options.syncNomGPIfEditing));
          } else if (key === 'sellPriIncl1' && item.hasOwnProperty('vatR') && item.hasOwnProperty('latestCost')) {
            validators.push(StockFunctions.priceNomGPSync(item, options.storeId, options.checkDirt, options.syncNomGPIfEditing));
          } else {
            throw Error('priceNomGPSync or gpPriceSync validators are being used with selling price or gp edits.' +
              'These validators require that the subset of an item passed to the control constructor includes "varR",' +
              ' and "latestCost" or "nominalGP" respectively.');
          }
        }
        break;
      // ------------------------------- integers
      case 'user':
      case 'onHoldCode':
      case 'lineColourCode':
      case 'category':
      case 'excludeSellingValue': // Not sure if there should be any additional validators
      case 'vatR':
        switch (key) {
          case 'onHoldCode':
            validators.push(StockFunctions.onHoldValidator());
            break;
          case 'vatR':
            validators.push(Validators.pattern(/^\s*[01]\s*$/));
            break;
          case 'lineColourCode':
            validators.push(StockFunctions.inList(selection.options as string[]));
            break;
          default:
            validators.push(StockFunctions.intValidator);
            break;
        }
        break;
      // ----------------------------------------------------------------------- Dates
      // ------------------------------- date
      case 'lastMoved':
      case 'lastPurchase':
      case 'lastSold':
      case 'lastStockTake':
      case 'prvSellingPriDate':
      case 'suppUsedLastDate5':
      case 'suppUsedLastDate4':
      case 'suppUsedLastDate3':
      case 'suppUsedLastDate2':
      case 'suppUsedLastDate1':
        // initValue = (initValue as Date).toISOString().substr(0,10);
        validators.push(StockFunctions.dateValidator4Strings());
        break;
      // ------------------------------- datetime
      case 'created':
        // initValue = (initValue as Date).toISOString();
        validators.push(StockFunctions.dateValidator4Strings(true));
        break;
      // ----------------------------------------------------------------------- boolean
      case 'sellIntoNegative':
      case 'sellUnderCost':
      case 'discExempt':
      case 'noDecimal':
      case 'web':
        validators.push(StockFunctions.boolValidator());
        break;
    }
    return new UntypedFormControl(initValue, validators);
  }

  static inList(list: string[]): ValidatorFn {

    if (typeof list[0] !== 'string') {
      throw Error('StockFunctions.inList > You passed a list of objects?');
    }
    return (control: AbstractControl): ValidationErrors | null => {
      const v = control.value;

      if (v) {
        if (!list.includes(('' + v).trim())) {
          return {entryDoesNotExist: true};
        }
      }
      return null;
    };
  }

  static conditionalInList(key: 'subDep', item: StockItem, options: string[] | { k: string; v: any }[]) {

    if (typeof options[0] === 'string') {
      console.warn('In stock-functions.stockItemFieldFormControl. Options provided to use conditionalInList ' +
        `ValidatorFn on key "${key}". However parameteri "options" is an array of strings, ` +
        'should be obj: {k: string; v: any}[].\nUsing inList ValidatorFn instead.');
      return StockFunctions.inList(options as string[]);
    }
    switch (key) {
      case 'subDep':
        const subDeps = options as { k: string; v: SubDep }[];
        return (control: AbstractControl): ValidationErrors | null => {
          const v = control.value;
          const dep = control.parent && control.parent.controls.hasOwnProperty('dep') &&
          control.parent.get('dep').valid ? control.parent.get('dep').value : item.dep;

          if (!subDeps.filter(o => (typeof o.v.dep === 'string') ? (o.v.dep === dep) : (o.v.dep.includes(dep)))
            .map(o => o.k).includes(v)) {
            if (subDeps.map(o => o.k).includes(v)) {
              return {conditionalInListConNotMet: `Sub-department '${v}' is not allowed for Department '${dep}'.`};
            }

            return {entryDoesNotExist: 'No such Sub-department'};
          }
        };
      default:
        console.warn('In stock-functions.stockItemFieldFormControl. Key not in switch case. Using inList ' +
          'ValidatorFn instead.');
        return StockFunctions.inList(Object.keys(options as { k: string; v: any }[]).sort());
    }
  }

  static onHoldValidator(multiple: boolean = true, supplier: boolean = false): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const v = control.value;

      if (v) {
        const result = onHoldDecode(+v, true, supplier);

        if (result === null) {
          return {invalidOnHoldCode: true};
        }

        if (result.length > 1 && !multiple) {
          return {oneTypeOnly: `Only one on hold type allowed. $${result.length} entered.`};
        }
      }
      return null;
    };
  }

  static intValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const val = +control.value;

      if (!val) {
        return {notNumber: true};
      }

      if (Math.floor(val) !== val) {
        return {notInteger: true};
      }

      if (+val !== +control.value) {
        control.setValue(val);
      }
      return null;
    };
  }

  static boolValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const val = control.value.trim();
      const match = /^(?<true>[tT](rue)?|[yY](es)?|1)|(?<false>[fF](alse)?|[nN]o?|0)$/.exec(val);

      if (!match) {
        return {notBoolean: true};
      }
      const bv = match.groups.true ? 'true' : 'false';

      if (bv !== val) {
        control.setValue(bv);
      }
      return null;
    };
  }

  static dateValidator4Strings(datetime: boolean = false): ValidatorFn {
    // TODO NB: Use regex groups instead
    return (control: AbstractControl): ValidationErrors | null => {

      if (control.value) {
        const dateStr: string = control.value.trim();

        const p1 = new RegExp(/^(?<D>\d\d?)(?<d1>[/\-])(?<M>\d\d?)\k<d1>(?<Y>\d{4})/.source +
          /(?:\s+(?<h>\d\d?):(?<m>\d\d?)(:(?<s>\d\d?)(:?\.(?<ms>\d{1,3}))?)?)?$/.source);
        const p2 = new RegExp(/^(?<Y>\d{4})(?<d1>[/\-])(?<M>\d\d?)\k<d1>(?<D>\d\d?)/.source +
          /(?:(?:\s+|T)(?<h>\d\d?):(?<m>\d\d?)(?::(?<s>\d\d?)(:?\.(?<ms>\d{1,3})(?<tz>[a-zA-Z]{1,2})?)?)?)?$/.source);

        let m = p1.exec(dateStr);

        if (!m) {
          m = p2.exec(dateStr);
        }

        if (!m) {
          return {invalidDate: 'Invalid date string.'};
        }

        if (+m.groups.M < 1 || +m.groups.M > 12) {
          return {invalidDate: 'Invalid month (1 <= month <= 12).'};
        }

        if (+m.groups.D < 1 || +m.groups.D > (new Date(+m.groups.Y, +m.groups.M, 0)).getDate()) {
          return {invalidDate: 'Invalid day of month.'};
        }

        if (!datetime && (m.groups.h)) {
          return {noTimeRequested: 'Only dates, without time, allowed in field.'};
        }

        if (m.groups.h && +m.groups.h > 23) {
          return {invalidDate: 'Invalid hours value.'};
        }

        if (m.groups.m && +m.groups.m > 59) {
          return {invalidDate: 'Invalid minutes value.'};
        }

        if (m.groups.s && +m.groups.s > 59) {
          return {invalidDate: 'Invalid seconds value.'};
        }
      }
      return null;
    };
  }

  // noinspection SpellCheckingInspection
  static moneyify(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.valid) {
        const v = +control.value;

        if (!isNaN(v)) {
          const fancy = (v).toFixed(2);

          if ('' + control.value !== '' + fancy) {
            control.setValue(fancy);
          }
        }
      }
      return null;
    };
  }

  static priceBand(
    pbs: PriceBand[], item: StockItem, autoSet?: boolean, checkDirt?: boolean,
    checkChanged?: (value: string | number, id: { stockId?: string; code?: string }) => boolean,
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = +control.value;

      if (!isNaN(value) && (!checkDirt || control.dirty)) {

        const v = StockFunctions.applyPriceBands(value, pbs, item).toFixed(2);
        if (value.toFixed(2) !== v) {
          if (!checkChanged || checkChanged(control.value, item)) {
            if (autoSet) {

              control.setValue(v);
            } else {
              return {priceBand: `Not meeting price band rules which should be ${v}`};
            }
          }
          // if (autoSet && (control.dirty || (checkChanged && checkChanged(value, item)))) {
          //   control.setValue(v);
          // } else {
          //   return {priceBand: `Not meeting price band rules which should be ${v}`};
          // }
        }
      } else if (LOG_PRICE_NGP_SYNC_DEBUGS) {
        console.warn(`did not price band (${item.code ? item.code : (item.stockId ? item.stockId : 'unknown')}) due to` +
          `(!isNaN(value)=${!isNaN(value)} checkDirt=${(!checkDirt || control.dirty)}`);
      }
      return null;
    };
  }

  static priceNomGPSync(item: StockItem, storeId: string, checkDirt: boolean = false,
                        pgSync: { [stockId: string]: string }): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = +control.value;

      if (isNaN(value) || !control.parent || !pgSync || (checkDirt && !control.dirty)) {
        return null;
      }
      const uid = (control.parent as any).uniqueid;

      if (!uid) {
        return;
      }

      if (control.parent.controls.hasOwnProperty('nominalGP')) {
        try {
          const newGP = this.calcGP(storeId, item, value).toFixed(2);

          if ('' + control.parent.get('nominalGP').value === newGP) {
            return null;
          }

          pgSync[uid] = newGP;
          control.parent.get('nominalGP').reset(newGP);
        } catch (e) {
          return {syncGPError: e.message};
        }
      }
      return null;
    };
  }

  /**
   * Ensures that GP edits sync to prices. So editing the GP will calculate the price automatically.
   *
   * @param item
   * @param storeId
   * @param checkDirt
   * @param pgSync
   */
  static gpPriceSync(item: StockItemLocallyExtended, storeId: string, checkDirt: boolean = false,
                     pgSync: { [stockId: string]: string }): ValidatorFn {
    // let lastSetPrice: string;
    return (control: AbstractControl): ValidationErrors | null => {
      const value = +control.value;

      // if ([item.code, item.stockId].includes('02020205')) {
      // eslint-disable-next-line max-len
      //
      // }

      if (isNaN(value) || !control.parent || !pgSync) {
        return null;
      }

      if (checkDirt && !control.dirty) {
        // TODO: When a table has been paginated and some values are quickly modified then the field for some reason is
        //  not marked as dirty. Luckily in the only use of this controller (**SO FAR**) the nominalGP is also given
        //  within the item. Lets play it safe and use nominalGP if it exists, else gp1, and compare with the value.
        //  no need to convert from or to strings or round or anything, literally just checking if the initial value is
        //  changed
        const initGP = item.hasOwnProperty('nominalGP') ?
          item.nominalGP :
          item.hasOwnProperty('gp1') ? item.gp1 : null;

        if (initGP === null || initGP === value) {
          if (LOG_PRICE_NGP_SYNC_DEBUGS) {

          }
          return;
        }
      }
      const uid = (control.parent as any).uniqueid;

      // if ([item.code, item.stockId].includes('02020205')) {
      //
      // }

      if (!uid) {
        return;
      }

      // if ([item.code, item.stockId].includes('02020205')) {
      //
      // }

      if (control.parent.controls.hasOwnProperty('sellPriIncl1')) {

        // if ([item.code, item.stockId].includes('02020205')) {
        //
        // }
        //
        if ('' + control.value !== pgSync[uid]) {
          const newPrice = this.sellPriceFromGP(storeId, item, value).toFixed(2);

          // if ([item.code, item.stockId].includes('02020205')) {
          //
          // }

          if (control.parent.get('sellPriIncl1').value !== newPrice) {
            control.parent.get('sellPriIncl1').markAsDirty();
            control.parent.get('sellPriIncl1').setValue(newPrice);
          }
        }
      }
      return null;
    };
  }

  static getVatRateForStore(storeId: string): VatRates {
    return this.temporaryVatInfo[storeId];
  }

  /* ---------------------------------------------------------------------------------------------------------------- */

  private static validateVatRates(storeId: string, item: StockItem): void {
    if (!this.temporaryVatInfo[storeId]) {
      throw Error('This is a temporary error about the temporary holding of vat info in the stock-functions class. ' +
        'You TEMPORARILY need to init with the storeId.');
    }

    if (item === undefined) {
      throw TypeError('Parameter "item" is undefined in validate vat rates');
    }

    if (!this.temporaryVatInfo[storeId].hasOwnProperty(item.vatR)) {
      throw Error(`Whoops, did someone stuff up? There is no vat entry for vatRate '${item.vatR}' on store ${storeId}`);
    }
  }
}
