import {IconArrowDownComponent} from './icon-arrow-down/icon-arrow-down.component';
import {IconArrowLeftComponent} from './icon-arrow-left/icon-arrow-left.component';
import {IconArrowRightComponent} from './icon-arrow-right/icon-arrow-right.component';
import {IconArrowUpComponent} from './icon-arrow-up/icon-arrow-up.component';
import {IconBackspaceOutlineComponent} from './icon-backspace-outline/icon-backspace-outline.component';
import {IconBandageComponent} from './icon-bandage/icon-bandage.component';
import {IconBarcodeComponent} from './icon-barcode/icon-barcode.component';
import {IconBarcodeScanComponent} from './icon-barcode-scan/icon-barcode-scan.component';
import {IconBellOutlineComponent} from './icon-bell-outline/icon-bell-outline.component';
import {IconBugComponent} from './icon-bug/icon-bug.component';
import {IconBugOutlineComponent} from './icon-bug-outline/icon-bug-outline.component';
import {IconCalendarComponent} from './icon-calendar/icon-calendar.component';
import {IconCalendarClearComponent} from './icon-calendar-clear/icon-calendar-clear.component';
import {IconCalendarMultiselectComponent} from './icon-calendar-multiselect/icon-calendar-multiselect.component';
import {
  IconCalendarMultiselectOutlineComponent,
} from './icon-calendar-multiselect-outline/icon-calendar-multiselect-outline.component';
import {IconCancelComponent} from './icon-cancel/icon-cancel.component';
import {IconCartComponent} from './icon-cart/icon-cart.component';
import {IconChevronDownComponent} from './icon-chevron-down/icon-chevron-down.component';
import {IconChevronLeftComponent} from './icon-chevron-left/icon-chevron-left.component';
import {IconChevronRightComponent} from './icon-chevron-right/icon-chevron-right.component';
import {IconChevronUpComponent} from './icon-chevron-up/icon-chevron-up.component';
import {IconClipboardComponent} from './icon-clipboard/icon-clipboard.component';
import {IconCloseBoxComponent} from './icon-close-box/icon-close-box.component';
import {IconCloseBoxOutlineComponent} from './icon-close-box-outline/icon-close-box-outline.component';
import {IconCloseCircleComponent} from './icon-close-circle/icon-close-circle.component';
import {IconCloseCircleOutlineComponent} from './icon-close-circle-outline/icon-close-circle-outline.component';
import {IconCloseComponent} from './icon-close/icon-close.component';
import {IconCloseOctagonComponent} from './icon-close-octagon/icon-close-octagon.component';
import {IconCloseOctagonOutlineComponent} from './icon-close-octagon-outline/icon-close-octagon-outline.component';
import {IconCloseOutlineComponent} from './icon-close-outline/icon-close-outline.component';
import {IconCloseThickComponent} from './icon-close-thick/icon-close-thick.component';
import {IconContentCopyComponent} from './icon-content-copy/icon-content-copy.component';
import {IconContentSaveComponent} from './icon-content-save/icon-content-save.component';
import {IconContentSaveOutlineComponent} from './icon-content-save-outline/icon-content-save-outline.component';
import {IconCreateComponent} from './icon-create/icon-create.component';
import {IconDeleteComponent} from './icon-delete/icon-delete.component';
import {IconDeleteOutlineComponent} from './icon-delete-outline/icon-delete-outline.component';
import {IconDownloadComponent} from './icon-download/icon-download.component';
import {IconDownloadOutlineComponent} from './icon-download-outline/icon-download-outline.component';
import {IconEyeOffOutlineComponent} from './icon-eye-off-outline/icon-eye-off-outline.component';
import {IconEyeOutlineComponent} from './icon-eye-outline/icon-eye-outline.component';
import {IconFileTrayStackedComponent} from './icon-file-tray-stacked/icon-file-tray-stacked.component';
import {IconFilterCogOutlineComponent} from './icon-filter-cog-outline/icon-filter-cog-outline.component';
import {IconFilterVariantComponent} from './icon-filter-variant/icon-filter-variant.component';
import {IconFilterVariantMinusComponent} from './icon-filter-variant-minus/icon-filter-variant-minus.component';
import {IconFilterVariantPlusComponent} from './icon-filter-variant-plus/icon-filter-variant-plus.component';
import {IconFilterVariantRemoveComponent} from './icon-filter-variant-remove/icon-filter-variant-remove.component';
import {IconGoogleDinosaurComponent} from './icon-google-dinosaur/icon-google-dinosaur.component';
import {IconHelpCircleOutlineComponent} from './icon-help-circle-outline/icon-help-circle-outline.component';
import {
  IconInfoSlabCircleOutlineComponent,
} from './icon-info-slab-circle-outline/icon-info-slab-circle-outline.component';
import {IconHomeComponent} from './icon-home/icon-home.component';
import {IconKeyComponent} from './icon-key/icon-key.component';
import {IconLinkVariantComponent} from './icon-link-variant/icon-link-variant.component';
import {IconLogInComponent} from './icon-log-in/icon-log-in.component';
import {IconMailComponent} from './icon-mail/icon-mail.component';
import {IconMenuComponent} from './icon-menu/icon-menu.component';
import {IconNewBoxComponent} from './icon-new-box/icon-new-box.component';
import {IconNotEqualVariantComponent} from './icon-not-equal-variant/icon-not-equal-variant.component';
import {IconPencilComponent} from './icon-pencil/icon-pencil.component';
import {IconPencilOffComponent} from './icon-pencil-off/icon-pencil-off.component';
import {IconPersonComponent} from './icon-person/icon-person.component';
import {IconPieChartComponent} from './icon-pie-chart/icon-pie-chart.component';
import {IconPintComponent} from './icon-pint/icon-pint.component';
import {IconPlanetComponent} from './icon-planet/icon-planet.component';
import {IconPlaylistPlusComponent} from './icon-playlist-plus/icon-playlist-plus.component';
import {IconPlusComponent} from './icon-plus/icon-plus.component';
import {IconProgressPencilComponent} from './icon-progress-pencil/icon-progress-pencil.component';
import {IconPriceTagsComponent} from './icon-price-tags/icon-price-tags.component';
import {IconRadioboxBlankComponent} from './icon-radiobox-blank/icon-radiobox-blank.component';
import {IconRadioboxMarkedComponent} from './icon-radiobox-marked/icon-radiobox-marked.component';
import {IconSettingsComponent} from './icon-settings/icon-settings.component';
import {IconSortComponent} from './icon-sort/icon-sort.component';
import {IconTableCogComponent} from './icon-table-cog/icon-table-cog.component';
import {IconTerminalComponent} from './icon-terminal/icon-terminal.component';
import {IconTodayComponent} from './icon-today/icon-today.component';
import {IconLockClosedOutlineComponent} from './icon-lock-closed-outline/icon-lock-closed-outline.component';
import {IconLockOpenOutlineComponent} from './icon-lock-open-outline/icon-lock-open-outline.component';
import {IconPageFirstComponent} from './icon-page-first/icon-page-first.component';
import {IconPageLastComponent} from './icon-page-last/icon-page-last.component';
import {LogoFullComponent} from './logo-full/logo-full.component';
import {LogoGComponent} from './logo-g/logo-g.component';
import {StylingSvgComponent} from './styling-svg/styling-svg.component';
import {IconReceiptOutlineComponent} from './icon-receipt-outline/icon-receipt-outline.component';
import {
  IconReceiptTextCheckOutlineComponent,
} from './icon-receipt-text-check-outline/icon-receipt-text-check-outline.component';
import {
  IconReceiptTextEditOutlineComponent,
} from './icon-receipt-text-edit-outline/icon-receipt-text-edit-outline.component';
import {IconReceiptTextOutlineComponent} from './icon-receipt-text-outline/icon-receipt-text-outline.component';
import {
  IconReceiptTextRemoveOutlineComponent,
} from './icon-receipt-text-remove-outline/icon-receipt-text-remove-outline.component';
import {IconDeleteClockComponent} from './icon-delete-clock/icon-delete-clock.component';
import {IconDeleteClockOutlineComponent} from './icon-delete-clock-outline/icon-delete-clock-outline.component';
import {
  IconReceiptTextSendOutlineComponent,
} from './icon-receipt-text-send-outline/icon-receipt-text-send-outline.component';
import {StylingSvgLoginComponent} from './styling-svg-login/styling-svg-login.component';
import {IconCustomSortUpDownComponent} from './icon-custom-sort-up-down/icon-custom-sort-up-down.component';
import {IconOpenInNewComponent} from './icon-open-in-new/icon-open-in-new.component';
import {IconPersonPlusOutlineComponent} from './icon-person-plus-outline/icon-person-plus-outline.component';
import {IconEmailAlertOutlineComponent} from './icon-email-alert-outline/icon-email-alert-outline.component';
import {IconEmailCheckOutlineComponent} from './icon-email-check-outline/icon-email-check-outline.component';
import {IconEmailSyncOutlineComponent} from './icon-email-sync-outline/icon-email-sync-outline.component';
import {IconEmailVariantComponent} from './icon-email-variant/icon-email-variant.component';
import {IconEmailFastOutlineComponent} from './icon-email-fast-outline/icon-email-fast-outline.component';
import {IconPdfBoxComponent} from './icon-pdf-box/icon-pdf-box.component';
import {IconLogoIQComponent} from './icon-logo-iq/icon-logo-iq.component';
import {IconChatComponent} from './icon-chat/icon-chat.component';
import {IconChatOutlineComponent} from './icon-chat-outline/icon-chat-outline.component';
import {IconPersonOffOutlineComponent} from './icon-person-off-outline/icon-person-off-outline.component';

export const ICON_ARROW_DOWN = IconArrowDownComponent;
export const ICON_ARROW_LEFT = IconArrowLeftComponent;
export const ICON_ARROW_RIGHT = IconArrowRightComponent;
export const ICON_ARROW_UP = IconArrowUpComponent;
export const ICON_BACKSPACE_OUTLINE = IconBackspaceOutlineComponent;
export const ICON_BANDAGE = IconBandageComponent;
export const ICON_BARCODE = IconBarcodeComponent;
export const ICON_BARCODE_SCAN = IconBarcodeScanComponent;
export const ICON_BELL_OUTLINE = IconBellOutlineComponent;
export const ICON_BUG = IconBugComponent;
export const ICON_BUG_OUTLINE = IconBugOutlineComponent;
export const ICON_CALENDAR = IconCalendarComponent;
export const ICON_CALENDAR_CLEAR = IconCalendarClearComponent;
export const ICON_CALENDAR_MULTISELECT = IconCalendarMultiselectComponent;
export const ICON_CALENDAR_MULTISELECT_OUTLINE = IconCalendarMultiselectOutlineComponent;
export const ICON_CANCEL = IconCancelComponent;
export const ICON_CART = IconCartComponent;
export const ICON_CHAT = IconChatComponent;
export const ICON_CHAT_OUTLINE = IconChatOutlineComponent;
export const ICON_CHEVRON_DOWN = IconChevronDownComponent;
export const ICON_CHEVRON_LEFT = IconChevronLeftComponent;
export const ICON_CHEVRON_RIGHT = IconChevronRightComponent;
export const ICON_CHEVRON_UP = IconChevronUpComponent;
export const ICON_CLIPBOARD = IconClipboardComponent;
export const ICON_CLOSE = IconCloseComponent;
export const ICON_CLOSE_OUTLINE = IconCloseOutlineComponent;
export const ICON_CLOSE_BOX = IconCloseBoxComponent;
export const ICON_CLOSE_BOX_OUTLINE = IconCloseBoxOutlineComponent;
export const ICON_CLOSE_CIRCLE = IconCloseCircleComponent;
export const ICON_CLOSE_CIRCLE_OUTLINE = IconCloseCircleOutlineComponent;
export const ICON_CLOSE_OCTAGON = IconCloseOctagonComponent;
export const ICON_CLOSE_OCTAGON_OUTLINE = IconCloseOctagonOutlineComponent;
export const ICON_CLOSE_THICK = IconCloseThickComponent;
export const ICON_CONTENT_COPY = IconContentCopyComponent;
export const ICON_CONTENT_SAVE = IconContentSaveComponent;
export const ICON_CONTENT_SAVE_OUTLINE = IconContentSaveOutlineComponent;
export const ICON_CREATE = IconCreateComponent;
export const ICON_CUSTOM_SORT_UP_DOWN = IconCustomSortUpDownComponent;
export const ICON_DELETE = IconDeleteComponent;
export const ICON_DELETE_CLOCK = IconDeleteClockComponent;
export const ICON_DELETE_CLOCK_OUTLINE = IconDeleteClockOutlineComponent;
export const ICON_DELETE_OUTLINE = IconDeleteOutlineComponent;
export const ICON_DOWNLOAD = IconDownloadComponent;
export const ICON_DOWNLOAD_OUTLINE = IconDownloadOutlineComponent;
export const ICON_EMAIL_ALERT_OUTLINE = IconEmailAlertOutlineComponent;
export const ICON_EMAIL_CHECK_OUTLINE = IconEmailCheckOutlineComponent;
export const ICON_EMAIL_FAST_OUTLINE = IconEmailFastOutlineComponent;
export const ICON_EMAIL_SYNC_OUTLINE = IconEmailSyncOutlineComponent;
export const ICON_EMAIL_VARIANT = IconEmailVariantComponent;
export const ICON_EYE_OFF_OUTLINE = IconEyeOffOutlineComponent;
export const ICON_EYE_OUTLINE = IconEyeOutlineComponent;
export const ICON_FILE_TRAY_STACKED = IconFileTrayStackedComponent;
export const ICON_FILTER_COG_OUTLINE = IconFilterCogOutlineComponent;
export const ICON_FILTER_VARIANT = IconFilterVariantComponent;
export const ICON_FILTER_VARIANT_MINUS = IconFilterVariantMinusComponent;
export const ICON_FILTER_VARIANT_PLUS = IconFilterVariantPlusComponent;
export const ICON_FILTER_VARIANT_REMOVE = IconFilterVariantRemoveComponent;
export const ICON_GOOGLE_DINOSAUR = IconGoogleDinosaurComponent;
export const ICON_HELP_CIRCLE_OUTLINE = IconHelpCircleOutlineComponent;
export const ICON_INFO_SLAB_CIRCLE_OUTLINE = IconInfoSlabCircleOutlineComponent;
export const ICON_HOME = IconHomeComponent;
export const ICON_KEY = IconKeyComponent;
export const ICON_LINK_VARIANT = IconLinkVariantComponent;
export const ICON_LOG_IN = IconLogInComponent;
export const ICON_LOGO_IQ = IconLogoIQComponent;
export const ICON_LOCK_CLOSED_OUTLINE = IconLockClosedOutlineComponent;
export const ICON_LOCK_OPEN_OUTLINE = IconLockOpenOutlineComponent;
export const ICON_MAIL = IconMailComponent;
export const ICON_MENU = IconMenuComponent;
export const ICON_NEW_BOX = IconNewBoxComponent;
export const ICON_NOT_EQUAL_VARIANT = IconNotEqualVariantComponent;
export const ICON_OPEN_IN_NEW = IconOpenInNewComponent;
export const ICON_PAGE_FIRST = IconPageFirstComponent;
export const ICON_PAGE_LAST = IconPageLastComponent;
export const ICON_PDF_BOX = IconPdfBoxComponent;
export const ICON_PENCIL = IconPencilComponent;
export const ICON_PENCIL_OFF = IconPencilOffComponent;
export const ICON_PERSON = IconPersonComponent;
export const ICON_PERSON_PLUS_OUTLINE = IconPersonPlusOutlineComponent;
export const ICON_PERSON_OFF_OUTLINE = IconPersonOffOutlineComponent;
export const ICON_PIE_CHART = IconPieChartComponent;
export const ICON_PINT = IconPintComponent;
export const ICON_PLANET = IconPlanetComponent;
export const ICON_PLAYLIST_PLUS = IconPlaylistPlusComponent;
export const ICON_PLUS = IconPlusComponent;
export const ICON_PROGRESS_PENCIL = IconProgressPencilComponent;
export const ICON_PRICE_TAGS = IconPriceTagsComponent;
export const ICON_RADIO_BOX_BLANK = IconRadioboxBlankComponent;
export const ICON_RADIO_BOX_MARKED = IconRadioboxMarkedComponent;
export const ICON_RECEIPT_OUTLINE = IconReceiptOutlineComponent;
export const ICON_RECEIPT_TEXT_CHECK_OUTLINE = IconReceiptTextCheckOutlineComponent;
export const ICON_RECEIPT_TEXT_EDIT_OUTLINE = IconReceiptTextEditOutlineComponent;
export const ICON_RECEIPT_TEXT_OUTLINE = IconReceiptTextOutlineComponent;
export const ICON_RECEIPT_TEXT_REMOVE_OUTLINE = IconReceiptTextRemoveOutlineComponent;
export const ICON_RECEIPT_TEXT_SEND_OUTLINE = IconReceiptTextSendOutlineComponent;
export const ICON_SETTINGS = IconSettingsComponent;
export const ICON_SORT = IconSortComponent;
export const ICON_TABLE_COG = IconTableCogComponent;
export const ICON_TERMINAL = IconTerminalComponent;
export const ICON_TODAY = IconTodayComponent;
export const LOGO_FULL = LogoFullComponent;
export const LOGO_G = LogoGComponent;
export const STYLING_SVG = StylingSvgComponent;
export const STYLING_SVG_LOGIN = StylingSvgLoginComponent;

export const SHARED_ICONS = [
  ICON_ARROW_DOWN,
  ICON_ARROW_LEFT,
  ICON_ARROW_RIGHT,
  ICON_ARROW_UP,
  ICON_CUSTOM_SORT_UP_DOWN,
  ICON_BACKSPACE_OUTLINE,
  ICON_BANDAGE,
  ICON_BARCODE,
  ICON_BARCODE_SCAN,
  ICON_BELL_OUTLINE,
  ICON_BUG,
  ICON_BUG_OUTLINE,
  ICON_CALENDAR,
  ICON_CALENDAR_CLEAR,
  ICON_CALENDAR_MULTISELECT,
  ICON_CALENDAR_MULTISELECT_OUTLINE,
  ICON_CANCEL,
  ICON_CART,
  ICON_CHAT,
  ICON_CHAT_OUTLINE,
  ICON_CHEVRON_DOWN,
  ICON_CHEVRON_LEFT,
  ICON_CHEVRON_RIGHT,
  ICON_CHEVRON_UP,
  ICON_CLIPBOARD,
  ICON_CLOSE,
  ICON_CLOSE_OUTLINE,
  ICON_CLOSE_BOX,
  ICON_CLOSE_BOX_OUTLINE,
  ICON_CLOSE_CIRCLE,
  ICON_CLOSE_CIRCLE_OUTLINE,
  ICON_CLOSE_OCTAGON,
  ICON_CLOSE_OCTAGON_OUTLINE,
  ICON_CLOSE_THICK,
  ICON_CONTENT_COPY,
  ICON_CONTENT_SAVE,
  ICON_CONTENT_SAVE_OUTLINE,
  ICON_CREATE,
  ICON_CUSTOM_SORT_UP_DOWN,
  ICON_DELETE,
  ICON_DELETE_CLOCK,
  ICON_DELETE_CLOCK_OUTLINE,
  ICON_DELETE_OUTLINE,
  ICON_DOWNLOAD,
  ICON_DOWNLOAD_OUTLINE,
  ICON_EMAIL_ALERT_OUTLINE,
  ICON_EMAIL_CHECK_OUTLINE,
  ICON_EMAIL_FAST_OUTLINE,
  ICON_EMAIL_SYNC_OUTLINE,
  ICON_EMAIL_VARIANT,
  ICON_EYE_OFF_OUTLINE,
  ICON_EYE_OUTLINE,
  ICON_FILE_TRAY_STACKED,
  ICON_FILTER_COG_OUTLINE,
  ICON_FILTER_VARIANT,
  ICON_FILTER_VARIANT_MINUS,
  ICON_FILTER_VARIANT_PLUS,
  ICON_FILTER_VARIANT_REMOVE,
  ICON_GOOGLE_DINOSAUR,
  ICON_HELP_CIRCLE_OUTLINE,
  ICON_INFO_SLAB_CIRCLE_OUTLINE,
  ICON_HOME,
  ICON_KEY,
  ICON_LINK_VARIANT,
  ICON_LOCK_CLOSED_OUTLINE,
  ICON_LOCK_OPEN_OUTLINE,
  ICON_LOG_IN,
  ICON_LOGO_IQ,
  ICON_MAIL,
  ICON_MENU,
  ICON_NEW_BOX,
  ICON_NOT_EQUAL_VARIANT,
  ICON_OPEN_IN_NEW,
  ICON_PAGE_FIRST,
  ICON_PAGE_LAST,
  ICON_PDF_BOX,
  ICON_PENCIL,
  ICON_PENCIL_OFF,
  ICON_PERSON,
  ICON_PERSON_OFF_OUTLINE,
  ICON_PERSON_PLUS_OUTLINE,
  ICON_PIE_CHART,
  ICON_PINT,
  ICON_PLANET,
  ICON_PLAYLIST_PLUS,
  ICON_PLUS,
  ICON_PROGRESS_PENCIL,
  ICON_PRICE_TAGS,
  ICON_RADIO_BOX_BLANK,
  ICON_RADIO_BOX_MARKED,
  ICON_RECEIPT_OUTLINE,
  ICON_RECEIPT_TEXT_CHECK_OUTLINE,
  ICON_RECEIPT_TEXT_EDIT_OUTLINE,
  ICON_RECEIPT_TEXT_OUTLINE,
  ICON_RECEIPT_TEXT_REMOVE_OUTLINE,
  ICON_SETTINGS,
  ICON_SORT,
  ICON_TABLE_COG,
  ICON_TERMINAL,
  ICON_TODAY,
  LOGO_FULL,
  LOGO_G,
  STYLING_SVG,
  STYLING_SVG_LOGIN,
];

export const iconComponents = {
  arrowDown: ICON_ARROW_DOWN,
  arrowLeft: ICON_ARROW_LEFT,
  arrowRight: ICON_ARROW_RIGHT,
  arrowUp: ICON_ARROW_UP,
  backSpaceOutline: ICON_BACKSPACE_OUTLINE,
  bandage: ICON_BANDAGE,
  barcode: ICON_BARCODE,
  barcodeScan: ICON_BARCODE_SCAN,
  bellOutline: ICON_BELL_OUTLINE,
  bug: ICON_BUG,
  bugOutline: ICON_BUG_OUTLINE,
  calendar: ICON_CALENDAR,
  calendarClear: ICON_CALENDAR_CLEAR,
  calendarMultiSelect: ICON_CALENDAR_MULTISELECT,
  calendarMultiSelectOutline: ICON_CALENDAR_MULTISELECT_OUTLINE,
  cancel: ICON_CANCEL,
  cart: ICON_CART,
  chat: ICON_CHAT,
  chatOutline: ICON_CHAT_OUTLINE,
  chevronDown: ICON_CHEVRON_DOWN,
  chevronLeft: ICON_CHEVRON_LEFT,
  chevronRight: ICON_CHEVRON_RIGHT,
  chevronUp: ICON_CHEVRON_UP,
  clipboard: ICON_CLIPBOARD,
  close: ICON_CLOSE,
  closeOutline: ICON_CLOSE_OUTLINE,
  closeBox: ICON_CLOSE_BOX,
  closeBoxOutline: ICON_CLOSE_BOX_OUTLINE,
  closeCircle: ICON_CLOSE_CIRCLE,
  closeCircleOutline: ICON_CLOSE_CIRCLE_OUTLINE,
  closeOctagon: ICON_CLOSE_OCTAGON,
  closeOctagonOutline: ICON_CLOSE_OCTAGON_OUTLINE,
  closeThick: ICON_CLOSE_THICK,
  contentCopy: ICON_CONTENT_COPY,
  contentSave: ICON_CONTENT_SAVE,
  contentSaveOutline: ICON_CONTENT_SAVE_OUTLINE,
  create: ICON_CREATE,
  customSortUpDown: ICON_CUSTOM_SORT_UP_DOWN,
  delete: ICON_DELETE,
  deleteClock: ICON_DELETE_CLOCK,
  deleteClockOutline: ICON_DELETE_CLOCK_OUTLINE,
  deleteOutline: ICON_DELETE_OUTLINE,
  download: ICON_DOWNLOAD,
  downloadOutline: ICON_DOWNLOAD_OUTLINE,
  emailAlertOutline: ICON_EMAIL_ALERT_OUTLINE,
  emailCheckOutline: ICON_EMAIL_CHECK_OUTLINE,
  emailFastOutline: ICON_EMAIL_FAST_OUTLINE,
  emailSyncOutline: ICON_EMAIL_SYNC_OUTLINE,
  emailVariant: ICON_EMAIL_VARIANT,
  eyeOffOutline: ICON_EYE_OFF_OUTLINE,
  eyeOutline: ICON_EYE_OUTLINE,
  fileTrayStacked: ICON_FILE_TRAY_STACKED,
  filterCogOutline: ICON_FILTER_COG_OUTLINE,
  filterVariant: ICON_FILTER_VARIANT,
  filterVariantMinus: ICON_FILTER_VARIANT_MINUS,
  filterVariantPlus: ICON_FILTER_VARIANT_PLUS,
  filterVariantRemove: ICON_FILTER_VARIANT_REMOVE,
  googleDinosaur: ICON_GOOGLE_DINOSAUR,
  helpCircleOutline: ICON_HELP_CIRCLE_OUTLINE,
  infoSlabCircleOutline: ICON_INFO_SLAB_CIRCLE_OUTLINE,
  home: ICON_HOME,
  key: ICON_KEY,
  linkVariant: ICON_LINK_VARIANT,
  lockClosedOutline: ICON_LOCK_CLOSED_OUTLINE,
  lockOpenOutline: ICON_LOCK_OPEN_OUTLINE,
  logIn: ICON_LOG_IN,
  logoIQ: ICON_LOGO_IQ,
  mail: ICON_MAIL,
  menu: ICON_MENU,
  newBox: ICON_NEW_BOX,
  notEqualVariant: ICON_NOT_EQUAL_VARIANT,
  openInNew: ICON_OPEN_IN_NEW,
  pageFirst: ICON_PAGE_FIRST,
  pageLast: ICON_PAGE_LAST,
  pdfBox: ICON_PDF_BOX,
  pencil: ICON_PENCIL,
  pencilOff: ICON_PENCIL_OFF,
  person: ICON_PERSON,
  personPlusOutline: ICON_PERSON_PLUS_OUTLINE,
  personOffOutline: ICON_PERSON_OFF_OUTLINE,
  pieChart: ICON_PIE_CHART,
  pint: ICON_PINT,
  planet: ICON_PLANET,
  playlistPlus: ICON_PLAYLIST_PLUS,
  plus: ICON_PLUS,
  progressPencil: ICON_PROGRESS_PENCIL,
  priceTags: ICON_PRICE_TAGS,
  radioBoxBlank: ICON_RADIO_BOX_BLANK,
  radioBoxMarked: ICON_RADIO_BOX_MARKED,
  receiptOutline: ICON_RECEIPT_OUTLINE,
  receiptTextCheckOutline: ICON_RECEIPT_TEXT_CHECK_OUTLINE,
  receiptTextEditOutline: ICON_RECEIPT_TEXT_EDIT_OUTLINE,
  receiptTextOutline: ICON_RECEIPT_TEXT_OUTLINE,
  receiptTextRemoveOutline: ICON_RECEIPT_TEXT_REMOVE_OUTLINE,
  receiptTextSendOutline: ICON_RECEIPT_TEXT_SEND_OUTLINE,
  settings: ICON_SETTINGS,
  sort: ICON_SORT,
  tableCog: ICON_TABLE_COG,
  terminal: ICON_TERMINAL,
  today: ICON_TODAY,
};
