import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {IStore} from '../../shared-models/store/store';
import {path_shared_store_data_suppliers_GET} from '../database-paths';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ISupplier} from '../../shared-models/stock/suppliers';

@Injectable({
  providedIn: 'root',
})
export class CollectionSharedSuppliersService {

  constructor(
    private angularFirestore: AngularFirestore,
  ) {
  }

  async setDocument<DataType>(
    path: string,
    data: DataType,
  ): Promise<void> {
    try {
      await this.angularFirestore
        .doc<DataType>(path)
        .set(data, {merge: true});
    } catch (error) {
      console.error(error);
    }
  }

  async getDocument<DataType>(
    path: string,
  ): Promise<DataType> {
    try {
      const docRef = this.angularFirestore.doc<DataType>(path);
      const docSnapshot = await docRef.get().toPromise();
      if (docSnapshot?.data()) {
        return docSnapshot.data();
      } else {
        return {} as DataType;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async deleteDocument<DataType>(
    path: string,
  ): Promise<void> {
    try {
      await this.angularFirestore
        .doc<DataType>(path)
        .delete();
    } catch (error) {
      console.error(`Error deleting document at path: ${path}\n`, error);
    }
  }

  async updateDocumentField<DataType, NewValueType>(
    path: string,
    fieldName: string,
    newValue: NewValueType
  ): Promise<void> {
    try {
      const updateObj: Partial<DataType> = {[fieldName]: newValue} as Partial<DataType>;
      await this.angularFirestore
        .doc<DataType>(path)
        .update(updateObj);
    } catch (error) {
      console.error(`Error updating document at field: ${fieldName} with value ${String(newValue)}\n`, error);
    }
  }

  getSuppliersByStore(store: IStore): Observable<{ [account: string]: ISupplier }> {
    return this.angularFirestore
      .collection(path_shared_store_data_suppliers_GET(store.storeId))
      .snapshotChanges()
      .pipe(
        map((changes) => {
          const suppliers: { [account: string]: ISupplier } = {};
          changes.forEach((change): void => {
            const doc = change.payload.doc;
            suppliers[doc.id] = doc.data() as ISupplier;
            suppliers[doc.id].supplierId = doc.id;
            suppliers[doc.id].isSupplierSelected = false;
          });
          return suppliers;
        })
      );
  }


}
