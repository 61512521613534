import {AgGridAngular} from 'ag-grid-angular';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared-module/shared-module.module';
import {CELL_EDITOR_COMPONENTS} from './components-cell-editors';
import {CELL_RENDERER_COMPONENTS} from './components-cell-renderers';
import {SHARED_GRID_COMPONENTS} from './components';
import {SharedIconsModule} from '../shared-icons/shared-icons.module';
import {StoreModule} from '@ngrx/store';
import {sharedGridReducer} from './store/shared-grid.reducer';
import {EffectsModule} from '@ngrx/effects';
import {SharedGridEffects} from './store/shared-grid.effects';
import {MatButtonModule} from '@angular/material/button';
import {SharedComponentsModule} from '../../shared/shared-components/shared-components.module';

@NgModule({
  declarations: [
    ...CELL_EDITOR_COMPONENTS,
    ...CELL_RENDERER_COMPONENTS,
    ...SHARED_GRID_COMPONENTS
  ],
  imports: [
    AgGridAngular,
    CommonModule,
    FormsModule,
    IonicModule,
    MatButtonModule,
    SharedIconsModule,
    SharedModule,
    SharedComponentsModule,
    ReactiveFormsModule,
    StoreModule.forFeature('shared-grid', sharedGridReducer),
    EffectsModule.forFeature([SharedGridEffects]),
  ],
  exports: [
    ...CELL_EDITOR_COMPONENTS,
    ...CELL_RENDERER_COMPONENTS,
    ...SHARED_GRID_COMPONENTS
  ]
})
export class SharedGridModule {
}
