import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {FormControl, UntypedFormControl, ValidatorFn, Validators} from '@angular/forms';
import {GetInputAlertOptions} from '../../../../shared-utilities/services-old/standard-alerts.service';

@Component({
  selector: 'app-basic-input',
  templateUrl: './basic-input.component.html',
  styleUrls: ['./basic-input.component.scss'],
})
export class BasicInputComponent implements OnInit {

  @Input() options: GetInputAlertOptions;

  formControl: UntypedFormControl;

  constructor(
    private modalController: ModalController,
  ) {}

  ngOnInit() {
    const ionModal = document.querySelector('ion-modal');
    const shadowRoot = ionModal.shadowRoot;
    if (shadowRoot) {
      const modalWrapper = shadowRoot.querySelector('div.modal-wrapper');
      if (modalWrapper instanceof HTMLElement) {
        modalWrapper.style.height = '200px';
        modalWrapper.style.width = '500px';
        modalWrapper.style.borderRadius = '10px';
      }
    }
    const validators: ValidatorFn[] = [Validators.required];

    if (this.options.pattern) {
      validators.push(Validators.pattern(this.options.pattern));
    }
    if (this.options.validators) {
      validators.push.apply(validators, this.options.validators);
    }

    if (this.options.type === 'number') {
      this.formControl = new FormControl<number>(null, validators);
    } else {
      this.formControl = new FormControl<string>(null, validators);
    }
  }

  submit() {
    this.modalController.dismiss(this.formControl.value).then();
  }

  cancel() {
    this.modalController.dismiss(null).then();
  }
}
