import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ModalController, PopoverController} from '@ionic/angular';
import {
  DisabledRules,
  LineColour,
  onHoldDecode,
  onHoldTypes,
} from '../../../../shared-utilities/models-old/datastructures';
import {FirebaseService} from '../../../../shared-utilities/services-old/firebase.service';
import {
  SelectPopoverComponent,
} from '../../../../shared-modules/shared-module/components/select-popover/select-popover.component';
import {
  CollectionStoresSettingsService,
} from '../../../../shared/shared-services/firebase/collection-stores-settings.service';

@Component({
  selector: 'app-del-item-settings',
  templateUrl: './del-item-settings.component.html',
  styleUrls: ['./del-item-settings.component.scss'],
})
export class DelItemSettingsComponent implements OnInit, OnChanges {

  @Input() storeId: string;
  @Input() settings: DisabledRules;
  @Input() lineColours: LineColour;
  @Input() isModal?: boolean = true;

  hide: { [what: string]: boolean } = {};
  saving = false;

  newSettings: DisabledRules = {};

  readonly onHoldKeys: string[];

  constructor(
    private modalController: ModalController,
    private popoverControl: PopoverController,
    private collectionStoresSettingsService: CollectionStoresSettingsService,
    private firebase: FirebaseService,
  ) {
    this.onHoldKeys = Object.keys(onHoldTypes).sort((a, b) => +a < +b ? -1 : 1);
  }

  ngOnInit(): void {
    if (this.settings) {
      Object.keys(this.settings).forEach(i => {
        this.newSettings[i] = {};
        Object.keys(this.settings[i]).forEach(j => {
          this.newSettings[i][j] = this.settings[i][j];
        });
      });

    }

    if (!this.newSettings) {
      this.newSettings = {onHandZero: {}, onHandNotZero: {}};
    } else {
      if (!this.newSettings.onHandZero) {
        this.newSettings.onHandZero = {};
      }
      if (!this.newSettings.onHandNotZero) {
        this.newSettings.onHandNotZero = {};
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['settings']?.currentValue) {
      const currentValue = changes['settings'].currentValue;
      this.updateNavData(currentValue as DisabledRules);
    }
  }

  updateNavData(currentValue: DisabledRules): void {
    this.settings = currentValue;
    for (const i of Object.keys(this.settings)) {
      this.newSettings[i] = {};
      for (const j of Object.keys(this.settings[i])) {
        this.newSettings[i][j] = this.settings[i][j];
      }
    }
  }

  close(): void {
    void this.modalController.dismiss().then();
  }

  async select(event, part: string): Promise<void> {
    const value = this.newSettings[part].onHoldCode ?
      onHoldDecode(+this.newSettings[part].onHoldCode, true) : null;
    const pop = await this.popoverControl.create({
      component: SelectPopoverComponent,
      componentProps: {
        title: 'On Hold Types',
        selection: onHoldTypes,
        order: this.onHoldKeys,
        value,
        multiple: true,
        selectAll: true,
        selectModButtons: [{
          text: 'All but "Invoices & CN"',
          handler: (checks: { [sKey: string]: boolean }) => {

            for (const sKey of this.onHoldKeys) {
              checks[sKey] = true;
            }
            checks['1'] = false;
            checks['2'] = false;
            return checks;
          },
        }],
      }, event,
    });
    pop.onDidDismiss().then(response => {
      const selection: string[] = response.data;

      if (selection) {
        if (selection.length) {
          this.newSettings[part].onHoldCode = selection.reduce((acc, v) => '' + (+acc + +v));
        } else {
          this.newSettings[part].onHoldCode = null;
        }
      }
    });
    await pop.present();
  }

  async selectColour(event, part: string): Promise<void> {
    const selection = {};
    const selectionStyles = {};
    const value = this.newSettings[part].lineColour ? [this.newSettings[part].lineColour] : null;
    const lineColoursOrder = Object.keys(this.lineColours).sort();

    for (const n of lineColoursOrder) {
      selection[n] = `(${n}) ${this.lineColours[n].description}`;
      selectionStyles[n] = `background: ${this.lineColours[n].value}`;
    }

    const pc = await this.popoverControl.create({
      component: SelectPopoverComponent,
      componentProps: {
        title: 'Line Colour', selection, selectionStyles, order: lineColoursOrder, value,
      },
      event,
    });
    await pc.present();
    const response = await pc.onDidDismiss();

    if (response.data) {
      this.newSettings[part].lineColour = response.data;
    }
  }

  valid(): boolean {
    const s = this.newSettings;

    if (s.onHandZero) {
      if (s.onHandZero.descPrefix || s.onHandZero.descSuffix || s.onHandZero.onHoldCode) {
        return true;
      }
    }
    if (s.onHandNotZero) {
      if (s.onHandNotZero.descPrefix || s.onHandNotZero.descSuffix || s.onHandNotZero.onHoldCode) {
        return true;
      }
    }
    return false;
  }

  save(): void {
    if (this.valid()) {
      this.saving = true;
      for (const rule of Object.keys(this.newSettings)) {
        for (const vKey of Object.keys(this.newSettings[rule])) {
          if (!this.newSettings[rule][vKey]) {
            delete this.newSettings[rule][vKey];
          } else if (['onHoldCode', 'lineColour'].includes(vKey)) {
            this.newSettings[rule][vKey] = +this.newSettings[rule][vKey];
          }
        }

        if (Object.keys(this.newSettings[rule]).length === 0) {
          delete this.newSettings[rule];
        }
      }
      const update = this.newSettings;

      // this.collectionStoresSettingsService.updateStoreSettings(this.storeId, 'stock_item_deletion_rules', update as never);
      // this.saving = false;
    }
  }


}
