import {IDimensions} from '../dimensions/dimensions';

/**
 * Interface for defining animation properties.
 * @interface
 * @member {Animations} animation The type of animation to apply.
 * @member {number} delay The delay before the animation starts, in seconds.
 * @member {AnimationDirection | AnimationGlobal} direction The direction of the animation.
 * @member {number} duration The duration of the animation, in seconds.
 * @member {AnimationFillMode | AnimationGlobal} fill The fill mode of the animation.
 * @member {IDimensions} fixedDimensions Fixed dimensions for the animation.
 * @member {string} id The unique identifier for the animation settings.
 * @member {number | AnimationIterationCount | AnimationGlobal} iterationCount The number of times the animation should repeat.
 * @member {AnimationPlayState | AnimationGlobal} playState The play state of the animation.
 * @member {AnimationTimingFunctions | AnimationGlobal | string} timingFunction The timing function for the animation.
 */
export interface IAnimation {
  /**
   * The type of animation to apply.
   * @type {Animations}
   */
  animation: Animations;

  /**
   * The delay before the animation starts, in seconds.
   * @type {number}
   */
  delay: number;

  /**
   * The direction of the animation.
   * Can be a single direction or a combination of directions.
   * @type {AnimationDirection | AnimationGlobal}
   */
  direction: AnimationDirection | AnimationGlobal;

  /**
   * The duration of the animation, in seconds.
   * @type {number}
   */
  duration: number;

  /**
   * The fill mode of the animation.
   * Can be a single fill mode or a combination of fill modes.
   * @type {AnimationFillMode | AnimationGlobal}
   */
  fill: AnimationFillMode | AnimationGlobal;

  /**
   * Fixed dimensions for the animation.
   * @type {IDimensions}
   */
  fixedDimensions: IDimensions;

  /**
   * The unique identifier for the animation settings.
   * @type {string}
   */
  id: string;

  /**
   * The number of times the animation should repeat.
   * Can be a number, an iteration count, or a global value.
   * @type {number | AnimationIterationCount | AnimationGlobal}
   */
  iterationCount: number | AnimationIterationCount | AnimationGlobal;

  /**
   * The play state of the animation.
   * Can be a play state or a global value.
   * @type {AnimationPlayState | AnimationGlobal}
   */
  playState: AnimationPlayState | AnimationGlobal;

  /**
   * The timing function for the animation.
   * Can be a keyword, a cubic-bezier function, steps function, or multiple values, a string.
   * @type {AnimationTimingFunctions | AnimationGlobal}
   */
  timingFunction: AnimationTimingFunctions | AnimationGlobal | string;


  /**
   * The background colour for the animation.
   * Sets the background colour for the animation, by default it is black | white depending on your current browser these.
   * @type {string }
   */
  backgroundColour?: string;
}

/**
 * Enumeration for available animations.
 * @enum {string}
 *
 * @member CIRCLE_DISAPPEAR_AND_REAPPEAR Represents the "circleDisappearAndReappear" animation.
 *
 */
export enum Animations {
  CIRCLE_DISAPPEAR_AND_REAPPEAR = 'circleDisappearAndReappear'
}

/**
 * Enumeration for animation directions.
 * @enum {string}
 *
 * @member ALTERNATE The animation alternates between normal and reverse directions. (Single Direction)
 * @member ALTERNATE_REVERSE The animation alternates between reverse and normal directions. (Single Direction)
 * @member NORMAL The animation plays in the normal direction. (Single Direction)
 * @member REVERSE The animation plays in reverse direction. (Single Direction)
 */
export enum AnimationDirection {
  ALTERNATE = 'alternate',
  ALTERNATE_REVERSE = 'alternate-reverse',
  NORMAL = 'normal',
  REVERSE = 'reverse',
}

/**
 * Enumeration for animation fill modes.
 * @enum {string}
 *
 * @member BACKWARDS The animation applies the fill mode backwards. (Single Fill)
 * @member BOTH The animation applies the fill mode both forwards and backwards. (Single Fill)
 * @member FORWARDS The animation applies the fill mode forwards. (Single Fill)
 * @member NONE The animation does not apply any fill mode. (Single Fill)
 */
export enum AnimationFillMode {
  BACKWARDS = 'backwards',
  BOTH = 'both',
  FORWARDS = 'forwards',
  NONE = 'none',
}

/**
 * Enumeration for global animation values. These are applicable on most animation settings.
 * @enum {string}
 *
 * @member INHERIT The animation property inherits its value from its parent.
 * @member INITIAL The animation property is set to its initial value.
 * @member REVERT The animation property is reverted to its previous value.
 * @member REVERT_LAYER The animation property is reverted to its value in the layer.
 * @member UNSET The animation property is unset.
 */
export enum AnimationGlobal {
  INHERIT = 'inherit',
  INITIAL = 'initial',
  REVERT = 'revert',
  REVERT_LAYER = 'revert-layer',
  UNSET = 'unset',
}

/**
 * Enumeration for animation iteration counts.
 * @enum {string}
 *
 * @member INFINITE The animation repeats indefinitely.
 */
export enum AnimationIterationCount {
  INFINITE = 'infinite'
}

/**
 * Enumeration for animation play states.
 * @enum {string}
 *
 * @member PAUSED The animation is paused. (Single Play State)
 * @member RUNNING The animation is running. (Single Play State)
 */
export enum AnimationPlayState {
  PAUSED = 'paused',
  RUNNING = 'running',
}

/**
 * Enumeration for animation timing functions.
 * @enum {string}
 *
 * @member EASE The animation has a smooth transition. (Keyword Value)
 * @member EASE_IN The animation starts slowly and then speeds up. (Keyword Value)
 * @member EASE_OUT The animation starts quickly and then slows down. (Keyword Value)
 * @member EASE_IN_OUT The animation starts slowly, speeds up, and then slows down. (Keyword Value)
 * @member LINEAR The animation has a constant speed. (Keyword Value)
 * @member STEP_START The animation transitions in steps, starting at the beginning of the interval. (Keyword Value)
 * @member STEP_END The animation transitions in steps, ending at the end of the interval. (Keyword Value)
 * @member CUBIC_BEZIER The animation uses a cubic-bezier timing function. (Function Values)
 * @member STEPS_END The animation uses steps with a specific end step. (Function Values)
 * @member STEPS_JUMP_START The animation uses steps with a specific jump-start step. (Function Values)
 * @member STEPS_JUMP_END The animation uses steps with a specific jump-end step. (Function Values)
 * @member STEPS_JUMP_NONE The animation uses steps with a specific jump-none step. (Function Values)
 * @member STEPS_JUMP_BOTH The animation uses steps with a specific jump-both step. (Function Values)
 * @member STEPS_START The animation uses steps with a specific start step. (Function Values)
 * @member STEPS_FINISH The animation uses steps with a specific end step. (Function Values)
 * @member MULTIPLE_EASE_STEP_BEZIER The animation timing functions include ease, step-start, and cubic-bezier. (Multiple Values)
 */
export enum AnimationTimingFunctions {
  CUBIC_BEZIER = 'cubic-bezier(0.1, 0.7, 1, 0.1)',
  EASE = 'ease',
  EASE_IN = 'ease-in',
  EASE_OUT = 'ease-out',
  EASE_IN_OUT = 'ease-in-out',
  LINEAR = 'linear',
  MULTIPLE_EASE_STEP_BEZIER = 'ease, step-start, cubic-bezier(0.1, 0.7, 1, 0.1)',
  STEP_END = 'step-end',
  STEPS_END = 'steps(4, end)',
  STEP_START = 'step-start',
  STEPS_FINISH = 'steps(8, end)',
  STEPS_JUMP_START = 'steps(4, jump-start)',
  STEPS_JUMP_END = 'steps(10, jump-end)',
  STEPS_JUMP_NONE = 'steps(20, jump-none)',
  STEPS_JUMP_BOTH = 'steps(5, jump-both)',
  STEPS_START = 'steps(6, start)',
}

/**
 * Default settings for an animation.
 * @type {IAnimation}
 *
 * @property {Animations} animation The type of animation to be used. Default is "circleDisappearAndReappear".
 * @property {number} delay The delay before the animation starts, in seconds. Default is 0.
 * @property {AnimationDirection} direction The direction in which the animation plays. Default is "normal".
 * @property {number} duration The duration of the animation, in seconds. Default is 3.
 * @property {AnimationGlobal} fill The fill mode of the animation. Default is "initial".
 * @property {Object} fixedDimensions The fixed dimensions for the animated element.
 * @property {number} fixedDimensions.width The width of the animated element in pixels. Default is 300.
 * @property {number} fixedDimensions.height The height of the animated element in pixels. Default is 300.
 * @property {string} id A unique identifier for the animation settings. Default is "default-settings".
 * @property {AnimationIterationCount} iterationCount The number of times the animation repeats. Default is "infinite".
 * @property {AnimationGlobal} playState The play state of the animation. Default is "initial".
 * @property {AnimationTimingFunctions} timingFunction The timing function of the animation. Default is "linear".
 *
 * @default ```{
 *   animation: circleDisappearAndReappear,
 *   delay: 0,
 *   direction: normal,
 *   duration: 3,
 *   fill: initial,
 *   fixedDimensions: {
 *     width: 300,
 *     height: 300
 *   },
 *   id: 'default-settings',
 *   iterationCount: infinite,
 *   playState: initial,
 *   timingFunction: linear
 * }```
 */
export const AnimationDefault: IAnimation = {
  animation: Animations.CIRCLE_DISAPPEAR_AND_REAPPEAR,
  delay: 0,
  direction: AnimationDirection.NORMAL,
  duration: 3,
  fill: AnimationFillMode.BOTH,
  fixedDimensions: {
    width: 300,
    height: 300,
  },
  id: 'default-settings',
  iterationCount: AnimationIterationCount.INFINITE,
  playState: AnimationPlayState.RUNNING,
  timingFunction: AnimationTimingFunctions.LINEAR,
};

