import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings-stock-manager-container',
  templateUrl: './settings-stock-manager-container.component.html',
  styleUrls: ['./settings-stock-manager-container.component.scss'],
})
export class SettingsStockManagerContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
