import {Component, Input, OnInit} from '@angular/core';
import {ModalController, PopoverController} from '@ionic/angular';
import {UserProfileConfigComponent} from '../user-profile-config/user-profile-config.component';
import {IUser} from '../../../../shared/shared-models/user-access/user';
import {MessagesService} from '../../../../shared-utilities/services-old/message-service/messages.service';
import {DataFreshnessExplanationComponent} from '../data-freshness-explanation/data-freshness-explanation.component';
import {Store} from '@ngrx/store';
import {FireAuthService} from '../../../../shared-utilities/services-old/fire-auth.service';

@Component({
  selector: 'app-menu-popup',
  templateUrl: './menu-popup.component.html',
  styleUrls: ['./menu-popup.component.scss'],
})
export class MenuPopupComponent implements OnInit {

  @Input() user: IUser;
  @Input() pp: string[];

  private messagesCount: number;

  constructor(
    private msgService: MessagesService,
    private modalController: ModalController,
    private popoverController: PopoverController,
    private auth: FireAuthService,
    private readonly store: Store,
  ) {
  }

  ngOnInit(): void {
    this.msgService.numLiveMsgsSub.subscribe((num: number) => (this.messagesCount = num));
  }

  async onUserProfileClick(): Promise<void> {
    await this.popoverController.dismiss();
    const mc: HTMLIonModalElement = await this.modalController.create({
      component: UserProfileConfigComponent,
      cssClass: 'larger-modal',
    });
    await mc.present();
  }

  async onLoginLogoutClick(): Promise<void> {
    await this.popoverController.dismiss();
    void this.auth.logout();
  }

  async onExplainManageItClick(): Promise<void> {
    await this.popoverController.dismiss();
    const mc: HTMLIonModalElement = await this.modalController.create({
      component: DataFreshnessExplanationComponent,
      cssClass: 'larger-modal',
    });
    await mc.present();
  }

}
