<ng-container *ngIf="(isModuleLoading$ | ngrxPush); else loadingComplete">
  <div class="app-loading-indicator">
    <app-shared-loading-indicator
      [animation]="animationLogo">
    </app-shared-loading-indicator>
  </div>
</ng-container>

<ng-template #loadingComplete>
  <div class="app-container">
    <div *ngIf="user?.userName" class="side-nav">
      <app-side-nav
        (sizeChange)="changeMenuSize($event)"
        [setSubPages]="menuPagesSubs"
        [setUserAccess]="(userAccess$ | ngrxPush)"
        [setUser]="(user$ | ngrxPush)"
        [selectedPage]="selectedPage">
      </app-side-nav>
    </div>
    <div
      [ngStyle]="{ width: user?.userName ? 'calc(100vw - 60px)' : '100vw', height: user.userName ? '95vh' : '100vh' }"
      class="content">
      <app-page-header
        *ngIf="user.userName"
        [title]="getCurrentPageText()"
        [user]="(user$ | ngrxPush)"
        [setUserMessageCount]="(userMessageCount$ | ngrxPush)">
      </app-page-header>
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-template>
