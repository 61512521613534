import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Store} from '@ngrx/store';
import {getImageURL} from '../../../../features-as-modules/feature-core/store/core.actions';
import {Observable} from 'rxjs';
import {selectImageURL} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {Icons} from '../../../shared-icons/icons';

@Component({
  selector: 'app-freshness-explanation',
  templateUrl: './data-freshness-explanation.component.html',
  styleUrls: ['./data-freshness-explanation.component.scss'],
})
export class DataFreshnessExplanationComponent implements OnInit {
  isImageLoading = true;
  imageURL$: Observable<string>;
  imageUrl: string;

  @Input() imageURL: string;

  protected readonly icons = Icons;

  constructor(
    private modalController: ModalController,
    private readonly store: Store
  ) {
  }

  ngOnInit(): void {
    const image = 'mgt_cloud_flow_chart.svg';
    this.store.dispatch(getImageURL({path: `/site_media/help/data_freshness/${image}`, image}));

    this.imageURL$ = this.store.select(selectImageURL(image));
    this.imageURL$.subscribe((image: string): void => {
      this.imageUrl = image;
    });
  }

  onImageLoad(): void {
    this.isImageLoading = false;
  }

  onButtonPress(): void {
    void this.modalController
      .dismiss()
      .then();
  }

}
