import {Component, ElementRef, Input, OnInit} from '@angular/core';

export interface FacetObj { key: string; count: number }

@Component({
  selector: 'app-facet-list',
  templateUrl: './facet-list.component.html',
  styleUrls: ['./facet-list.component.scss'],
})
export class FacetListComponent implements OnInit{

  @Input() facetID: string;
  @Input() initFacets: { [key: string]: number };
  @Input() names: { [key: number]: { name: string } };
  @Input() check: (facetID: string, facet: string) => void;
  @Input() checks: { [key: string]: boolean } = {};

  @Input() bubbleColour?: string;

  facets: FacetObj[];

  constructor(
    private element: ElementRef
  ) {
    this.element.nativeElement.style.setProperty('--bubble-colour', '#fafafa');
  }

  ngOnInit() {
    this.facets = Object.keys(this.initFacets).map(f => ({key: f, count: this.initFacets[f]}));
    this.facets.sort((a, b) => b.count - a.count);

    if (this.bubbleColour) {
      this.element.nativeElement.style.setProperty('--bubble-colour', this.bubbleColour);
    }
  }
}
