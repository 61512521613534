import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalController, PopoverController} from '@ionic/angular';
import {SelectPopoverComponent} from '../select-popover/select-popover.component';
import {
  keyToTitle,
  sItemIntToKey,
  sItemKeyToInt,
  StockItem
} from '../../../../shared-utilities/models-old/datastructures';
import {StockHistoryLogComponent} from '../stock-history-log/stock-history-log.component';
import {DOCUMENT_ID, FirebaseService} from '../../../../shared-utilities/services-old/firebase.service';
import {TableDrag} from '../../../../shared-utilities/functions-old/table-functions';

@Component({
  selector: 'app-stock-updates-viewer',
  templateUrl: './stock-updates-viewer.component.html',
  styleUrls: ['./stock-updates-viewer.component.scss'],
})
export class StockUpdatesViewerComponent implements OnInit {

  @Input() apiEvent: any;
  @Input() storeId: string;
  @Input() isEmbedded?: boolean;
  @Input() highlightedCode?: string;
  @Output() codeUpdated: EventEmitter<any> = new EventEmitter();
  @Output() queryTypeUpdate: EventEmitter<any> = new EventEmitter();
  uniqueTitles: string[];
  showNew: boolean;
  showOriginal: boolean;
  rowNum: string;

  stockInfo: { [code: string]: StockItem } = {};
  stockIds: string[] = [];

  readonly k2i = sItemKeyToInt;
  readonly tableColumns: TableDrag;

  protected readonly sItemIntToKey = sItemIntToKey;
  protected readonly keyToTitle = keyToTitle;

  constructor(
    private modalController: ModalController,
    private popControl: PopoverController,
    private firebaseService: FirebaseService,
  ) {
    this.tableColumns = new TableDrag([],);
  }

  get visibleFields(): (keyof StockItem)[] {
    return this.tableColumns.columns as (keyof StockItem)[];
  }

  ngOnInit() {
    this.uniqueTitles = this.getUniqueTitles(Object.values(this.apiEvent.data));

    if (!this.uniqueTitles.includes('desc')) {
      this.uniqueTitles = ['desc'].concat(this.uniqueTitles);
    }
    const stockIds = Object.keys(this.apiEvent.data);
    stockIds.sort();
    this.tableColumns.columns = this.uniqueTitles;
    this.showOriginal = true;
    this.showNew = true;
    this.rowNum = '2';
    this.fetchStock(stockIds);
  }


  getUniqueTitles(data: any[]): string[] {
    const uniqueTitles = [];
    data.forEach((item) => {
      Object.keys(item.n).forEach((key) => {
        if (!uniqueTitles.includes(sItemIntToKey[key])) {
          uniqueTitles.push(sItemIntToKey[key]);
        }
      });
    });
    return uniqueTitles;
  }

  async close() {
    const modal = await this.modalController.getTop();
    modal.classList.add('fade-out');
    // TODO Do these fade outs work? Must be a more ionic standard for this
    setTimeout(() => {
      this.modalController.dismiss().then();
    }, 500);
  }

  changeSpan() {
    // TODO This can be simplified
    if (this.showOriginal && !this.showNew) {
      this.rowNum = '1';
    } else if (this.showNew && this.showOriginal) {
      this.rowNum = '2';
    } else if (this.showNew) {
      this.rowNum = '2';
    } else if (!this.showOriginal && !this.showNew) {
      this.rowNum = '1';
    }
  }

  title(key: keyof StockItem): string {
    return this.tableColumns.title(key);
  }

  getOriginalValue(code: string, key: keyof StockItem) {
    const intKey = this.k2i[key];

    if (this.apiEvent.data[code].o.hasOwnProperty(intKey)) {
      return this.apiEvent.data[code].o[intKey];
    }
    return this.stockInfo?.[code][key];
  }

  async selectFields(event) {
    const options = {};

    for (const key of Object.keys(keyToTitle)) {
      if (key !== 'code') {
        options[key] = keyToTitle[key];
      }
    }
    const filteredOptions = {};

    for (const key in options) {
      if (options.hasOwnProperty(key) && this.uniqueTitles.includes(key)) {
        filteredOptions[key] = options[key];
      }
    }

    const pc = await this.popControl.create({
      component: SelectPopoverComponent, event, componentProps: {
        title: 'Select Visible Fields',
        selection: filteredOptions,
        value: this.tableColumns.columns,
        multiple: true, selectAll: true,
      }
    });
    await pc.present();
    const {data} = await pc.onDidDismiss();

    if (data) {
      this.tableColumns.columns = data;
    }
  }

  getRowClass(index: number): string {
    return index % 2 === 0 ? 'row-even' : 'row-odd';
  }

  isHighlighted(code: string): boolean {
    if (this.highlightedCode) {
      if (code === this.highlightedCode) {

      }
      return code === this.highlightedCode;
    }
  }

  fetchStock(stockCodes: string[]) {
    for (const stockIdsBatch of this.firebaseService.sliceArrayForFBQueries(stockCodes)) {
      // const codes = stockIdsBatch.map((stockId) => stockCodeDecode(stockId));
      this.firebaseService.queryStock2(
        this.storeId, [{q: 'where', p: [DOCUMENT_ID(), 'in', stockIdsBatch]}]
        , true, true
      ).then((bundle: { ids: string[]; items: { [id: string]: StockItem } }) => {
        for (const stockId of bundle.ids) {
          this.stockInfo[stockId] = bundle.items[stockId];
        }
        this.stockIds.push.apply(this.stockIds, stockIdsBatch);
        this.stockIds.sort((a, b) => this.stockInfo[a]?.desc < this.stockInfo[b]?.desc ? -1 : 1);
      }).catch(e => {
        console.error('Fetch error!', e);
        console.error(e);
      });
    }
  }

  async queryHistory(code: any) {
    if (this.isEmbedded) {
      this.codeUpdated.emit(code);
      this.queryTypeUpdate.emit('code');
    } else {
      await this.modalController.dismiss()
      const mc = await this.modalController.create({
        component: StockHistoryLogComponent,
        componentProps: {storeId: this.storeId, codeHistoryList: code, queryType: 'code'},
        backdropDismiss: false,
        cssClass: 'settings-modal'
      });
      await mc.present();
      const {data} = await mc.onDidDismiss();
    }
  }
}
