<div
  [ngClass]="params.data.originalValue?.[params.colDef.field]?.value !== value ? 'edited' : ''"
  class="cell-renderer-text">
  <div *ngIf="onHoldDecode(+value)?.length > 0 || value === 0; else noCode">{{value}}</div>
  <ng-template #noCode>
    <div class="error-text">
      On Hold Code Does Not Exist
    </div>
  </ng-template>
  <app-icon-backspace-outline
    (click)="onUndoChangesClick()"
    *ngIf="params.data.originalValue?.[params.colDef.field]?.value !== value && !params.data.disabled"
    [tooltip]="'Previous Value = ' +params.data.originalValue[params.colDef.field].value">
  </app-icon-backspace-outline>
</div>

