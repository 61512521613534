import {calculateGPValue} from '../../../shared/shared-utils/calculations/calculations-stock';

/**
 * @class StockUtils
 * Utilities for manipulating and stock items.
 *
 * @member calculateGP Calculate the GP for a stock item using the vat rate, selling price, latest cost price and the new selling price.
 */
export class StockUtils {

  /**
   * Calculate the GP for a stock item using the vat rate, selling price, latest cost price and the new selling price.
   *
   * @param vatRate The VAT rate for the stock item.
   * @param sellPriIncl1 The selling price (incl) for the stock item.
   * @param latestCost  The cost price for the stock item.
   * @param newSellPrice The new selling price for the stock item.
   * @returns a calculated GP for the stock item.
   * To be deleted if all references are changed
   */
  static calculateGP(vatRate: number, sellPriIncl1: number, latestCost: number, newSellPrice?: number): number {
    // const price: number = ![undefined, null].includes(newSellPrice) ? newSellPrice : sellPriIncl1;
    // const x: number = price / (1 + (vatRate / 100));
    // return ((x - latestCost) / x) * 100;
    return calculateGPValue(vatRate, sellPriIncl1, latestCost, newSellPrice);
  }

  static calculatePrice (vatRate: number, nominalNGP: number, latestCost: number, newNgp?: number): number {
    const ngp: number = ![undefined, null].includes(newNgp) ? +newNgp : +nominalNGP;
    const price = ngp * (1 + (vatRate / 100));
    return ((price - latestCost) / price) * 100;
  }

}
