<app-shared-modal-filters-advanced
  [buttonCloseCross]="buttonCloseCross"
  [colDefs]="colDefs"
  [colDefsIgnore]="colDefsIgnore"
  [isAdvancedFiltersLoading]="(isAdvancedFilterGroupsLoading$ | ngrxPush)"
  [gridReference]="gridReference"
  [modalTitle]="modalTitle"
  [setAdvancedFilterGroups]="(advancedFilterGroups$ | ngrxPush)"
  [setAppliedFilter]="(appliedAdvancedFilterGroup$ |ngrxPush)"
  [setSelectedAdvancedFilterGroup]="(selectedAdvancedFilterGroup$ | ngrxPush)">
</app-shared-modal-filters-advanced>
