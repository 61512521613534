import {createAction, props} from '@ngrx/store';
import {IStore} from '../../../shared/shared-models/store/store';
import {FbQuery} from '../../../shared-utilities/services-old/firebase.service';
import {DisabledRules, LineColour, StockItem} from '../../../shared-utilities/models-old/datastructures';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {IPaginationData} from '../../../shared/shared-models/pagination/pagination-data';
import {ISearchableFields} from '../../../shared/shared-models/type-sense/default-searchable-fields';
import {IFacetCounts} from '../../../shared/shared-models/type-sense/type-sense-types';
import {IDepartment} from '../../../shared/shared-models/stock/departments';
import {IGridHeaderMenu} from '../../../shared/shared-models/grid-menu/header-menu';
import {ColDef} from 'ag-grid-community';

// =======================================================================
// Stock Item
// =======================================================================
export const getAllStockItems = createAction(
  '[Stock Manager][Stock Item] Get All Stock Items',
  props<{ idField: string; currentPage: number, pageSize: number }>(),
);

export const getAllStockItemsWithoutExistingSuppliers = createAction(
  '[Stock Manager][Stock Item] Get All Stock Items When Suppliers Do Not Exist ',
  props<{ idField: string; currentPage: number, pageSize: number, store: IStore, selectedFields: ISearchableFields }>(),
);

export const getAllStockItemsWithExistingSuppliers = createAction(
  '[Stock Manager][Stock Item] Get All Stock Items When Suppliers Exist ',
  props<{
    idField: string;
    currentPage: number,
    pageSize: number,
    store: IStore,
    selectedFields: ISearchableFields,
    selectedStoreSuppliers: ISearchableFields
  }>(),
);

export const getAllStockItemsSuccess = createAction(
  '[Stock Manager][Stock Item] Get All Stock Items Success',
  props<{ stockItems: StockItem[]; store: IStore, paginationData: IPaginationData, facetCounts: IFacetCounts }>(),
);

export const getAllStockItemsFailure = createAction(
  '[Stock Manager][Stock Item] Get All Stock Items Failure',
  props<{ error: IError, store: IStore }>(),
);

export const getNextPageForStockItems = createAction(
  '[Stock Manager][Pagination] Get Next Page For Stock Items',
);

export const getNextPageForStockItemsSuccess = createAction(
  '[Stock Manager][Pagination] Get Next Page For Stock Items Success',
  props<{ stockItems: StockItem[]; store: IStore, nextPaginationData: IPaginationData }>(),
);

export const getNextPageForStockItemsFailure = createAction(
  '[Stock Manager][Pagination] Get Next Page For Stock Items Failure',
  props<{ error: IError; store: IStore }>(),
);


// =======================================================================
// Search Results
// =======================================================================
export const setSearchableFields = createAction(
  '[Stock Manager][Search Results] Set Searchable Fields',
  props<{ searchableFields: ISearchableFields }>(),
);

export const getSearchResultsForStockItems = createAction(
  '[Stock Manager][Search Results] Get Search Results For Stock Items',
  props<{ idField: string }>(),
);

export const getSearchResultsForStockItemsSuccess = createAction(
  '[Stock Manager][Search Results] Get Search Results For Stock Items Success',
  props<{ stockItems: StockItem[]; store: IStore, paginationData: IPaginationData, facetCounts: IFacetCounts }>(),
);

export const getSearchResultsForStockItemsFailure = createAction(
  '[Stock Manager][Search Results] Get Search Results For Stock Items Failure',
  props<{ error: IError, store: IStore }>(),
);

// =======================================================================
// Pagination
// =======================================================================
export const getPaginationResultsForStockItems = createAction(
  '[Stock Manager][Pagination] Get Pagination Results For Stock Item [Grid]',
  props<{ pageNumber: number }>(),
);

export const getPaginationResultsForStockItemsSuccess = createAction(
  '[Stock Manager][Pagination] Get Pagination Results For Stock Items Success [Grid]',
  props<{ stockItems: StockItem[]; store: IStore, paginationData: IPaginationData, facetCounts: IFacetCounts }>(),
);

export const getPaginationResultsForStockItemsFailure = createAction(
  '[Stock Manager][Pagination] Get Pagination Results For Stock Items Failure [Grid]',
  props<{ error: IError, store: IStore }>(),
);

// =======================================================================
// Page size
// =======================================================================
export const setPageSizeForSharedGrid = createAction(
  '[Stock Manager][Pagination] Set Page Size For Shared Grid',
  props<{ pageSize: number }>(),
);

export const setPageSizeForSharedGridAtStoreId = createAction(
  '[Stock Manager][Pagination] Set Page Size For Shared Grid At Store Id',
  props<{ pageSize: number; store: IStore, pageNumber: number }>(),
);

// =======================================================================
// set Next Page
// =======================================================================
export const setNextPageForSharedGrid = createAction(
  '[Stock Manager][Pagination] Set Next Page For Shared Grid',
);

export const setNextPageForSharedGridAtStoreId = createAction(
  '[Stock Manager][Pagination] Set Next Page For Shared Grid At Store Id',
  props<{ paginationData: IPaginationData; store: IStore }>(),
);

export const getNextPageForSharedGridSuccess = createAction(
  '[Stock Manager][Pagination] Get Next Page For Shared Grid Success',
  props<{ store: IStore, stockItems: StockItem[] }>(),
);
export const getNextPageForSharedGridFailure = createAction(
  '[Stock Manager][Pagination] Get Next Page For Shared Grid Failure',
  props<{ error: IError; store: IStore }>(),
);

// =======================================================================
// Suppliers
// =======================================================================
export const getSuppliersForStockManager = createAction(
  '[Stock Manager][Suppliers] Get Suppliers For Stock Manager',
  props<{ store: IStore, firebaseQueries: FbQuery[], idField: string }>(),
);

export const getSuppliersForStockManagerSuccess = createAction(
  '[Stock Manager][Suppliers] Get Suppliers For Stock Manager Success',
  props<{ suppliers: ISearchableFields, store: IStore }>(),
);

export const getSuppliersForStockManagerFailure = createAction(
  '[Stock Manager][Suppliers] Get Suppliers For Stock Manager Failure',
  props<{ error: IError, store: IStore }>(),
);

export const setSuppliersForStockManager = createAction(
  '[Stock Manager][Suppliers] Set Suppliers For Stock Manager',
  props<{ suppliers: { [key: string]: ISearchableFields } }>(),
);

export const setSuppliersForStockManagerSuccess = createAction(
  '[Stock Manager][Suppliers] Set Suppliers For Stock Manager Success',
  props<{
    stockItems: StockItem[],
    store: IStore,
    paginationData: IPaginationData,
    facetCounts: IFacetCounts,
    suppliers: { [key: string]: ISearchableFields }
  }>(),
);

export const setSuppliersForStockManagerFailure = createAction(
  '[Stock Manager][Suppliers] Set Suppliers For Stock Manager Failure',
  props<{ error: IError, store: IStore }>(),
);

// =======================================================================
// Departments and SubDepartments
// =======================================================================
export const getStoreDepartmentsForStockManager = createAction(
  '[Stock Manager][Departments] Get Store Departments For Stock Manager',
);

export const getStoreDepartmentsForStockManagerSuccess = createAction(
  '[Stock Manager][Departments] Get Store Departments For Stock Manager Success',
  props<{ store: IStore; departments: IDepartment[] }>(),
);

export const getStoreDepartmentsForStockManagerFailure = createAction(
  '[Stock Manager][Departments] Get Store Departments For Stock Manager Failure',
  props<{ error: IError, store: IStore }>(),
);

// =======================================================================
// Filters
// =======================================================================
export const setStockItemFilters = createAction(
  '[Stock Manager][Filters] Set Stock Item Filters',
  props<{ filters: { [key: string]: ISearchableFields } }>(),
);

export const setStockItemFiltersSuccess = createAction(
  '[Stock Manager][Filters] Set Stock Item Filters Success',
  props<{
    stockItems: StockItem[],
    store: IStore,
    paginationData: IPaginationData,
    facetCounts: IFacetCounts,
    filters: { [key: string]: ISearchableFields }
  }>(),
);

export const setStockItemFiltersFailure = createAction(
  '[Stock Manager][Filters] Set Stock Item Filters Failure',
  props<{ error: IError, store: IStore }>(),
);


// ====================================================================================================
// Get Disabling Rules
// ====================================================================================================
export const getStockItemDisablingRules = createAction(
  '[Stock Manager][Item Disabling] Get Stock Items Disabling Rules',
);

export const getStockItemDisablingRulesSuccess = createAction(
  '[Stock Manager][Item Disabling] Get Stock Items Disabling Rules Success',
  props<{ store: IStore; rulesDoc: DisabledRules }>(),
);

export const getStockItemDisablingRulesFailure = createAction(
  '[Stock Manager][Item Disabling] Get Stock Items Disabling Rules Failure',
  props<{ errors: IError }>(),
);

// ====================================================================================================
// Disable Items
// ====================================================================================================
export const setItemDisabledStatus = createAction(
  '[Stock Manager][Item Disabling] Set Item to be Disabled',
  props<{ stockItem: StockItem, enable: boolean }>(),
);

export const setItemDisabledWithStoreID = createAction(
  '[Stock Manager][Item Disabling] Set Item to be Disabled With StoreID',
  props<{ stockItem: StockItem; store: IStore }>(),
);

// ====================================================================================================
// Edited Items
// ====================================================================================================

export const updateStockItemsWithEditedSuccess = createAction(
  '[Stock Manager][Edited Items] Update Stock Items with Edited Success',
  props<{ stockItems: StockItem[], store: IStore, editedItems: StockItem[] }>(),
);

export const setEditedItem = createAction(
  '[Stock Manager][Edited Items] Set Edited Item',
  props<{ stockItem: StockItem }>(),
);
export const setEditedItemAdd = createAction(
  '[Stock Manager][Edited Items] Add To Edited Items',
  props<{ stockItem: StockItem, store: IStore }>(),
);
export const setEditedItemRemove = createAction(
  '[Stock Manager][Edited Items] Remove From Edited Items',
  props<{ stockItem: StockItem, store: IStore }>(),
);

export const setEditedItemsToStockPage = createAction(
  '[Stock Manager][Edited Items] Add The Edited Item To Stock Page',
);

export const getEditedItemsFromTypesense = createAction(
  '[Stock Manager][Edited Items] Get Edited Items from Typesense',
  props<{ store: IStore }>(),
);

export const getEditedItemsFromTypesenseSuccess = createAction(
  '[Stock Manager][Edited Items] Get Edited Items from Typesense Success',
  props<{ editedItemsFromTypesense: StockItem[]; facetCounts: IFacetCounts; store: IStore, priceBandedItems: StockItem[] }>(),
);

export const getEditedItemsFromTypesenseFailure = createAction(
  '[Stock Manager][Edited Items] Get Edited Items from Typesense Failure',
  props<{ error: IError; store: IStore }>(),
);

// ====================================================================================================
// Set Grid Menu Action
// ====================================================================================================
export const setStockManagerMenuActions = createAction(
  '[Stock Manager][Menu Actions] Set Stock Manager Menu Actions per Column',
  props<{ gridHeaderMenu: IGridHeaderMenu }>(),
);


export const setStockManagerMenuActionsWithStore = createAction(
  '[Stock Manager][Menu Actions] Set Stock Manager Menu Actions per Column with Store Details',
  props<{ store: IStore; gridHeaderMenu: IGridHeaderMenu }>(),
);

export const setStockManagerSortTye = createAction(
  '[Stock Manager][Menu Actions] Set Stock Manager Sort Type',
  props<{ sortType: string }>(),
);

export const togglePriceBanding = createAction(
  '[Stock Manager][Menu Actions] Toggle PriceBanding ',
);


//===============================================================================================================
// Set Visible Fields
//===============================================================================================================
export const setStockManagerVisibility = createAction(
  '[Stock Manager][Visible Fields] Set Stock Manager Visible Fields',
  props<{ colDef: ColDef[]; menuData: string[] }>(),
);

export const updateStockManagerSingleVisibleField = createAction(
  '[Stock Manager][Visible Fields] Update Single Visible Field For Stock Manager',
  props<{ colId: string; value: boolean }>(),
);

// ====================================================================================================
// Get Line Colours
// ====================================================================================================
export const getLineColours = createAction(
  '[Stock Manager][Line Colour] Get Line Colours',
  props<{ pageStoreDocument: string }>(),
);

export const getLineColoursSuccess = createAction(
  '[Stock Manager][Line Colour] Get Line Colours Success',
  props<{ lineColours: LineColour; selectedStore: IStore }>(),
);

export const getLineColoursFailure = createAction(
  '[Stock Manager][Line Colour] Get Line Colours Failure',
  props<{ error: IError, store: IStore }>(),
);

// ====================================================================================================
// Set NGP And Price Incl
// ====================================================================================================
export const setPriceGpForStockManager = createAction(
  '[Stock Manager][Price] Set The Price, NGP for Stock Manager',
  props<{ stockItem: StockItem, field: string }>(),
);

export const setPriceGpForStockMangerWithStoreID = createAction(
  '[Stock Manager][Price] Set The Price, NGP for Stock Manager for Store ID',
  props<{ stockItem: StockItem; field: string; storeId: string }>(),
);

// ====================================================================================================
// set Stock Updates For Stock Manger
// ====================================================================================================

export const setStockUpdatesForStockManger = createAction(
  '[Stock Manager][Update Firebase] Set Stock Updates On Firebase For Stock Manager',
  props<{ itemsToUpdate: { [code: string]: { o: StockItem; n: StockItem } } }>(),
);

export const stockUpdateFailure = createAction(
  '[Stock Manager][Update Firebase] Set Stock Updates On Firebase For Stock Manager Failure',
  props<{ error: IError}>(),
);

export const removeEditedItemsAfterStockUpdate = createAction(
  '[Stock Manager][Update Firebase] Remove Edited Items After StockUpdate',
  props<{ store: IStore }>(),
);

export const undoAllEditsToStockManager = createAction(
  '[Stock Manager][Undo Edits] Remove Edited Items From Stock Manager',
);

// ====================================================================================================
// get Stock Manager Preview Columns
// ====================================================================================================


export const getStockManagerPreviewColumns = createAction(
  '[Stock Manager][Preview Columns] Get Stock Manager Preview Columns',
);

export const setStockManagerPreviewColumns = createAction(
  '[Stock Manager][Preview Column] Set Stock Manager Preview Columns',
  props<{ columnData: {} }>(),
);

export const setStockManagerPreviewColumnsSuccess = createAction(
  '[Stock Manager][Preview Column] Set Stock Manager Preview Columns Success',
  props<{ columnData: {} }>(),
);

export const setStockManagerPreviewColumnsFailure = createAction(
  '[Stock Manager][Preview Column] Set Stock Manager Preview Columns Failure',
  props<{ error: IError }>(),
);






