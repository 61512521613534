<app-modal-header [title]="'Messages'" [close]="close"></app-modal-header>

<ion-content>
  <app-shared-store-tabs
    [setStores]="(userStores$ | ngrxPush)"
    [isModal]="true"
    [isMessagesModal]="true"
    (emitStoreChange)="selectStore($event.storeId)">
  </app-shared-store-tabs>
  <ion-grid id="messages" *ngIf="order; else spinner">
    <ng-container *ngIf="selectedOrder && selectedOrder.length; else empty">
      <ion-accordion-group>
        <ion-accordion>
          <ion-item slot="header">
            <ion-label [color]="this.filteredMessageTypes || this.selectedDates ? 'success' : ''">Filters</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <button mat-raised-button (click)="filterMsgTypes()">
              Filter Message Types
              <span *ngIf="this.filteredMessageTypes">*</span>
            </button>
            <button mat-raised-button (click)="filterDates($event)">
              Filter Dates
              <span *ngIf="this.selectedDates">*</span>
            </button>
            <button mat-raised-button *ngIf="groupedByAge.olderMessages.length > 0"
                    style="float: right; background: var(--ion-color-danger); color:var(--ion-color-white)"
                    (click)="deleteOldMessages()">
              Delete Old Messages
            </button>
          </div>
        </ion-accordion>
      </ion-accordion-group>

      <ion-accordion-group *ngIf="!filtered; else filteredTemplate" [value]="initialOpenGroup">
        <ion-accordion *ngIf="groupedByAge.currentWeek.length" value="currentWeek">
          <ion-item slot="header" color="light">
            <ion-label>This Week</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <ng-container *ngFor="let msgID of groupedByAge.currentWeek">
              <ng-container *ngTemplateOutlet="messageTemplate; context: {msgID: msgID}" />
            </ng-container>
          </div>
        </ion-accordion>
        <ion-accordion *ngIf="groupedByAge.currentMonth.length" value="currentMonth">
          <ion-item slot="header" color="light">
            <ion-label>This Month</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <ng-container *ngFor="let msgID of groupedByAge.currentMonth">
              <ng-container *ngTemplateOutlet="messageTemplate; context: {msgID: msgID}" />
            </ng-container>
          </div>
        </ion-accordion>
        <ion-accordion *ngIf="groupedByAge.olderMessages.length" value="olderMessages">
          <ion-item slot="header" color="light">
            <ion-label>Old Messages</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <ng-container *ngFor="let msgID of groupedByAge.olderMessages">
              <ng-container *ngTemplateOutlet="messageTemplate; context: {msgID: msgID}" />
            </ng-container>
          </div>
        </ion-accordion>
      </ion-accordion-group>
    </ng-container>

    <ng-template #filteredTemplate>
      <div class="ion-padding">
        <ng-container *ngFor="let msgID of filtered">
          <ng-container *ngTemplateOutlet="messageTemplate; context: {msgID: msgID}" />
        </ng-container>
      </div>
    </ng-template>

    <ng-template #empty>
      <div id="no-messages">
        <i>Inbox is empty :)</i>
      </div>
    </ng-template>
  </ion-grid>
</ion-content>

<ng-template #messageTemplate let-msgID=msgID>
  <ion-row [class]="'msg ' + (expandedMessage === msgID ? 'expanded' : '')">
    <ion-col size="auto"><span>{{ dateStrings[msgID].d }}</span></ion-col>
    <ion-col size="auto"><span>{{ dateStrings[msgID].t }}</span></ion-col>
    <ion-col class="preview truncate" (click)="expand(msgID)">
      <ng-container *ngIf="messages[msgID] && ['API-RESPONSE', 'AUTO_ORDER_RESULT'].includes(messages[msgID].type)">
        <ng-container *ngIf="!messages[msgID].payload.success; else successTemplate">
          <ion-icon [class]="!sentButIMAPFailed[messages[msgID].id]?'api-error':'api-warn'" name="alert-circle-outline"></ion-icon>
        </ng-container>
        <ng-template #successTemplate>
          <ion-icon class="api-success" name="checkmark-circle-outline"></ion-icon>
        </ng-template>
      </ng-container>
      {{ previews[msgID] }}
    </ion-col>
    <ion-col size="auto">
      <ion-icon (click)="deleteMessage(msgID)" name="trash-outline"></ion-icon>
    </ion-col>
    <!--        <ion-col size="1"><ion-icon (click)="deleteMessage(msgID)" name="trash-outline"></ion-icon></ion-col>-->
  </ion-row>
  <ion-grid class="msg-expansion" *ngIf="getExpansion(msgID)" [hidden]="expandedMessage !== msgID || hide[msgID]">
    <ng-container *ngComponentOutlet="getExpansion(msgID); injector: injectors[msgID]"></ng-container>
    <ion-row>
      <ion-icon (click)="expandedMessage = null" name="caret-up-outline"></ion-icon>
    </ion-row>
  </ion-grid>
</ng-template>

<ng-template #spinner>
  <div id="loading-indicator">
    <ion-card>
      <ion-card-header>
        <ion-progress-bar type="indeterminate"></ion-progress-bar>
      </ion-card-header>
      <ion-card-content>
        loading...
      </ion-card-content>
    </ion-card>
  </div>
</ng-template>
