<ion-header *ngIf="isModal">
  <ion-toolbar>
    <div>
      <ion-title>Stock Item Disabling</ion-title>
      <ion-title>
        <ion-icon (click)="close()" size="large" name="close-outline"></ion-icon>
      </ion-title>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div id="explanation">
    <p [hidden]="hide.explanation">
      "Disabling" a stock item from this page will actually just modify the item in IQ to be easily recognisable, as well
      as set the on hold type of the item to prevent unintentional usage of the item entry.<br>
      <i>Full item removal from IQ TBA.</i>
    </p>
    <div>
      <ion-icon (click)="hide.explanation = !hide.explanation" [name]="'chevron-' + ( hide.explanation ? 'down' : 'up') + '-outline'"></ion-icon>
    </div>
  </div>

  <div class="setting-heading">
    Action when on hand (stock) is zero
    <ion-icon (click)="hide.onHandZero = !hide.onHandZero" [name]="'chevron-' + ( hide.onHandZero ? 'down' : 'up') + '-outline'"></ion-icon>
  </div>
  <div id="onHold-zero" class="inputs" [hidden]="hide.onHandZero" *ngIf="newSettings.onHandZero !== undefined">
    <div>
      <span>Description Prefix</span>
      <ion-input [disabled]="saving" class="ion-no-padding" [(ngModel)]="newSettings.onHandZero.descPrefix"></ion-input>
    </div>
    <div>
      <span>Description Suffix</span>
      <ion-input [disabled]="saving" class="ion-no-padding" [(ngModel)]="newSettings.onHandZero.descSuffix"></ion-input>
    </div>
    <div>
      <span>On Hold Type</span>
      <span class="select">
        {{ newSettings.onHandZero.onHoldCode }}
        <button [disabled]="saving" (click)="select($event, 'onHandZero')"><ion-icon name="caret-down-outline"></ion-icon></button>
      </span>
    </div>
    <div *ngIf="lineColours">
      <span>Line Colour</span>
      <span class="select" >
        <span *ngIf="newSettings.onHandZero.lineColour" [style]="'background:' + lineColours[newSettings.onHandZero.lineColour].value">
          {{ newSettings.onHandZero.lineColour }}: {{ lineColours[newSettings.onHandZero.lineColour].description }}
        </span>
        <button [disabled]="saving" (click)="selectColour($event, 'onHandZero')"><ion-icon name="caret-down-outline"></ion-icon></button>
      </span>
    </div>
  </div>

  <div class="setting-heading">
    Action when on hand (stock) is&nbsp;<strong>not</strong>&nbsp;zero
    <ion-icon (click)="hide.onHandNotZero = !hide.onHandNotZero" [name]="'chevron-' + ( hide.onHandNotZero ? 'down' : 'up') + '-outline'"></ion-icon>
  </div>
  <div id="onHold-notZero" class="inputs" [hidden]="hide.onHandNotZero" *ngIf="newSettings.onHandNotZero !== undefined">
    <div>
      <span>Description Prefix</span>
      <ion-input [disabled]="saving" class="ion-no-padding" [(ngModel)]="newSettings.onHandNotZero.descPrefix"></ion-input>
    </div>
    <div>
      <span>Description Suffix</span>
      <ion-input [disabled]="saving" class="ion-no-padding" [(ngModel)]="newSettings.onHandNotZero.descSuffix"></ion-input>
    </div>
    <div>
      <span>On Hold Type</span>
      <span class="select">
        {{ newSettings.onHandNotZero.onHoldCode }}
        <button [disabled]="saving" (click)="select($event, 'onHandNotZero')"><ion-icon name="caret-down-outline"></ion-icon></button>
      </span>
    </div>
    <div *ngIf="lineColours">
      <span>Line Colour</span>
      <span class="select" >
        <span *ngIf="newSettings.onHandNotZero.lineColour" [style]="'background:' + lineColours[newSettings.onHandNotZero.lineColour].value">
          {{ newSettings.onHandNotZero.lineColour }}: {{ lineColours[newSettings.onHandNotZero.lineColour].description }}
        </span>
        <button [disabled]="saving" (click)="selectColour($event, 'onHandNotZero')"><ion-icon name="caret-down-outline"></ion-icon></button>
      </span>
    </div>
  </div>

  <div class="tools">
    <span></span>
    <span>
      <button (click)="save()" [disabled]="(! valid()) || saving">
        <ion-icon *ngIf=" ! saving; else spinner" size="large" name="save-outline"></ion-icon>
      </button>
    </span>
  </div>

</ion-content>

<ng-template #spinner>
  <ion-spinner></ion-spinner>
</ng-template>
