import {createFeatureSelector, createSelector} from '@ngrx/store';
import {SharedState} from './shared.reducer';
import {selectSelectedUserStore} from '../../../features-as-modules/feature-core/store/core.selectors';
import {IStore} from '../../../shared/shared-models/store/store';

export const selectSharedState = createFeatureSelector<SharedState>('core-shared');

// ====================================================================================================
// Suppliers
// ====================================================================================================
export const selectIsSuppliersLoading = createSelector(
  selectSharedState,
  (state: SharedState) => state.isSuppliersLoading,
);

export const selectSuppliersByUserSelectedStore = createSelector(
  selectSharedState,
  selectSelectedUserStore,
  (state: SharedState, selectedStore: IStore) => state.suppliers[selectedStore.storeId] || {}
);

// ====================================================================================================
// Colleagues
// ====================================================================================================

export const selectColleagues = createSelector(
  selectSharedState,
  (state: SharedState) => state.colleagues,
);

export const selectIsColleaguesLoading = createSelector(
  selectSharedState,
  (state: SharedState) => state.isColleaguesLoading,
);

// ====================================================================================================
// Storage
// ====================================================================================================

export const selectStorageUrl = createSelector(
  selectSharedState,
  (state: SharedState) => state.storageUrl,
);


