import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-navigable-modal',
  templateUrl: './navigable-modal.component.html',
  styleUrls: ['./navigable-modal.component.scss'],
})
export class NavigableModalComponent implements OnInit {
  root: any;
  rootParams: any;

  constructor() { }

  ngOnInit() {}

}
