import {Component, Input} from '@angular/core';
import {
  SelectPopoverComponent,
} from '../../../../../shared-modules/shared-module/components/select-popover/select-popover.component';
import {setNGPReportColPreviewSettings, setStockManagerPreviewColumns} from '../../../store/settings.actions';
import {ColDef} from 'ag-grid-community';
import {GridUtils} from '../../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {PopoverController} from '@ionic/angular';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-settings-preview-columns',
  templateUrl: './settings-preview-columns.component.html',
  styleUrls: ['./settings-preview-columns.component.scss'],
})
export class SettingsPreviewColumnsComponent {
  @Input() previewColumns: { [key: number]: string };
  @Input() colDefs: ColDef[];
  @Input() type: string;

  selectPopover: {
    order: string[]; selection: { [key: string]: string }; disabledKeys: string[]; value: string[];
  } = {
    order: [], selection: {}, disabledKeys: [], value: [],
  };


  constructor(
    private popoverController: PopoverController,
    private readonly store: Store,
  ) {
  }

  async onOpenPreviewColumnsClick(fieldEvent: Event): Promise<void> {
    this.convertColumnDefinitionsToSelectPopover(this.colDefs);
    const popover: HTMLIonPopoverElement = await this.popoverController.create({
      component: SelectPopoverComponent, componentProps: {
        cancelText: 'Cancel',
        disabled: this.selectPopover.disabledKeys,
        maxListHeight: 250,
        multiple: true,
        okText: 'Apply',
        order: this.selectPopover.order,
        title: 'Set Preview Columns',
        search: true,
        selectAll: true,
        selection: this.selectPopover.selection,
        value: this.selectPopover.value,
      }, event: fieldEvent,
    });
    await popover.present();
    const data = await popover.onDidDismiss();
    if (data.data) {
      if (this.type === 'ngp') {
        this.store.dispatch(setNGPReportColPreviewSettings({columnData: {...data.data}}));
      } else if (this.type === 'stock') {
        this.store.dispatch(setStockManagerPreviewColumns({columnData: {...data.data}}));
      }
    }
  }

  private convertColumnDefinitionsToSelectPopover(colDefs: ColDef[]): void {
    let isVisible = this.previewColumns;
    this.selectPopover.selection = {};
    const order = [];
    const disabled = [];
    const selected = [];
    colDefs = [...colDefs];
    colDefs.forEach((col: ColDef) => {
      const description: string = GridUtils.getHumanReadableColumnIdentifier(col);
      if (col.lockPinned && col.field !== 'disabled') {
        disabled.push(col.field);
      }
      if (Object.values(isVisible).length > 0) {
        if (isVisible[col.field] || (col.lockPinned && col.field !== 'disabled')) {
          selected.push(col.field);
        }
      } else {
        if (!col.hide) {
          selected.push(col.field);
        }
      }

      order.push(col.field);
      this.selectPopover = {
        order,
        selection: {...this.selectPopover.selection, [col.field]: description},
        disabledKeys: disabled,
        value: selected,
      };
      const previewColumnsValues = Object.values(this.previewColumns);
      const combinedSet = new Set([...disabled, ...previewColumnsValues]);

      this.selectPopover.value = Array.from(combinedSet);
    });
  }
}
