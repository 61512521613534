<div
  class="cell-renderer-text"
  [ngClass]="params.data.originalValue?.[params.colDef.field]?.value !== value ? 'edited' : ''">
  <div>{{ value }}</div>
  <app-icon-backspace-outline
    *ngIf="params.data.originalValue?.[params.colDef.field]?.value !== value && !params.data.disabled"
    (click)="onUndoChangesClick()"
    [tooltip]="'Previous Value = ' +params.data.originalValue[params.colDef.field].value">
  </app-icon-backspace-outline>
</div>

