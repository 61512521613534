import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalController, PopoverController} from '@ionic/angular';
import {firstDayOWeek, isoDateIgnoreTimeZone} from '../../../../shared-utilities/functions-old/date-functions';

@Component({
  selector: 'app-date-range-selector',
  templateUrl: './date-range-selector.component.html',
  styleUrls: ['./date-range-selector.component.scss'],
})
export class DateRangeSelectorComponent implements OnInit {

  @Input() selectedDates: { start?: Date; end?: Date };
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() buttonInputText?: { start?: string; between?: string; end?: string };
  @Input() visibleButtons?: { reset?: boolean; cancel?: boolean; ok?: boolean };
  @Input() title?: boolean;
  @Input() isPopOver?: boolean;

  @Output() onchange = new EventEmitter<{ start?: Date; end?: Date }>();

  buttonText: string;
  filterDateValues: { start?: string; end?: string };
  minDateISO: string;
  maxDateISO: string;

  readonly todayISO = isoDateIgnoreTimeZone(new Date());

  constructor(
    private modalController: ModalController,
    private popControl: PopoverController
  ) { }

  ngOnInit() {
    this.minDateISO = this.minDate ? isoDateIgnoreTimeZone(this.minDate) : null;
    this.maxDateISO = this.maxDate ? isoDateIgnoreTimeZone(this.maxDate) : null;



    if (this.isPopOver && !this.visibleButtons) {
      this.visibleButtons = {reset: true, cancel: true, ok: true};
    }

    if (this.selectedDates && (this.selectedDates.start || this.selectedDates.end)) {
      this.filterDateValues = {};

      if (this.selectedDates.start) {
        this.filterDateValues.start = isoDateIgnoreTimeZone(this.selectedDates.start);
      }
      if (this.selectedDates.end) {
        this.filterDateValues.end = isoDateIgnoreTimeZone(this.selectedDates.end);
      }
    }
    this.buttonText = this.toggleDateFilterText();
  }

  async close() {
    const modal = await this.modalController.getTop();
    modal.classList.add('fade-out');
    setTimeout(() => {
      this.modalController.dismiss().then();
    }, 500);
  }

  async cancel() {
    if (this.selectedDates != null){
      await this.popControl.dismiss({start : this.selectedDates.start, end: this.selectedDates.end}).then();
    } else { await this.popControl.dismiss().then(); }
  }

  async reset() {
    if (this.isPopOver !== false) {
      await this.popControl.dismiss().then();
    } else {
      this.onchange.emit({start : null, end: null});
    }
  }

  async ok() {
    if (this.filterDateValues) {
      this.selectedDates = {
        start: this.filterDateValues.start ? new Date(this.filterDateValues.start) : null,
        end: this.filterDateValues.end ? new Date(this.filterDateValues.end) : null,
      };

      if (this.isPopOver) {
        await this.popControl.dismiss(this.selectedDates).then();
      } else {
        this.onchange.emit(this.selectedDates);
      }
    } else {
      if (this.isPopOver) {
        await this.popControl.dismiss().then();
      } else {
        this.onchange.emit(null);
      }
    }
  }

  toggleDateFilter() {
    let dateValues: { start?: Date; end?: Date };

    if (this.filterDateValues) {
      dateValues = {};

      if (this.filterDateValues.start) { dateValues.start = new Date(this.filterDateValues.start); }
      if (this.filterDateValues.end)   { dateValues.end =   new Date(this.filterDateValues.end);   }
    }

    if (!dateValues) {
      dateValues = {start: this.minDate ? this.minDate : firstDayOWeek(new Date(), 'monday')};
      this.filterDateValues = {start: isoDateIgnoreTimeZone(dateValues.start)};
      this.buttonText = this.toggleDateFilterText();
      return;
    }

    if (dateValues.start) {
      if (dateValues.end) {
        this.filterDateValues = null;
      } else {
        delete dateValues.start;
        delete this.filterDateValues.start;
        dateValues.end = this.maxDate ? this.maxDate : new Date();
        this.filterDateValues.end = isoDateIgnoreTimeZone(dateValues.end);
      }
    } else if (dateValues.end) {
      dateValues.start =  this.minDate ? this.minDate : firstDayOWeek(new Date(), 'monday');
      this.filterDateValues.start = isoDateIgnoreTimeZone(dateValues.start);
    }
    this.buttonText = this.toggleDateFilterText();
    this.onchange.emit({
      start: this.filterDateValues?.start ? new Date(this.filterDateValues.start) : undefined,
      end: this.filterDateValues?.end ? new Date(this.filterDateValues.end) : undefined
    });
  }

  private toggleDateFilterText(): string {
    if (!this.filterDateValues) {
      return 'select range';
    }

    if (this.filterDateValues.start) {
      if (this.filterDateValues.end) {
        return this.buttonInputText?.between || 'Between selected dates';
      }
      return this.buttonInputText?.start || 'After selected date';
    } else if (this.filterDateValues.end) {
      return this.buttonInputText?.end || 'Before selected date';
    }
    return 'select range';
  }


}
