/**
 * Interface representing the options for exporting data as CSV in AG Grid.
 * @interface
 *
 * @property {string} [fileName] The name of the exported CSV file.
 * @property {string[]} [columnKeys] An array of column keys to specify which columns to include in the export.
 * @property {boolean} [allColumns] A flag to indicate whether to export all columns, including those not visible.
 * @property {boolean} [onlySelected] A flag to indicate whether to export only the selected rows.
 * @property {boolean} [suppressQuotes] A flag to indicate whether to suppress quotes in the CSV output.
 * @property {string} [columnSeparator] A custom separator to use in the CSV file. Defaults to a comma (',').
 * @property {Function} [processCellCallback] A callback function to process each cell's data before exporting.
 * @property {ParamType} params - The parameters for the callback function.
 * @property {any} params.value - The value of the cell.
 * @property {ReturnType} - The processed cell value.
 * @template ParamType The type of the parameter object passed to the callback.
 * @template ReturnType The type of the value returned by the callback.
 */
export interface IAgGridExportSettings {
  /**
   * The name of the exported CSV file.
   * @type {string}
   */
  fileName?: string;

  /**
   * An array of column keys to specify which columns to include in the export.
   * @type {string[]}
   */
  columnKeys?: string[];

  /**
   * A flag to indicate whether to export all columns, including those not visible.
   * @type {boolean}
   */
  allColumns?: boolean;

  /**
   * A flag to indicate whether to export only the selected rows.
   * @type {boolean}
   */
  onlySelected?: boolean;

  /**
   * A flag to indicate whether to suppress quotes in the CSV output.
   * @type {boolean}
   */
  suppressQuotes?: boolean;

  /**
   * A custom separator to use in the CSV file. Defaults to a comma (',').
   * @type {string}
   */
  columnSeparator?: string;

  /**
   * A callback function to process each cell's data before exporting.
   * @param {ParamType} params - The parameters for the callback function.
   * @param {any} params.value - The value of the cell.
   * @returns {ReturnType} - The processed cell value.
   * @template ParamType The type of the parameter object passed to the callback.
   * @template ReturnType The type of the value returned by the callback.
   * @type {(params: ParamType) => ReturnType}
   */
  processCellCallback?<ParamType, ReturnType>(params: ParamType): ReturnType;
}

export const DEFAULT_AG_GRID_SETTINGS: IAgGridExportSettings = {
  fileName: 'NO_FILENAME_GIVEN',
  columnKeys: [],
  allColumns: false,
  onlySelected: false,
  suppressQuotes: true,
  columnSeparator: ','
}
