<app-modal-header
  [close]="close"
  [title]="storeInfo.name + ' Auto Ordering Email Settings'"
></app-modal-header>

<div *ngIf="isUserEmailSettingsLoading; else userAccordions" class="loading-div">
  <app-shared-loading-indicator [animation]="animationLogo"></app-shared-loading-indicator>
</div>

<ng-template #userAccordions>
  <ion-content>
    <ion-accordion-group (ionChange)="accordionChange($event)" [multiple]="false">
      <ion-accordion
        *ngFor="let userEntry of editableUsers | keyvalue; trackBy trackByFunction"
        [value]="userEntry.key">
        <ion-item color="light" slot="header">
          <ion-label class="truncate text">
            {{ userEntry.key }}
            <!-- Add any banners or additional labels here if needed -->
          </ion-label>
        </ion-item>
        <div
          class="staff-pain scroll-pain ion-padding store-row"
          id="colleagues"
          slot="content">
          <ion-col class="truncate text" size="3"></ion-col>
          <ion-col>
            <ion-grid>
              <div id="settings-container">
                <ion-fab
                  *ngIf="testing"
                  class="pulse-notification-fab"
                  horizontal="end"
                  slot="fixed"
                  vertical="top">
                  <div>
                    <span class="mdi mdi-test-tube"></span>
                  </div>
                </ion-fab>
                <ion-grid id="header-grid">
                  <ion-row>
                    <ion-col>Settings for {{ storeInfo.name }}</ion-col>
                  </ion-row>
                </ion-grid>
                <ion-grid
                  *ngIf="settingsForm; else spinner"
                  [formGroup]="settingsForm"
                  id="settings-form">
                  <ion-row class="copy-paste">
                    <ion-col
                      offset-md="3"
                      offset-sm="5"
                      size-md="4"
                      size-sm="5">
                      <div>
                        <button (click)="copy()" type="button">
                          <span><ion-icon name="copy-outline"></ion-icon> Copy</span>
                        </button>
                      </div>
                    </ion-col>
                    <ion-col>
                      <div>
                        <button (click)="paste()" [disabled]="!copiedValue" type="button">
                          <span><ion-icon name="clipboard-outline"></ion-icon> Paste</span>
                        </button>
                      </div>
                    </ion-col>
                  </ion-row>

                  <ion-row
                    [class]="'section-header' + (settingsForm.invalid ? ' ng-invalid' : '')">
                    <ion-col size-md="7" size-sm="9">
                      Email Details
                    </ion-col>
                  </ion-row>
                  <div>
                    <!-- Email Details Form -->
                    <!-- Since 'email', 'user', 'pwd' are directly under 'settingsForm', we can use formControlName directly -->
                    <ion-row class="field-row">
                      <ion-col size-md="3" size-sm="4">
                        Sender Address
                      </ion-col>
                      <ion-col size-md="4" size-sm="5">
                        <ion-input
                          (ionChange)="changeEvt($event, 'email')"
                          [disabled]="saving || !!testing"
                          autocomplete="false"
                          formControlName="email"
                          type="email">
                        </ion-input>
                      </ion-col>
                    </ion-row>
                    <ion-row class="field-row">
                      <ion-col size-md="3" size-sm="4">
                        User Name
                      </ion-col>
                      <ion-col size-md="4" size-sm="5">
                        <ion-input
                          (ionChange)="changeEvt($event, 'user')"
                          [disabled]="saving || !!testing"
                          autocomplete="false"
                          formControlName="user">
                        </ion-input>
                      </ion-col>
                    </ion-row>
                    <ion-row class="field-row">
                      <ion-col size-md="3" size-sm="4">
                        Password
                      </ion-col>
                      <ion-col size-md="4" size-sm="5">
                        <ion-input
                          (ionChange)="changeEvt($event, 'pwd')"
                          [disabled]="saving || !!testing"
                          autocomplete="false"
                          formControlName="pwd"
                          type="password">
                        </ion-input>
                      </ion-col>
                    </ion-row>
                    <!-- Other fields -->
                    <!-- For 'smtp' and 'imap', which are FormGroups, we need to use formGroupName -->
                    <ion-row class="field-row subgroup-head">
                      <ion-col size-md="3" size-sm="4">SMTP</ion-col>
                      <ion-col size-md="4" size-sm="5"></ion-col>
                    </ion-row>
                    <div formGroupName="smtp">
                      <!-- SMTP Fields -->
                      <ion-row class="field-row">
                        <ion-col size-md="3" size-sm="4">
                          SMTP Server
                        </ion-col>
                        <ion-col size-md="4" size-sm="5">
                          <ion-input
                            (ionChange)="changeEvt($event, 'smtp.server')"
                            [disabled]="saving || !!testing"
                            formControlName="server"
                          ></ion-input>
                        </ion-col>
                      </ion-row>
                      <!-- Other SMTP fields -->
                      <!-- SSL Only -->
                      <ion-row class="field-row">
                        <ion-col size-md="3" size-sm="4">
                          SSL Only
                        </ion-col>
                        <ion-col size-md="4" size-sm="5">
                          <ion-toggle
                            (ionChange)="changeEvt($event, 'smtp.sslOnly')"
                            [disabled]="saving || !!testing"
                            formControlName="sslOnly"
                          ></ion-toggle>
                        </ion-col>
                      </ion-row>
                    </div>
                    <ion-row class="field-row subgroup-head">
                      <ion-col size-md="3" size-sm="4">IMAP</ion-col>
                      <ion-col size-md="4" size-sm="5"></ion-col>
                    </ion-row>
                    <div formGroupName="imap">
                      <!-- IMAP Fields -->
                      <ion-row class="field-row">
                        <ion-col size-md="3" size-sm="4">
                          IMAP Server
                        </ion-col>
                        <ion-col size-md="4" size-sm="5">
                          <ion-input
                            (ionChange)="changeEvt($event, 'imap.server')"
                            [disabled]="saving || !!testing"
                            formControlName="server">
                          </ion-input>
                        </ion-col>
                      </ion-row>
                      <!-- Other IMAP fields -->
                      <!-- SSL Only -->
                      <ion-row class="field-row">
                        <ion-col size-md="3" size-sm="4">
                          SSL Only
                        </ion-col>
                        <ion-col size-md="4" size-sm="5">
                          <ion-toggle
                            (ionChange)="changeEvt($event, 'imap.sslOnly')"
                            [disabled]="saving || !!testing"
                            formControlName="sslOnly">
                          </ion-toggle>
                        </ion-col>
                      </ion-row>
                    </div>
                    <!-- Test Email Button -->
                    <ion-row class="test-button">
                      <ion-col size-md="3" size-sm="4">Test Email Settings</ion-col>
                      <ion-col size-md="4" size-sm="5">
                        <ion-button
                          (click)="testEmail()"
                          [disabled]="settingsForm.get('imap').invalid || !!testing"
                          color="light"
                          size="small"
                        >
                          Test
                        </ion-button>
                      </ion-col>
                    </ion-row>
                  </div>
                  <ion-row>
                    <ion-col offset-md="3" offset-sm="5" size-md="4" size-sm="5">
                      <ion-button
                        (click)="save()"
                        [disabled]="settingsForm.invalid || saving"
                        size="small"
                      >Save
                      </ion-button
                      >
                    </ion-col>
                  </ion-row>
                </ion-grid>
                <ng-template #spinner>
                  <div class="spinner">
                    <ion-spinner></ion-spinner>
                  </div>
                </ng-template>
              </div>
            </ion-grid>
          </ion-col>
        </div>
      </ion-accordion>
    </ion-accordion-group>
  </ion-content>
</ng-template>

