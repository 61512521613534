import {NGPReport} from '../ngp-reports/ngp-report';
import {FiltersAndTools} from '../ngp-reports/filters-tools-ngp';
/**
 * @class FilterAndToolUtils
 * Utilities for manipulating and NGP Reports on the Grid - Tools and Utilities.
 *
 * @member countOnPropertyNGPReport Count the amount of reports that has a property that equals a value.
 * @member countOnNumberPropertyNGPReport Count the amount of reports that has a number property that equals a value based on the operator.
 * @member determineFiltersAndTools Determine the Filters and Tools Menu / Actions for the NGP Report Header.
 * @member changeFilterAndToolsCounts Change the Filter and Tools counts based on toggled switches on the menu.
 */
export class FilterAndToolUtils {

  /**
   * Count the amount of reports that has a property that equals a value.
   *
   * @param ngpReports The NGP Reports it needs to count based on a filtered property.
   * @param property The property it needs to filtered for the count.
   * @param value The value that needs to be checked for the count to be valid.
   *
   * @returns A count for the amount of properties equalling the value.
   */
  static countOnPropertyNGPReport(
    ngpReports: NGPReport[],
    property: string,
    value: string | boolean
  ): number {
    return ngpReports.filter((report: NGPReport) => report[property] === value).length;
  }

  /**
   * Count the amount of reports that has a number property that equals a value based on the operator.
   *
   * @param ngpReports The NGP Reports it needs to count based on a filtered property.
   * @param property The property it needs to filtered for the count.
   * @param operator The operator that needs to be checked `<` | `<=` | `>` | `>=` | `=`
   * @param amount The amount the operator checks against.
   *
   * @returns A count for the amount of number properties equalling operated the value.
   */
  static countOnNumberPropertyNGPReport(
    ngpReports: NGPReport[],
    property: string,
    operator: string | `<` | `<=` | `>` | `>=` | `=`,
    amount: number = 0
  ): number {
    let count = 0;
    switch (operator) {
      case '<':
        count = ngpReports.filter((report: NGPReport): boolean => report[property] < amount).length;
        break;
      case '<=':
        count = ngpReports.filter((report: NGPReport): boolean => report[property] <= amount).length;
        break;
      case '>':
        count = ngpReports.filter((report: NGPReport): boolean => report[property] > amount).length;
        break;
      case '>=':
        count = ngpReports.filter((report: NGPReport): boolean => report[property] >= amount).length;
        break;
      case '=':
        count = ngpReports.filter((report: NGPReport): boolean => report[property] === amount).length;
        break;
    }
    return count;
  }

  /**
   * Determine the Filters and Tools Menu / Actions for the NGP Report Header.
   *
   * @param ngpReports The NGP Reports it needs to count based on a filtered property.
   * @param filtersAndTools An object for the Filter and Tools Menu / Actions that will be amended.
   * @param ngpReportsNewlyAdded The NGP Reports that have been added while the user is on the ngp page.
   *
   * @returns An object containing the values for the Filter and Tools Menu / Actions.
   */
  static determineFiltersAndTools(ngpReports: NGPReport[], filtersAndTools: FiltersAndTools, ngpReportsNewlyAdded?: NGPReport[]): FiltersAndTools {
    return {
      ...filtersAndTools,
      total: ngpReports.length,
      editedCount: this.countOnPropertyNGPReport(ngpReports, 'isEdited', true),
      gpDifferencePositive: this.countOnNumberPropertyNGPReport(ngpReports, 'diffGP', '>=', 0),
      gpDifferenceNegative: this.countOnNumberPropertyNGPReport(ngpReports, 'diffGP', '<', 0),
      newItemCount: ngpReportsNewlyAdded?.length > 0 ? ngpReportsNewlyAdded.length : 0,
    };
  }

  /**
   * Change the Filter and Tools counts based on toggled switches on the menu.
   *
   * @param filtersAndTools An object for the Filter and Tools Menu / Actions that will be amended and checked.
   * @param existingReports An object that gives a list of all ngp reports that existed before new items were added.
   *
   * @returns An object containing the values for the Filter and Tools Menu / Actions.
   */
  static changeFilterAndToolsCounts(filtersAndTools: FiltersAndTools, existingReports?: NGPReport[]): FiltersAndTools {
    const fAndTools = {...filtersAndTools};
    existingReports = existingReports?.filter((report: NGPReport) => report.newlyAdded)
    fAndTools.totalShowing = 0;
    if (fAndTools.isGpDifferencePositiveOn) {
      fAndTools.totalShowing += fAndTools.gpDifferencePositive;
    }
    if (fAndTools.isGpDifferenceNegativeOn) {
      fAndTools.totalShowing += fAndTools.gpDifferenceNegative;
    }
    if (fAndTools.isEditedItemsOn) {
      fAndTools.isGpDifferencePositiveOn = true;
      fAndTools.isGpDifferenceNegativeOn = true;
      fAndTools.totalShowing = fAndTools.editedCount;
    }
    if (existingReports?.length > 0 ){
      fAndTools.totalShowing = fAndTools.totalShowing - existingReports.length;
    }
    return fAndTools;
  }

}
