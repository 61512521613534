<app-modal-header title="Editors Configuration" [close]="close" ></app-modal-header>

<ion-content>
  <ion-fab *ngIf="changes" vertical="top" horizontal="end" slot="fixed">
    <ion-fab-button color="light" (click)="save()">
      <ion-icon color="warning" name="save-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <div id="content">
    <div class="section">
      <div class="heading" (click)="showSection('stockEditors')">
        Stock Editors
      </div>
      <div class="content" [hidden]="!show.stockEditors">
        <ng-container *ngIf="hasAccess('priceBanding'); else noPBTemplate">
          <div class="heading" (click)="showSection('stockEditors.priceBranding')">
            Price Banding
          </div>
          <div class="content price-banding" [hidden]="!show['stockEditors.priceBranding']">
            <app-price-banding [stores]="stores" [setChanges]="setChanges"></app-price-banding>
          </div>
        </ng-container>
        <ng-template #noPBTemplate>
          <ng-container *ngTemplateOutlet="noAccessTemplate; context: {text: 'Price Banding'}"></ng-container>
        </ng-template>
      </div>
      <div class="content" [hidden]="!show.stockEditors">
        <ng-container *ngIf="hasAccess('priceBanding'); else noPBTemplate">
          <div class="heading" (click)="showSection('stockEditors.priceThreshold')">
            Price Thresholds
          </div>
          <div class="content" [hidden]="!show['stockEditors.priceThreshold']">
            <app-price-threshold [stores]="stores"></app-price-threshold>
          </div>
        </ng-container>
        <ng-template #noPBTemplate>
          <ng-container *ngTemplateOutlet="noAccessTemplate; context: {text: 'Price Thresholds'}"></ng-container>
        </ng-template>
      </div>
    </div>
  </div>
</ion-content>

<ng-template #noAccessTemplate let-text=text>
  <div class="heading no-access">
    {{ text }}
    <i>you do not have access to these settings</i>
  </div>
</ng-template>
