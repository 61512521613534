<ng-container *ngIf="apiEvent; else spinner">
  <ion-row>
    <ion-col><b>{{ storeName }} API Response</b></ion-col>
  </ion-row>
  <ng-container *ngIf="apiEvent.status === 'FAILED'; else success">
    <ion-row class="fail-header">
      <ion-col>FAILED</ion-col>
      <ion-col>
        <span *ngIf="redone"><i>Already reattempted</i></span>
        <button (click)="redo()" [disabled]="redone || changing">
          <ion-icon name="refresh-outline"></ion-icon>
        </button>
      </ion-col>
    </ion-row>
    <ion-row class="fail-subheader">
      <ion-col>
        <i>Detailed error messages are in the works, for now you can retry and / or let Techodactyl Support know.</i>
      </ion-col>
    </ion-row>
  </ng-container>
  <ng-template #success>
    <ng-container *ngIf=" ! messageSuccess">
      <ion-row class="reattempted-header">
        <ion-col>
          <i>This api call was reattempted successfully at {{ apiEvent.executedDate.toLocaleString() }}</i>
        </ion-col>
      </ion-row>
    </ng-container>
  </ng-template>
  <ion-row>
    <ion-col size="4">Created at</ion-col>
    <ion-col size="auto">{{ apiEvent.creationDate.toLocaleTimeString() }}</ion-col>
    <ion-col size="auto">{{ apiEvent.creationDate.toLocaleDateString() }}</ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="4">Executed at</ion-col>
    <ion-col size="auto">{{ apiEvent.executedDate.toLocaleTimeString() }}</ion-col>
    <ion-col size="auto">{{ apiEvent.executedDate.toLocaleDateString() }}</ion-col>
  </ion-row>
  <ion-row class="changes-per-code">
    <ion-col>Changes per stock code:</ion-col>
  </ion-row>

  <ng-container *ngIf="!apiEvent.hasOwnProperty('payload'); else shittyLegacy">
    <ion-row *ngFor="let code of keys(apiEvent.data)" class="item-changes">
      <ion-col size="2">{{ code }}</ion-col>
      <ion-col >
      <span class="change" *ngFor="let key of keys(apiEvent.data[code].n)" (click)="fieldChangePopover($event, msgTS, code)">
        <span></span>
        {{ keyToTitle[i2k[key]] }}
      </span>
      </ion-col>
  </ion-row>
</ng-container>
<ng-template #shittyLegacy>
    <ion-row *ngFor="let code of keys(apiEvent.payload)" class="item-changes">
      <ion-col size="2">{{ code }}</ion-col>
      <ion-col>
      <span class="change" *ngFor="let key of keys(apiEvent.payload[code])" (click)="fieldChangePopover($event, msgTS, code)">
        {{ keyToTitle[i2k[key]] }}
      </span>
      </ion-col>
    </ion-row>
  </ng-template>
  <button  class="view-button" (click)="viewAll()">View all changes</button>
</ng-container>

<ng-template #spinner>
  <ion-row class="spinner">
    <ion-spinner></ion-spinner>
  </ion-row>
</ng-template>
