import {Component, Input, OnInit} from '@angular/core';
import {Icons} from '../../../../shared-modules/shared-icons/icons';
import {ModalController} from '@ionic/angular';
import {ColDef} from 'ag-grid-community';
import {IAdvancedFilterGroup, IFilterGroup} from '../../../shared-models/filter-groups/filter-groups';
import {Store} from '@ngrx/store';
import {
  applyAdvancedFilterGroupFilterToGrid,
  cancelEditingOfAdvancedFilterGroup,
  cloneSelectedAdvancedFilterGroup,
  createAdvancedFilterGroup,
  deleteSelectedAdvancedFilterGroup,
  getAdvancedFilterGroups,
  removeAdvancedFilterGroupFilterFromGrid,
  saveAdvancedFilterGroup,
  saveSelectedAdvancedFilterGroup,
  setAdvancedFilterGroupGrid,
  setSelectedAdvancedFilterGroup,
} from '../../../../features/core/store-shared-filter/shared-filter.actions';
import {getDeepCopyOfObject} from '../../../shared-utils/object/object.utils';
import {ISharedModalBasic, ISharedModalBasicResponse} from '../../../shared-models/modals/shared-modal-basic';
import {ISharedModalFiltersAdvanced} from '../../../shared-models/modals/shared-modal-filters-advanced';
import {Observable} from 'rxjs';
import {selectSelectedAdvancedFilterGroupFilterGroups} from '../../../../features/core/store-shared-filter/shared-filter.selectors';
import {
  AnimationDefault, AnimationDirection, Animations, IAnimation
} from '../../../shared-models/animations/animation';
import {SharedModalBasicComponent} from '../shared-modal-basic/shared-modal-basic.component';
import {OverlayEventDetail} from '@ionic/core';

@Component({
  selector: 'app-shared-modal-filters-advanced',
  templateUrl: './shared-modal-filters-advanced.component.html',
  styleUrls: ['./shared-modal-filters-advanced.component.scss'],
})
export class SharedModalFiltersAdvancedComponent implements ISharedModalFiltersAdvanced, OnInit {

  @Input() buttonCloseCross: boolean;
  @Input() colDefs: ColDef[];
  @Input() colDefsIgnore: string[];
  @Input() modalTitle: string;

  @Input() isAdvancedFiltersLoading: boolean;

  advancedFilterGroups: IAdvancedFilterGroup[] = [];
  appliedAdvancedFilterGroup: IAdvancedFilterGroup;
  colDefsNames: ColDef[] = [];
  selectedAdvancedFilterGroup: IAdvancedFilterGroup;
  selectedAdvancedFilterGroupOriginal: IAdvancedFilterGroup;
  selectedGroupData$: Observable<IFilterGroup>;
  selectedGrid: string;

  protected readonly animationLogo: IAnimation = {
    ...AnimationDefault,
    animation: Animations.CIRCLE_DISAPPEAR_AND_REAPPEAR,
    direction: AnimationDirection.REVERSE,
    fixedDimensions: {width: 1, height: 1},
    id: 'shared-advanced-filter-groups-modal-animation-logo'
  }

  protected readonly icons = Icons;

  constructor(
    private modalController: ModalController,
    private readonly store: Store
  ) {
  }

  get getColumnList(): ColDef[] {
    return this.colDefs.filter((col: ColDef) => !this.colDefsIgnore?.includes(col.field));
  }

  get getIsNewAdvancedFilterGroup(): boolean {
    const found = this.advancedFilterGroups.filter((filterGroup: IAdvancedFilterGroup) => !filterGroup.saved);
    return found.length > 0;
  }

  @Input() set setAdvancedFilterGroups(filterGroups: IAdvancedFilterGroup[]) {
    this.advancedFilterGroups = [];
    if (filterGroups?.length > 0) {
      this.advancedFilterGroups = filterGroups.length > 0 ? getDeepCopyOfObject(filterGroups) : [];
    }
  };

  @Input() set setAppliedFilter(appliedFilter: IAdvancedFilterGroup) {
    this.appliedAdvancedFilterGroup = undefined;
    if (appliedFilter) {
      this.appliedAdvancedFilterGroup = appliedFilter;
    }
  }

  @Input() set gridReference(grid: string) {
    this.selectedGrid = grid;
    this.store.dispatch(setAdvancedFilterGroupGrid({grid}))
  }

  @Input() set setSelectedAdvancedFilterGroup(advancedFilterGroup: IAdvancedFilterGroup) {
    this.selectedAdvancedFilterGroup = undefined;
    this.selectedAdvancedFilterGroupOriginal = undefined;
    if (advancedFilterGroup) {
      this.selectedAdvancedFilterGroup = getDeepCopyOfObject(advancedFilterGroup);
      this.selectedAdvancedFilterGroupOriginal = getDeepCopyOfObject(advancedFilterGroup);
      this.selectedAdvancedFilterGroupOriginal.saved = true;
    }
  };

  ngOnInit(): void {
    this.colDefsNames = this.getColumnList;
    this.store.dispatch(getAdvancedFilterGroups());
    this.selectedGroupData$ = this.store.select(selectSelectedAdvancedFilterGroupFilterGroups);
  }

  onApplyFilterClick(): void {
    this.store.dispatch(applyAdvancedFilterGroupFilterToGrid());
  }

  onRemoveFilterClick(): void {
    this.store.dispatch(removeAdvancedFilterGroupFilterFromGrid());
  }

  onButtonPress(buttonPressed: 'button-accept' | 'button-close'): void {
    void this.modalController
      .dismiss({buttonPressed} as ISharedModalBasicResponse, buttonPressed === 'button-accept' ? 'modal-accept' : 'modal-close')
      .then();
  }

  onCloneItemClick(): void {
    this.store.dispatch(cloneSelectedAdvancedFilterGroup());
  }

  onNameChange(): void {
    if (this.selectedAdvancedFilterGroup.name !== this.selectedAdvancedFilterGroupOriginal.name) {
      this.store.dispatch(saveAdvancedFilterGroup({advancedFilterGroup: this.selectedAdvancedFilterGroup}));
    }
  }

  onNewItemClick(): void {
    this.store.dispatch(createAdvancedFilterGroup({grid: this.selectedGrid}));
  }

  onSaveItemClick(): void {
    this.store.dispatch(saveSelectedAdvancedFilterGroup());
  }

  onCancelItemClick(): void {
    this.store.dispatch(cancelEditingOfAdvancedFilterGroup({originalAdvancedFilterGroup: this.selectedAdvancedFilterGroupOriginal}));
  }

  onSelectedNameClick(name: string): void {
    const advancedFilterGroup = this.advancedFilterGroups.find((group: IAdvancedFilterGroup): boolean => group.name === name);
    this.store.dispatch(setSelectedAdvancedFilterGroup({advancedFilterGroup}));
    this.selectedAdvancedFilterGroup = undefined;
  }

  trackByFunction(index: number): number {
    return index;
  }

  async onDeleteAdvancedFilterGroupClick(): Promise<void> {
    const componentProps: ISharedModalBasic = {
      buttonAccept: true,
      buttonAcceptText: 'Confirm Delete',
      buttonClose: true,
      buttonCloseCross: false,
      buttonCloseText: `Cancel`,
      modalTitle: `Confirm Delete of Advanced Filter Group`,
      modalTitleIcon: Icons.infoSlabCircleOutline,
      modalTitleIconColor: 'yellow',
      contextHeading: 'NOTE: You are about to delete an entire advanced filter group.',
      contextDescription: [
        'Please make sure you want to perform the following delete.',
        ` - ${this.selectedAdvancedFilterGroup.name}`,
        'This action is irreversible.',
        `If this is you are certain please press 'Confirm Delete'.`,
        `If you would like to cancel press 'Cancel'.`
      ]
    }
    const modalController: HTMLIonModalElement = await this.modalController.create({
      component: SharedModalBasicComponent, componentProps, cssClass: ['shared-basic-modal-css'],
    });
    await modalController.present();
    const modalResponse: OverlayEventDetail<ISharedModalBasicResponse> = await modalController.onDidDismiss();
    if (modalResponse?.data?.buttonPressed === 'button-accept') {
      this.onDeleteItemClick();
    }
  }

  private onDeleteItemClick(): void {
    this.store.dispatch(deleteSelectedAdvancedFilterGroup());
  }

}
