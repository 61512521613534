import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
})
export class ModalHeaderComponent {
  @Input() title: string;
  @Input() close: () => void;
  constructor(private modalController: ModalController,) {
  }
}
