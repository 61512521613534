import {Component, Input, OnInit} from '@angular/core';
import {IStore} from '../../../../../shared/shared-models/store/store';
import {DisabledRules, LineColour, TableNavSettings} from '../../../../../shared-utilities/models-old/datastructures';
import {ModalController} from '@ionic/angular';
import {setTableNavSettings} from '../../../store/settings.actions';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {selectLineColour, selectNavSettings} from '../../../store/settings.selectors';

@Component({
  selector: 'app-settings-general-grid',
  templateUrl: './settings-general-grid.component.html',
  styleUrls: ['./settings-general-grid.component.scss'],
})
export class SettingsGeneralGridComponent implements OnInit {
  @Input() currentStore: IStore;
  @Input() itemDisablingRules: DisabledRules;

  tableNavigationSettings$: Observable<TableNavSettings>;
  lineColours$: Observable<LineColour>;

  constructor(
    private modalController: ModalController,
    private readonly store: Store,
  ) {
  }

  ngOnInit(): void {
    this.lineColours$ = this.store.select(selectLineColour);
    this.tableNavigationSettings$ = this.store.select(selectNavSettings);
  }

  close = (): void => {
    void this.modalController.dismiss().then();
  };

}
