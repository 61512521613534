
/**
 * @class NumberUtils
 * Utilities for manipulating and displaying numbers.
 *
 * @member formatNumberToDecimals Format any number to be displayed with custom decimal places
 */
export class NumberUtils {

  /**
   * Format any number to be displayed with custom decimal places
   *
   * @param value The value that needs to be formatted.
   * @param decimalDigits The number of digits to be displayed.
   * @returns a string formatted value, displaying the value with selected decimal places
   *
   */

  static formatNumberToDecimals(value: number, decimalDigits: number = 0, ): string | number {
    return ((value * 100) / 100).toFixed(decimalDigits);

  }

}
