import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as CoreActions from './core.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {from, of} from 'rxjs';
import {FirebaseService} from '../../../shared-utilities/services-old/firebase.service';
import {IUser} from '../../../shared/shared-models/user-access/user';
import {StoreObject} from '../../../shared-utilities/models-old/store/store-object';
import {FireAuthService} from '../../../shared-utilities/services-old/fire-auth.service';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {MessagesService} from '../../../shared-utilities/services-old/message-service/messages.service';
import {IUserAccess} from '../../../shared/shared-models/user-access/user-access';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {
  CollectionUserAccessColleaguesService,
} from '../../../shared/shared-services/firebase/collection-user-access-colleagues.service';

@Injectable()
export class CoreEffects {

  // ====================================================================================================
  // Get User
  // ====================================================================================================

  // '[Core][User] Get User'
  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.getUser),
      mergeMap(() =>
        from(this.firebaseService.userObj).pipe(
          mergeMap((user: IUser | null) => {
            if (user === null) {
              return of(
                CoreActions.setCurrentPageAndTab({currentSelectedPage: {currentPage: 'login', currentTab: null}}),
                CoreActions.getUserSuccess({user}));
            } else {
              return of(
                CoreActions.setCurrentPageAndTab({currentSelectedPage: {currentPage: 'home', currentTab: null}}),
                CoreActions.getUserSuccess({user}));
            }
          }),
          catchError((error: IError) => of(CoreActions.getUserFailure({error}))),
        ),
      ),
    ),
  );

  // ====================================================================================================
  // Get User Stores
  // ====================================================================================================

  // '[Core][User Stores] Get User Stores'
  getUserStores$ = createEffect(() => this.actions$.pipe(
    ofType(CoreActions.getUserStores),
    mergeMap(({pageStoreDocument}) =>
      this.firebaseService.pageStores(pageStoreDocument)
        .pipe(
          map((storeObject: StoreObject) => CoreActions.getUserStoresSuccess({storeObject})),
          catchError((error: IError) => of(CoreActions.getUserStoresFailure({error}))),
        ),
    ),
  ));

  // ====================================================================================================
  // Get Image URL
  // ====================================================================================================
  // '[Core][Image] Get Image URL'
  getImageURL = createEffect(() => this.actions$.pipe(
    ofType(CoreActions.getImageURL),
    mergeMap(({path, image}) =>
      this.firebaseService.getImageUrl(path)
        .pipe(
          map((url: string) => CoreActions.getImageURLSuccess({url, image})),
          catchError((error: IError) => of(CoreActions.getImageURLFailure({error, image}))),
        ),
    ),
  ));

  // ====================================================================================================
  // Get User Access
  // ====================================================================================================

  // '[Core][User] Get User Access'
  getUserAccess$ = createEffect(() => this.actions$.pipe(
    ofType(CoreActions.getUserAccess),
    mergeMap(() =>
      this.fireAuthService.userAccess
        .pipe(
          map((userAccess: IUserAccess) => CoreActions.getUserAccessSuccess({userAccess})),
          catchError((error: IError) => of(CoreActions.getUserAccessFailure({error}))),
        ),
    ),
  ));

  // ====================================================================================================
  // User Messages
  // ====================================================================================================

  // '[Core][Messages] Get User Message Count'
  getUserMessageCount$ = createEffect(() => this.actions$.pipe(
    ofType(CoreActions.getUserMessageCount),
    mergeMap(() =>
      this.messagesService.numLiveMsgsSub
        .pipe(
          map((messageCount: number) => CoreActions.getUserMessageCountSuccess({messageCount})),
          catchError((error: IError) => of(CoreActions.getUserMessageCountFailure({error}))),
        ),
    ),
  ));


  constructor(
    private actions$: Actions,
    private firebaseService: FirebaseService,
    private fireAuthService: FireAuthService,
    private messagesService: MessagesService,
    private router: Router,
    private collectionUserAccessColleaguesService: CollectionUserAccessColleaguesService,
    private readonly store: Store,
  ) {
  }


}
