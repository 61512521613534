/**
 * @class UiUtils
 * Utilities for manipulating and displaying UI elements within the application.
 *
 * @member convertLineColourCodeToClassString Converts a `LineColourCode` to a css class.
 */
export class UiUtils {

  /**
   * `convertLineColourCodeToClassString` Converts a `LineColourCode` to a css class.
   *
   * @param code The `LineColourCode` that needs to be converted.
   * @param storeId The relevant store id that will be added to the class.
   * @param cssClass The pre-fix for the css class.
   */
  static convertLineColourCodeToClassString(code: number | any, storeId: string, cssClass: string): string {
    const colourCode = typeof code === 'number' ? code : 0;
    return `${cssClass}${storeId}-${colourCode}`;
  }

}
