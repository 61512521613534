/**
 * start?: { v: number; inc?: boolean };
 *
 * end?: { v: number; inc?: boolean };
 */
export interface IRange {
  start?: { v: number; inc?: boolean };
  end?: { v: number; inc?: boolean };
}

export const rangeOverlap = (r1: IRange, r2: IRange, min: number = Number.NEGATIVE_INFINITY,
                             max: number = Number.POSITIVE_INFINITY) => {
  const x1 = r1.start && r1.start.v !== null && r1.start.v !== undefined ? r1.start.v : min;
  const x2 = r1.end && r1.end.v !== null && r1.end.v !== undefined ? r1.end.v : max;
  const y1 = r2.start && r2.start.v !== null && r2.start.v !== undefined ? r2.start.v : min;
  const y2 = r2.end && r2.end.v !== null && r2.end.v !== undefined ? r2.end.v : max;
  const maxStart = Math.max(x1, y1);
  const minEnd = Math.min(x2, y2);

  if (maxStart < minEnd) {
    return true;
  } else if (maxStart === minEnd) {
    if (maxStart === x1) {
      if (!r1.start.inc) {
        return false;
      }
    } else {
      if (!r2.start.inc) {
        return false;
      }
    }
    if (minEnd === x2) {
      if (!r1.end.inc) {
        return false;
      }
    } else {
      if (!r2.end.inc) {
        return false;
      }
    }
    return true;
  }
  return false;
};

export const copyRange = (range: IRange) => {
  if (range) {
    const r2: IRange = {};

    for (const part of Object.keys(range)) {
      r2[part] = {};
      for (const key of Object.keys(range[part])) {
        r2[part][key] = range[part][key];
      }
    }
    return r2;
  } else {
    return null;
  }
};

export const inRange = (v: number, range: IRange): boolean => {
  if (range) {
    if (range.start && range.start.v !== null && range.start.v !== undefined) {
      if (range.start.v >= v) {
        if (!(range.start.inc && range.start.v === v)) {
          return false;
        }
      }
    }
    if (range.end && range.end.v !== null && range.end.v !== undefined) {
      if (range.end.v <= v) {
        if (!(range.end.inc && range.end.v === v)) {
          return false;
        }
      }
    }
  }
  return true;
};

export const relativeToRange = (v: number, range: IRange): 'in' | 'below' | 'above' => {
  if (range) {
    if (range.start && range.start.v !== null && range.start.v !== undefined) {
      if (v < range.start.v || (v === range.start.v && !range.start.inc)) {
        return 'below';
      }
    }
    if (range.end && range.end.v !== null && range.end.v !== undefined) {
      if (v > range.end.v || (v === range.end.v && !range.end.inc)) {
        return 'above';
      }
    }
    return 'in';
  }
  return null;
};
