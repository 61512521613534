<div class="af-add-buttons">
  <ion-button fill="clear" (click)="onAddFilterClick()">
    <app-icon-plus [tooltip]="'Add Filter'"></app-icon-plus>
    Add Filter
  </ion-button>
  <ion-button fill="clear" (click)="onAddGroupClick()">
    <app-icon-playlist-plus [tooltip]="'Add Group'"></app-icon-playlist-plus>
    Add Group
  </ion-button>
  <ion-button fill="clear" (click)="onDeleteItemClick()">
    <app-icon-delete-outline [tooltip]="'Delete Group'"></app-icon-delete-outline>
    Delete Group
  </ion-button>
</div>

<div class="advanced-filter-group-container" *ngIf="filterGroup?.filtersAndGroups?.length > 0">

  <ng-container *ngFor="let group of filterGroup.filtersAndGroups; let i = index; trackBy: trackByFunction">

    <ng-container *ngIf="!getObjectHasProperty(group, 'operator')">
      <div class="item-filter-container">
        <app-shared-modal-filters-advanced-group-item
          [setColDef]="colDefs"
          [setFilter]="group">
        </app-shared-modal-filters-advanced-group-item>
      </div>
    </ng-container>

    <ng-container
      *ngIf="getObjectHasProperty(group, 'operator')">
      <app-shared-modal-filters-advanced-group
        [predecessorID]="group.id"
        [setColDef]="colDefs"
        [setFilterGroups]="group">
      </app-shared-modal-filters-advanced-group>
    </ng-container>

    <div class="advanced-filter-group-operator-buttons" *ngIf="i + 1 < filterGroup.filtersAndGroups.length">
      <div
        [ngClass]="filterGroup.operator === 'AND' ? 'selected-button' : ''"
        (click)="onToggleOperator('AND')">
        <span>AND</span>
      </div>
      <div
        [ngClass]="filterGroup.operator === 'OR' ? 'selected-button' : ''"
        (click)="onToggleOperator('OR')">
        <span>OR</span>
      </div>
    </div>

  </ng-container>

</div>
