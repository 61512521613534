import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TableNavSettings} from '../../../../shared-utilities/models-old/datastructures';
import {FormControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FirebaseService} from '../../../../shared-utilities/services-old/firebase.service';
import {PopoverController} from '@ionic/angular';
import {SelectPopoverComponent} from '../../../shared-module/components/select-popover/select-popover.component';
import {NgpReportSettings} from '../../../../shared-utilities/models-old/ngp-report/ngp-data-types';

@Component({
  selector: 'app-shared-grid-table-navigation',
  templateUrl: './shared-grid-table-navigation.component.html',
  styleUrls: ['./shared-grid-table-navigation.component.scss'],
})
export class SharedGridTableNavigationComponent implements OnInit, OnChanges  {

  @Input() navSettings: TableNavSettings;
  @Input() altNavName?: string;
  @Input() disabledOpts?: { [key in keyof TableNavSettings]: boolean } =
    {cellDebounce: true} as { [key in keyof TableNavSettings]: boolean };

  @Output() readonly settingsChange = new EventEmitter<TableNavSettings>();


  settingsFG: UntypedFormGroup;
  saving: boolean;

  readonly endSelection = {
    'restart_page': 'jump back to first field on current page',
    'restart_column': 'restart at the top of the column',
    'next_column': 'start the next column',
    'next_page': 'go to first field on the next page',
    'next_page_same_column': 'continue the column on the next page',
    'restart_row': 'restart at the beginning of the current row'
  };

  private readonly defaultSetting: TableNavSettings = {tabEnd: 'restart_page', enterEnd: 'restart_column'};

  constructor(
    private firebase: FirebaseService,
    private popControl: PopoverController,
  ) {
  }

  ngOnInit(): void {
      if (!this.navSettings) {
        this.navSettings = {} as TableNavSettings;
        Object.keys(this.defaultSetting)
          .forEach((key: string) => (this.navSettings[key] = this.defaultSetting[key]));
      }
      this.settingsFG = new UntypedFormGroup({
        tabEnd: new UntypedFormControl(this.navSettings.tabEnd, [Validators.required]),
        enterEnd: new UntypedFormControl(this.navSettings.enterEnd, [Validators.required]),
        cellDebounce: new FormControl(this.navSettings.cellDebounce, [Validators.min(500), Validators.max(3000)])
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['navSettings']?.currentValue) {
      const currentValue = changes['navSettings'].currentValue;
      this.updateNavData(currentValue as TableNavSettings);
    }
  }


  updateNavData(currentValue: TableNavSettings): void {
    this.navSettings = currentValue;
    this.settingsFG = new UntypedFormGroup({
      tabEnd: new UntypedFormControl(this.navSettings.tabEnd, [Validators.required]),
      enterEnd: new UntypedFormControl(this.navSettings.enterEnd, [Validators.required]),
      cellDebounce: new FormControl(this.navSettings.cellDebounce, [Validators.min(500), Validators.max(3000)])
    });

  }

  async selectEnd(event, endOf: 'tab' | 'enter'): Promise<void> {
    let order = ['restart_page', 'restart_column', 'next_column', 'next_page', 'next_page_same_column', 'restart_row'];
    let selection = this.endSelection as any;

    if (endOf === 'tab') {
      order = order.filter((key: string) => !['restart_column', 'next_column', 'next_page_same_column'].includes(key));
      selection = {};
      order.forEach((key: string) => (selection[key] = this.endSelection[key]));
    }
    if (endOf === 'enter') {
      order = order.filter((key: string) => !['restart_row'].includes(key));
      selection = {};
      order.forEach((key: string) => (selection[key] = this.endSelection[key]));
    }
    const pc = await this.popControl.create({
      component: SelectPopoverComponent, componentProps: {
        selection,
        value: this.settingsFG.get(`${endOf}End`).value,
        order,
        selectModButtons: [{
          text: 'Default', handler: (check: { [key: string]: boolean }) => {
            check = {};
            check[this.defaultSetting[`${endOf}End`]] = true;
            return check;
          }
        }]
      },
      event
    });
    await pc.present();
    const {data} = await pc.onDidDismiss();

    if (data) {
      this.settingsFG.get(`${endOf}End`).setValue(data);
    }
  }

  reset(key: keyof TableNavSettings): void {
    switch (key) {
      case 'cellDebounce':
        this.settingsFG.get('cellDebounce').reset(null);
        break;
      case 'tabEnd':
        break;
      case 'enterEnd':
        break;
    }
  }

  onClose(): void {
    this.settingsChange.emit();
  }

  onSave(): void {
    this.settingsChange.emit(this.settingsFG.value);
  }

}
