import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-settings-stock-manager',
  templateUrl: './settings-stock-manager.component.html',
  styleUrls: ['./settings-stock-manager.component.scss'],
})
export class SettingsStockManagerComponent {


}
