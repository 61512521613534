<div class="line-colour-selector-div" [ngStyle]="{'width': width + 'px'}">

  <input #input [(ngModel)]="value" (focus)="selectText()" class="input"/>
  <select
    [(ngModel)]="value"
    (change)="onSelectionChange($event)"
    class="select-line-colour-list"
    [ngStyle]="{'width': selectorWidth + 'px'}">
    <option value="0" *ngIf="colDefField === 'lineColourCode'">No Line Colour</option>
    <option *ngFor="let option of options" [value]="option.key" [style.background]="option.value">
      {{ option.description }}
    </option>
  </select>

  <app-display-icon
    class="backspace"
    *ngIf="params.data.originalValue[params.colDef.field].value !== value"
    [icon]="icons.backSpaceOutline"
    (click)="onUndoChangesClick()">
  </app-display-icon>

</div>
